import React, { useContext, useEffect, useState } from "react";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import Pricing from "../PricingNew/Pricing";
//import PricingConfiguration from "../PricingConfiguration/PricingConfiguration";
import Lottie from "../../Assets/Animation/LoadingWhite";
import _ from "lodash";
import axios from "axios";
import { createAlbumLatest } from "../WorkFlowLeads_AddWorkflowTask/apiCallsLeads/apiCallsLeads";
import { useParams } from "react-router-dom";
import DynamicAlbumTemplate from "./DynamicTemplateForm/DynamicTemplateForm";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
import "./AlbumCreateForm.css";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
//import PricingMain from "../PricingMain/PricingMain";
function AlbumCreateForm({
  update,
  priceData,
  setUpdate,
  visible,
  setAlbum,
  values,
  eventType,
  packageType,
}) {
  const [slugIdValidation, setSlugIdValidation] = useState(false);
  const {
    studioId,
    activePackages,
    paymentPlan,
    setPaymentPlan,
    handlepaymentPlanChange,
    flexiPackagePayment,
    payPlanError,
    setPayPlanError,
  } = useContext(dataContext);
  const [slugIdValidationLoading, setSlugIdValidationLoading] = useState(false);
  const [selectedPhotosharePackage, setSelectedPhotosharePackage] = useState(
    priceData?.SignatureAlbum[0]
  );
  const { eventId } = useParams();
  const [packageTypes, setPackageTypes] = useState(packageType || "FLEXI");
  const initialValues = {
    albumName: null,
    templateName: null,
    templateType: null,
    albumTemplateId: null,
    compress: true,
    purchasedFromCRM: true,
    sections: [],
    name: "",
    canAssignCrew: false,
    hasTaskDueDate: false,
    workflowTaskType: "PRODUCTION",
    workflowEventType: "ALBUM",
    dueDate: "",
    packageType: packageTypes,
  };
  const [pricingDataApi, setPricingDataApi] = useState(
    priceData?.SignatureAlbum[0]?.config
  );
  const [totalPrice, setTotalPrice] = useState(
    priceData?.SignatureAlbum[0]?.config?.price
  );

  const { userIdToken, currentUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [pickedTemplate, setPickedTemplate] = useState({});
  const [albumData, setAlbumData] = useState([]);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [errorCatch, setErrorCatch] = useState("");

  let newNumber = 0;
  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      packageType: packageTypes,
    }));
  }, [packageTypes]);

  useEffect(() => {
    getAlbums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPaymentPlan("");
  }, [visible]);

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    if (formValues?.slugId) {
      checkSlugIdValidation(formValues?.slugId, source);
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.slugId]);
  useEffect(() => {
    if (errorCatch) {
      setErrorCatch("");
    }
  }, [visible]);

  const checkSlugIdValidation = async (slugId, source) => {
    try {
      setSlugIdValidationLoading(true);
      setSlugIdValidation(false);

      let config = {
        method: "post",
        // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/album/checkslugid/${eventId}/${studioId}`,
        url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/album/checkslugid/${eventId}/${studioId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          slugId: slugId,
        },
        cancelToken: source.token,
      };
      const response = await axios(config);
      if (response.status === 200) {
        let slugIdError = "";
        if (response?.data?.error === false) {
          setSlugIdValidation(true);
        } else {
          setSlugIdValidation(false);
          slugIdError = "Unique text is already used.Please choose other..";
        }
        setErrors((prevState) => ({
          ...prevState,
          slugId: slugIdError,
        }));
      }
      setSlugIdValidationLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setSlugIdValidationLoading(false);
        throw error;
      }
    }
  };

  const getAlbums = async () => {
    setLoading(true);
    try {
      let config = {
        method: "GET",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/crmalbums/templates?templateType=ALBUM&platformType=SHOOTMATES&eventType=${eventType}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        let templates = response?.data?.templates;
        setAlbumData(templates);
        if (templates && templates[0]) {
          setPickedTemplate(templates[0]);
          setFormValues({
            ...formValues,
            templateName: templates[0]?.templateName,
            albumTemplateId: templates[0]?._id,
            templateType: templates[0]?.templateType,
            sections: templates[0]?.sections,
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const validateForm = () => {
    let errors = {};
    if (_.isEmpty(formValues?.slugId)) {
      errors.slugId = "Unique text is empty";
    }
    if (slugIdValidation === false) {
      errors.slugId = "Unique text is already used.Please choose other..";
    }
    if (_.isEmpty(pickedTemplate)) {
      errors.template = "Please choose any template..";
    }
    if (_.isEmpty(formValues.albumName)) {
      errors.albumName = "Please enter album name";
    }
    if (_.isEmpty(formValues.slugId)) {
      errors.slugId = "Please enter slug Id";
    }
    return errors;
  };

  const albumType = "ALBUM";
  const handleClickAdd = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // if (
      //   packageTypes === "FLEXI" &&
      //   activePackages?.paylater &&
      //   activePackages?.paylater === "ACTIVE" &&
      //   paymentPlan === ""
      // )
      //   return setPayPlanError("Select Your Payment Plan");
      const albumIdRes = await createAlbumLatest(
        pricingDataApi,
        totalPrice,
        albumType,
        setLoading,
        eventId,
        studioId,
        newNumber,
        tokenData,
        formValues,
        values,
        setAlbum,
        setUpdate,
        update,
        packageTypes,
        paymentPlan
      );

      if (albumIdRes?.errorMsg) return setErrorCatch(albumIdRes?.errorMsg);
      if (albumIdRes && packageTypes == "FLEXI") {
        flexiPackagePayment(
          studioId,
          albumIdRes?.albumId,
          albumIdRes?.packageCode?.replace(/'/g, ""),
          Number(eventId),
          paymentPlan
        );
      } else {
        setAlbum(false);
        setUpdate(!update);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  //   if (_.isEmpty(pickedTemplate)) {
  //     setError({
  //       errorMessage: "Please Choose Any Template..",
  //       errorStatus: true,
  //       header: "Message",
  //     });
  //     return;
  //   }
  //   if (_.isEmpty(formValues.albumName)) {
  //     setError({
  //       errorMessage: "Please give a album name..",
  //       errorStatus: true,
  //       header: "Message",
  //     });
  //     return;
  //   }
  //   if (_.isEmpty(formValues.slugId)) {
  //     setError({
  //       errorMessage: "Please give a slugId",
  //       errorStatus: true,
  //       header: "Message",
  //     });
  //     return;
  //   }
  //   await addWorkFlowAlbumTask(
  //     albumType,
  //     setLoading,
  //     eventId,
  //     studioId,
  //     newNumber,
  //     tokenData,
  //     formValues,
  //     values,
  //     setAlbum,
  //     setUpdate,
  //     update
  //   );
  // };
  const generateLink = (slugId) => {
    let link = "";
    if (!_.isEmpty(slugId)) {
      link = `https://app.memshots.com/${slugId}`;
    } else {
      link = "";
    }
    return link;
  };

  const handleSlugIdChange = (e) => {
    let temp = e.target.value;
    temp = temp.replace(/\s+/g, "").substring(0, 50);
    setFormValues({
      ...formValues,
      slugId: temp,
    });
  };

  const handleFrequencyChange = (event) => {
    setPackageTypes(event.target.value);
  };

  return (
    <PopupWrapper visible={visible}>
      {loading ? (
        <Lottie loadingText={"Loading.."} />
      ) : (
        <>
          <div className="AlbumCreateFormContainer  mt-[78px] ml-[78px] mr-[78px] min-w-[50%]">
            <div className="flex justify-between p-[24px] items-center">
              <div className="font_semibold_20 text-[#161616] flex items-center ">
                <p>Creating Photo Album </p>{" "}
              </div>

              <div className="items-center gap-[20px]  flex">
                <div className="items-center  flex">
                  <label className="">
                    {" "}
                    {packageType?.charAt(0).toUpperCase() +
                      packageType?.slice(1).toLowerCase()}{" "}
                    Package{" "}
                  </label>
                </div>
                <img
                  src={popupsCloseIcon}
                  alt=""
                  onClick={() => {
                    setAlbum(false);
                  }}
                  className="cursor-pointer"
                />
              </div>
            </div>

            <div className="seperatorHorizontalNew"></div>
            {errorCatch && (
              <p className="text-[18px] text-end mr-[10%] text-[red]">
                {errorCatch}
              </p>
            )}
            <div className="w-full h-full px-[24px] py-[20px]">
              <p className="font_semibold_16 text-[#161616] mb-[24px]">
                Photo Album Details
              </p>
              <div className="w-full grid grid-cols-2 gap-x-[24px]">
                {/* Album Name Input */}
                <div>
                  <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                    Album Name <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Please enter Album name"
                    className="w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]"
                    value={formValues.albumName}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        albumName: e.target.value,
                      })
                    }
                  />
                  {errors?.albumName && (
                    <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                      {errors.albumName}
                    </p>
                  )}
                </div>

                {/* Slug ID Input */}
                <div>
                  <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                    Slug ID <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Please enter an unique Id"
                    className="w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]"
                    value={formValues.slugId}
                    onChange={handleSlugIdChange}
                  />
                  {formValues?.slugId && (
                    <p className="font-normal text-[19px] leading-[19px] text-[#145DA0] mt-[5px]">
                      {generateLink(formValues.slugId)}
                      {slugIdValidation && <span> &#9989;</span>}
                      {slugIdValidationLoading && (
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="text-black p-2 text-sm">
                            Checking
                          </span>
                        </div>
                      )}
                    </p>
                  )}
                  {errors?.slugId && (
                    <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                      {errors.slugId}
                    </p>
                  )}
                </div>

                {/* Compression Needed Input */}
                <div className="col-span-2 mt-[24px]">
                  <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                    Compression Needed? <span className="text-red-500">*</span>
                  </p>
                  <div className="flex items-center">
                    <label className="mr-[24px]">
                      <input
                        type="radio"
                        name="compression"
                        value="yes"
                        checked={formValues.compress === true}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            compress: true,
                          })
                        }
                      />
                      <span className="ml-[8px]">Yes</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="compression"
                        value="no"
                        checked={formValues.compress === false}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            compress: false,
                          })
                        }
                      />
                      <span className="ml-[8px]">No</span>
                    </label>
                  </div>
                  {errors?.compress && (
                    <p className="font-normal text-[12px] leading-[14px] text-[#fc3535] mt-[5px]">
                      {errors.compress}
                    </p>
                  )}

                  {/* <div className="items-center  flex">
                    <label htmlFor="paymentFrequency " className="  text-md">
                      Package type -
                    </label>

                    <div
                      className={`p-2 
                    `}
                    >
                      <label>{packageType}</label>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="seperatorHorizontalNew"></div>
            <div className="w-full h-full px-6 py-5 bg-white rounded-lg shadow-lg">
              <p className="font-semibold text-lg text-[#161616] mb-6">
                Select Album Template
              </p>
              <p className="font-normal text-sm text-[#8C8C8C] mb-2">
                Choose a template <span className="text-red-500">*</span>
              </p>

              <div className="w-full flex flex-wrap gap-6">
                {albumData && albumData.length > 0 ? (
                  albumData.map((obj, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setPickedTemplate(albumData[index]);
                        setFormValues({
                          ...formValues,
                          templateName: albumData[index]?.templateName,
                          albumTemplateId: albumData[index]?._id,
                          templateType: albumData[index]?.templateType,
                          sections: albumData[index]?.sections,
                        });
                      }}
                      className={`relative group rounded-lg overflow-hidden cursor-pointer w-fit transition-all duration-300 
            ${
              obj?._id === pickedTemplate?._id
                ? "border-4 border-blue-500 shadow-lg"
                : "border-2 border-gray-200"
            }
            hover:shadow-md hover:border-gray-300`}
                    >
                      {/* Template Image */}
                      <img
                        src={obj?.templateCover}
                        alt="Template"
                        className="w-[153px] h-[217px] object-cover rounded-md"
                      />

                      {/* Checkmark for Selected Template */}
                      {obj?._id === pickedTemplate?._id && (
                        <div className="absolute top-2 right-2 bg-blue-500 text-white rounded-full p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                      )}
                      {obj?._id === pickedTemplate?._id && (
                        <a
                          href={obj?.previewUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block bg-black text-white text-sm font-medium text-center px-4 py-2 mt-2 rounded-md transition-opacity duration-300 hover:bg-gray-800"
                        >
                          Preview
                        </a>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">No templates available.</p>
                )}

                {errors?.template && (
                  <p className="font-normal text-sm text-red-500 mt-2">
                    {errors.template}
                  </p>
                )}
              </div>
            </div>

            <div className="seperatorHorizontalNew"></div>
            <div className="w-full h-full  pt-[20px]">
              {!_.isEmpty(pickedTemplate) ? (
                <DynamicAlbumTemplate
                  key={pickedTemplate._id}
                  pickedTemplate={pickedTemplate}
                  setFormValues={setFormValues}
                />
              ) : null}
            </div>
            {/* <PricingMain/> */}
            <Pricing
              albumType={albumType}
              priceData={priceData}
              setPricingDataApi={setPricingDataApi}
              setTotalPrice={setTotalPrice}
              selectedPhotosharePackage={selectedPhotosharePackage}
              setSelectedPhotosharePackage={setSelectedPhotosharePackage}
            />
            {errorCatch && (
              <p className="text-[18px] text-end mr-[10%] text-[red]">
                {errorCatch}
              </p>
            )}
            {packageTypes === "FLEXI" &&
              activePackages?.paylater &&
              activePackages?.paylater === "ACTIVE" && (
                <div className="flex  justify-end items-center my-4 mr-[5%] space-x-4">
                  <label className="text-[14px]">Choose to Payment Plan</label>{" "}
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name="paymentOption"
                      value="Paylater"
                      checked={paymentPlan === "PAYLATER"}
                      onChange={handlepaymentPlanChange}
                      className="mr-2"
                    />
                    Pay Later
                  </label>
                  {/* <label className="flex cursor-pointer items-center">
                    <input
                      type="radio"
                      name="paymentOption"
                      value="oneTime"
                      checked={paymentPlan === "ONETIME"}
                      onChange={handlepaymentPlanChange}
                      className="mr-2"
                    />
                    One Time
                  </label> */}
                </div>
              )}
            {payPlanError && (
              <p className="text-[14px] text-end mr-[5%] text-[red]">
                {payPlanError}
              </p>
            )}
            <div className="px-[24px] w-full flex gap-[16px] items-center justify-end  pb-[24px]">
              <button
                onClick={() => {
                  setAlbum(false);
                }}
                className="cancelButton font_semibold_12"
              >
                Cancel
              </button>
              {packageType === "YEARLY" ? (
                activePackages?.packageStatus === "ACTIVE" ? (
                  loading ? (
                    <button className="eventButton font_semibold_12">
                      Loading..
                    </button>
                  ) : (
                    <button
                      className="eventButton font_semibold_12"
                      onClick={handleClickAdd}
                    >
                      Create
                    </button>
                  )
                ) : (
                  <p>Your Package is {activePackages?.packageStatus}</p>
                )
              ) : loading ? (
                <button className="eventButton font_semibold_12">
                  Loading..
                </button>
              ) : (
                <button
                  className="eventButton font_semibold_12"
                  onClick={handleClickAdd}
                >
                  Create
                </button>
              )}

              {/* {loading ? (
                <button className="eventButton font_semibold_12">
                  Loading..
                </button>
              ) : (
                <button
                  className="eventButton font_semibold_12"
                  onClick={handleClickAdd}
                >
                  Create
                </button>
              )} */}
            </div>
          </div>
        </>
      )}
    </PopupWrapper>
  );
}

export default AlbumCreateForm;
