import React, { useState, createContext, useEffect, useContext } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { authentication } from "../store/firebase-config";
import Lottie from "../Assets/Animation/Loading";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { dataContext } from "./DataContext";
import { isEmpty } from "lodash";
export const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState();
  const [userIdToken, setUserIdToken] = useState();
  const [emailError, setEmailError] = useState("");
  const [emailAccountLogin, setEmailAccountLogin] = useState(false);
  const [emailVerified, setEmailVerified] = useState();
  const [loginRefresh, setLoginRefresh] = useState(1);
  const verifyUser = async () => {
    try {
      const idToken = await authentication.currentUser.getIdToken(true);
      setUserIdToken(idToken);
      return idToken;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, (user) => {
      setCurrentUser(user);
      setPageLoading(false);
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetStudioRoleCheck = (studioRole) => {
    // if (isEmpty(studioRole)) {
    //   navigate(`/CreateProfile`);
    // } else {
    // navigate("/Event");
    // }
  };
  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      setLoading(true);
      const login = await signInWithPopup(authentication, provider);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_LOGIN}`,
        headers: {
          Authorization: `Bearer ${login._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        // handleGetStudioRoleCheck();
        // window.location.href = "/Event";
        setLoginRefresh((prevState) => prevState + 1);
      } else {
        setLoading(false);
        // navigate(`/login`);
      }
      setLoading(false);
      return loginCheck.status;
    } catch (error) {
      setLoading(false);
      // navigate(`/login`);
    }
  };

  const googleSignOut = async () => {
    signOut(authentication)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  //email signin
  const emailSignIn = async (name, email, password) => {
    try {
      setLoading(true);
      const response = await createUserWithEmailAndPassword(
        authentication,
        email,
        password
      );

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_LOGIN}`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
        data: { name },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        setEmailAccountLogin(true);
        // navigate("/Event");
        // setLoginRefresh((prevState) => prevState + 1);
        // handleGetStudioRoleCheck();
      } else {
        setLoading(false);
        // navigate(`/login`);
      }
      setLoading(false);
      return loginCheck.status;
    } catch (error) {
      setLoading(false);
      // navigate(`/login`);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };

  //email login
  const emailLogin = async (email, password) => {
    try {
      setLoading(true);
      const response = await signInWithEmailAndPassword(
        authentication,
        email,
        password
      );

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_LOGIN}`,
        headers: {
          Authorization: `Bearer ${response._tokenResponse.idToken}`,
          "Content-Type": "application/json",
        },
      };
      const loginCheck = await axios(config);
      if (loginCheck.status === 201) {
        setEmailAccountLogin(true);
        // navigate("/Event");
        // setLoginRefresh((prevState) => prevState + 1);
        // handleGetStudioRoleCheck();
      } else {
        setLoading(false);
        // navigate(`/login`);
      }
      setLoading(false);
      return loginCheck.status;
    } catch (error) {
      setLoading(false);
      // navigate(`/login`);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }

      console.log("error", error);
    }
  };

  //email login password reset
  const emailPasswordReset = async (email) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(authentication, email);
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }

      console.log("error", error);
    }
  };
  //email verification
  const emailVerification = async (user) => {
    try {
      setLoading(true);
      await sendEmailVerification(user);
      return true;
    } catch (error) {
      setLoading(false);
      const cleanedErrorMessage = error?.message?.match(/auth\/(.+?)\)/);
      if (cleanedErrorMessage && cleanedErrorMessage[1]) {
        const errorCode = cleanedErrorMessage[1];
        setEmailError(errorCode);
      } else {
        const cleanedErrorMessage = error?.message?.replace(
          /^Firebase:\s*/,
          ""
        );
        setEmailError(cleanedErrorMessage);
      }
      console.log("error", error);
    }
  };
  const fetchUserData = async () => {
    try {
      const user = authentication.currentUser;
      await user.reload();
      setCurrentUser(user);
      setEmailVerified(user?.emailVerified);
      return user;
    } catch (error) {
      console.error("Error reloading user data", error);
      // Handle error here
    }
  };
  return (
    <userAuthContext.Provider
      value={{
        googleSignIn,
        currentUser,
        loading,
        setLoading,
        googleSignOut,
        verifyUser,
        userIdToken,
        emailError,
        setEmailError,
        emailPasswordReset,
        emailLogin,
        emailSignIn,
        emailAccountLogin,
        setEmailAccountLogin,
        fetchUserData,
        emailVerified,
        setEmailVerified,
        emailVerification,
        loginRefresh,
        handleGetStudioRoleCheck,
      }}
    >
      {pageLoading ? <Lottie /> : children}
    </userAuthContext.Provider>
  );
}
