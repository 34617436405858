import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { dataContext } from "../../../Context/DataContext";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./StudioEditFormPopup.css";
import { studioStorage } from "../../../store/firebase-config";
import { ref, uploadBytes, updateMetadata } from "firebase/storage";
//import uploadImage from '../../../Assets/fileUpload.svg'
import coverImageIcon from "../../../Assets/coverImageIcon.svg";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";
import { useNavigate } from "react-router-dom";

function StudioEditFormPopup({
  update,
  setUpdate,
  visible,
  setStudioForm,
  studioObj,
  idkey,
  studioId,
}) {
  const studioDataId = studioObj?._id;
  const studioLogo = studioObj?.studioLogo;
  const studioLogoDark = studioObj?.studioLogoDark;
  const inputRef = useRef();
  const inputRefDark = useRef();
  const [loading, setLoading] = useState(false);
  const [newLogo, setNewLogo] = useState(null);
  const [newLogoDark, setNewLogoDark] = useState(null);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { refresh, setRefresh } = useContext(dataContext);
  const initialValues = {
    studioName: studioObj?.studioName ? studioObj?.studioName : "",
    socialMediaLinks: studioObj?.socialMediaLinks || {},
    email: studioObj?.email ? studioObj?.email : " ",
    phone_number: studioObj?.phone_number ? studioObj?.phone_number : " ",
    whatsAppNumber: studioObj?.whatsAppNumber ? studioObj?.whatsAppNumber : " ",
    stateCountry: studioObj?.stateCountry ? studioObj?.stateCountry : " ",
    pinCode: studioObj?.pinCode ? studioObj?.pinCode : " ",
    address: studioObj?.address ? studioObj?.address : " ",
    studioUniqueText: studioObj?.studioUniqueText
      ? studioObj?.studioUniqueText
      : "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const navigate = useNavigate();

  //handle logo choose
  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleChooseDark = async () => {
    inputRefDark.current.click();
  };
  //handle logo change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewLogo(file);
  };

  const handleFileChangeDark = (e) => {
    const file = e.target.files[0];
    setNewLogoDark(file);
  };
  //upload studio logo firebase
  // const uploadStudioLogo = async (studioLogo, studioDataId) => {
  //   try {
  //     const file = studioLogo;
  //     if (!file) {
  //       console.error("No file selected.");
  //       return;
  //     }
  //     if (file.type !== "image/png" && file.type !== "image/jpeg") {
  //       console.error("Only PNG and JPG images are allowed.");
  //       return;
  //     }
  //     const fileExtension = file.type.split("/")[1];
  //     const fileName = `logo.${fileExtension}`;
  //     const storageRef = ref(studioStorage, `/${studioDataId}/${fileName}`);
  //     await uploadBytes(storageRef, file);
  //     const newMetadata = {
  //       cacheControl: "no-cache, no-store, must-revalidate",
  //       contentType: file.type,
  //     };
  //     await updateMetadata(storageRef, newMetadata);
  //     const bucketName = "studioprofile";
  //     const filePath = `${studioDataId}/${fileName}`;
  //     const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
  //     return gcsBucketUrl;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const uploadStudioLogo = async (studioLogo, studioLogoDark, studioDataId) => {
    setLoading(true);
    try {
      const logos = {};
      if (studioLogo) logos.light = studioLogo;
      if (studioLogoDark) logos.dark = studioLogoDark;

      const uploadedUrls = {};

      if (Object.keys(logos).length === 0) {
        console.error("No logos provided for upload.");
        return;
      }

      for (const [type, file] of Object.entries(logos)) {
        const allowedTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg+xml",
        ];

        if (!allowedTypes.includes(file.type)) {
          console.error(
            `Only PNG, JPG, JPEG, and SVG images are allowed for the ${type} logo.`
          );
          continue;
        }

        const fileExtension = file.type.split("/")[1];
        const fileName = `logo_${type}.${fileExtension}`;
        const storageRef = ref(studioStorage, `/${studioDataId}/${fileName}`);
        await uploadBytes(storageRef, file);

        const newMetadata = {
          cacheControl: "no-cache, no-store, must-revalidate",
          contentType: file.type,
        };
        await updateMetadata(storageRef, newMetadata);

        const bucketName = "studioprofile";
        const filePath = `${studioDataId}/${fileName}`;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        uploadedUrls[type] = gcsBucketUrl;
      }

      // Update studio with the uploaded URLs only for available logos
      await updateStudio(studioDataId, {
        lightLogoUrl: uploadedUrls.light || "",
        darkLogoUrl: uploadedUrls.dark || "",
      });

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const updateStudio = async (studioDataId, gcsBucketUrl) => {
    try {
      setLoading(true);
      verifyUser();
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/updateStudio/${studioDataId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          ...formValues,
          studioLogo: gcsBucketUrl?.lightLogoUrl
            ? gcsBucketUrl?.lightLogoUrl
            : studioObj?.studioLogo,
          studioLogoDark: gcsBucketUrl?.darkLogoUrl
            ? gcsBucketUrl?.darkLogoUrl
            : studioObj?.studioLogoDark,
        },
      };
      const response = await axios(config);
      setLoading(false);
      setUpdate(!update);
      setRefresh(!refresh);
      return response;
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleUpdateStudio = async () => {
    if (!studioLogo && !newLogo) {
      setLoading(false);
      alert("Please Upload a Studio Logo..");
      return;
    }
    if (newLogo || newLogoDark) {
      await uploadStudioLogo(newLogo, newLogoDark, studioDataId);
    } else {
      updateStudio(studioDataId);
    }
  };

  // const updateStudio = async (studioDataId) => {
  //   try {
  //     setLoading(true);
  //     verifyUser();
  //     let studioLogoImage = null;
  //     if (!studioLogo && !newLogo) {
  //       setLoading(false);
  //       alert("Please Upload a Studio Logo..");
  //       return;
  //     }
  //     if (newLogo) {
  //       studioLogoImage = await uploadStudioLogo(newLogo, studioDataId);
  //     }
  //     let config = {
  //       method: "patch",
  //       url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/updateStudio/${studioDataId}`,
  //       headers: {
  //         Authorization: `Bearer ${tokenData}`,
  //         "Content-Type": "application/json",
  //       },
  //       data: {
  //         ...formValues,
  //         studioLogo: studioLogoImage ? studioLogoImage : studioObj?.studioLogo,
  //       },
  //     };
  //     const response = await axios(config);
  //     setLoading(false);
  //     setUpdate(!update);
  //     setRefresh(!refresh);
  //     return response;
  //   } catch (error) {
  //     console.log("error", error);
  //     setLoading(false);
  //   }
  // };

  const handleChangeInputs = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };
  const handleChangeSocialMedia = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    setFormValues({
      ...formValues,
      socialMediaLinks: { ...formValues?.socialMediaLinks, [id]: value },
    });
  };

  return (
    <>
      <div className=" p-[24px] ">
        <div className=" flex items-start justify-between  ">
          <div>
            <p className="font_semibold_20  text text-[#161616]">
              {idkey === "update"
                ? "Edit your Studio Profile"
                : "Set up your Studio Profile"}
            </p>
            <p className="para mt-[15px] pb-[20px]">
              Let’s setup your studio profile and explore all the advanced
              features.
            </p>
          </div>

          <div className="">
            <button
              onClick={() => setStudioForm(false)}
              className="border-[1px] border-[#787878] bg-[#f1f0f0] p-[10px] rounded-[50px]"
            >
              <img src={popupsCloseIcon} alt="" className="cursor-pointer " />
            </button>
          </div>
          {/* <img
          src={popupsCloseIcon}
          alt=""
          onClick={() => setStudioForm(false)}
          className="cursor-pointer"
        /> */}
        </div>
        <div className="flex justify-start items-start gap-[50px]">
          <div className="flex-shrink-0">
            {studioLogo || newLogo ? (
              <div className="w-[200px] h-full" onClick={handleChoose}>
                <input
                  type="file"
                  onChange={handleFileChange}
                  ref={inputRef}
                  className="hidden"
                  accept=".png,.svg,.PNG,.SVG"
                />
                <img
                  className="w-full border-[1px] border-[#787878] h-full object-cover"
                  src={
                    newLogo
                      ? URL.createObjectURL(newLogo)
                      : studioObj?.studioLogo
                      ? studioObj?.studioLogo
                      : null
                  }
                  alt="no data"
                />
              </div>
            ) : (
              <div
                onClick={handleChoose}
                className="flex gap-[10px] w-[200px] justify-center h-full items-center border-[1px] border-[#8C8C8C33]"
              >
                <input
                  type="file"
                  onChange={handleFileChange}
                  ref={inputRef}
                  className="hidden"
                  accept=".png,.svg,.PNG,.SVG"
                />
                <img src={coverImageIcon} alt="" />
                <p className="font_normal_14 text-[#161616]">
                  Add a Organisation logo
                </p>
              </div>
            )}
          </div>
          <div className="flex-shrink-0">
            {studioLogoDark || newLogoDark ? (
              <div className="w-[200px] h-full" onClick={handleChooseDark}>
                <input
                  type="file"
                  onChange={handleFileChangeDark}
                  ref={inputRefDark}
                  className="hidden"
                  accept=".png,.svg,.PNG,.SVG"
                />
                <img
                  className="w-full border-[1px] border-[#787878] h-full object-cover"
                  src={
                    newLogoDark
                      ? URL.createObjectURL(newLogoDark)
                      : studioObj?.studioLogoDark
                      ? studioObj?.studioLogoDark
                      : null
                  }
                  alt="no data"
                />
              </div>
            ) : (
              <div
                onClick={handleChooseDark}
                className="flex gap-[10px] flex-col text-center bg-[#111] text-[#fff]  w-[200px] justify-center h-[220px] items-center border-[1px] border-[#8C8C8C33]"
              >
                <input
                  type="file"
                  onChange={handleFileChangeDark}
                  ref={inputRefDark}
                  className="hidden"
                  accept=".png,.svg,.PNG,.SVG"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13 19C13 19.7 13.13 20.37 13.35 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 3.9 3.9 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13ZM13.96 12.29L11.21 15.83L9.25 13.47L6.5 17H13.35C13.75 15.88 14.47 14.91 15.4 14.21L13.96 12.29ZM20 18V15H18V18H15V20H18V23H20V20H23V18H20Z"
                    fill="white"
                  />
                </svg>
                <p className="font_normal_14 uppercase ">Add logo </p>
              </div>
            )}
          </div>
        </div>
        <p className="text-start logoUploadText mt-[24px] mr-[50%]">
          Please try to Upload your logos on*PNG or *SVG format. Also upload in
          white and black, these logos will shows on our different products so
          which may cause contrast issues{" "}
        </p>

        <div className="flex studioForm mt-[40px] justify-start items-start ">
          {/* {!logoAdd ? ( */}
          <>
            <div>
              <p className="studioDetails">Studio Details</p>
              <div className="flex gap-[35px]">
                <div className="flex flex-col">
                  <label>Studio Name *</label>
                  <input
                    value={formValues.studioName}
                    id="studioName"
                    className={` font_normal_14 `}
                    onChange={handleChangeInputs}
                    // style={{
                    //   borderColor: formErrors?.studioName && "#f25555",
                    // }}
                    type="text"
                    placeholder="Enter studio name"
                  />
                  <label>Email *</label>
                  <input
                    type="text"
                    placeholder="Enter email address"
                    className={` font_normal_14 `}
                    value={formValues.email}
                    // style={{ borderColor: formErrors?.email && "#f25555" }}
                    id="email"
                    onChange={handleChangeInputs}
                  />
                </div>

                <div className="flex flex-col">
                  <label>Domain Name * (not editable)</label>
                  <input
                    type="text"
                    placeholder="Enter unique domain name"
                    className={` font_normal_14  `}
                    // style={{
                    //   borderColor: formErrors?.studioUniqueText && "#f25555",
                    // }}
                    disabled
                    value={formValues?.studioUniqueText}
                    id="studioUniqueText"
                    onChange={handleChangeInputs}
                  />
                  <label>Mobile Number *</label>
                  <input
                    type="text"
                    placeholder="Enter mobile number"
                    className={`font_normal_14 `}
                    // style={{
                    //   borderColor: formErrors?.phone_number && "#f25555",
                    // }}
                    value={formValues?.phone_number}
                    id="phone_number"
                    onChange={handleChangeInputs}
                  />
                </div>
              </div>
              <div className="flex gap-[35px]">
                <div className="flex flex-col">
                  <label>Whatsapp Number *</label>
                  <input
                    type="text"
                    placeholder="Enter whatsapp number"
                    className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                    value={formValues.whatsAppNumber}
                    // style={{
                    //   borderColor: formErrors?.whatsAppNumber && "#f25555",
                    // }}
                    id="whatsAppNumber"
                    onChange={handleChangeInputs}
                  />
                  <label>State/Country </label>
                  <input
                    type="text"
                    placeholder="State/Country"
                    className={`font_normal_14 `}
                    // style={{
                    //   borderColor: formErrors?.state_country && "#f25555",
                    // }}
                    value={formValues?.stateCountry}
                    id="stateCountry"
                    onChange={handleChangeInputs}
                  />
                </div>
                <div className="flex flex-col">
                  <label>Address </label>
                  <textarea
                    rows={1}
                    type="message"
                    placeholder="Enter Address"
                    className={`w-full mt-[8px] font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                    value={formValues.address}
                    // style={{ borderColor: formErrors?.address && "#f25555" }}
                    id="address"
                    onChange={handleChangeInputs}
                  />
                  <label>Pin Code </label>
                  <input
                    type="text"
                    placeholder="Enter Pin Code"
                    className={`font_normal_14 `}
                    // style={{
                    //   borderColor: formErrors?.pin_code && "#f25555",
                    // }}
                    value={formValues?.pinCode}
                    id="pinCode"
                    onChange={handleChangeInputs}
                  />
                </div>
              </div>
            </div>
            <div className="ml-[35px]">
              {" "}
              <p className="studioDetails">Social Connection</p>
              <div className="flex flex-col">
                <label>Instagram</label>
                <input
                  type="text"
                  placeholder="Enter instagram link"
                  className={` font_normal_14 `}
                  value={formValues?.socialMediaLinks?.instagram}
                  id="instagram"
                  onChange={handleChangeSocialMedia}
                />
                <label>Facebook</label>
                <input
                  type="text"
                  placeholder="Enter facebook link"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={formValues?.socialMediaLinks?.facebook}
                  id="facebook"
                  onChange={handleChangeSocialMedia}
                />
                <label>Twitter</label>
                <input
                  type="text"
                  placeholder="Enter twitter link"
                  className={` font_normal_14 `}
                  value={formValues?.socialMediaLinks?.twitter}
                  id="twitter"
                  onChange={handleChangeSocialMedia}
                />
                <label>Website</label>
                <input
                  type="text"
                  placeholder="Enter website link"
                  className={` font_normal_14 `}
                  value={formValues?.socialMediaLinks?.website}
                  id="website"
                  onChange={handleChangeSocialMedia}
                />
              </div>
            </div>
          </>
          {/* ) : (
          <div className="">
            <p className="logoText">Add your studio logos</p>
            <div className="flex mt-[15px] justify-start items-start gap-[43px]">
              {studioLogo ? (
                <div className="w-full h-full">
                  <img
                    className="w-full h-full object-cover"
                    src={URL.createObjectURL(studioLogo)}
                    alt="no data"
                  />
                </div>
              ) : (
                <div
                  onClick={handleChoose}
                  className="logoBox justify-center  flex flex-col  items-center "
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    ref={inputRef}
                    style={{ display: "none" }}
                    className="hidden"
                    accept=".jpg,.jpeg,.png,.svg"
                  />
                  <img src={coverImageIcon} alt="" />
                  <p className="font_normal_14 logoImgText">Add logo</p>
                </div>
              )}

              <div className=" w-[300px] h-[300px] flex-shrink-0"></div>
            </div>
          </div>
          )} */}
        </div>
        <div className=" justify-end gap-[20px] items-end flex">
          <button
            className="studioFormCancelButton border-opacity-50"
            onClick={() => setStudioForm(false)}
            // onClick={handleSave}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            disabled={loading}
            className="studioFormsaveButton disabled:opacity-30 mt-[32px]"
            onClick={() => {
              handleUpdateStudio();
            }}
          >
            {loading ? "Loading" : "Save Changes"}
          </button>
          {/* <button
            className=" "
            onClick={() => (logoAdd ? handleSave() : setLogoAdd(true))}
            // onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Loading" : "Next"}
          </button> */}
        </div>
      </div>

      {/* <PopupWrapper visible={visible}>
        <div className="addStudioFormContainer  mt-[78px]">
          <div className="p-[24px] sticky top-0 bg-white flex items-center justify-between rounded-t-[12px]">
            <p className="font_semibold_20 text-[#161616]">
              {idkey === "update" ? "Edit Studio" : "Add a new Studio"}
            </p>
            <img
              src={popupsCloseIcon}
              alt=""
              onClick={() => setStudioForm(false)}
              className="cursor-pointer"
            />
          </div>
          <div className="seperatorHorizontalNew"></div>
          <div className="pt-[12px]">
            <p className="px-[24px] font_semibold_16 text-[#161616] mb-[16px]">
              Organisation details
            </p>
            <div className="mb-[16px] mx-[24px] rounded-[8px] border-[#8C8C8C4D] border-[1px] flex items-center justify-center cursor-pointer w-[510px] h-[100px]">
              {studioLogo || newLogo ? (
                <div className="w-full h-full" onClick={handleChoose}>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    ref={inputRef}
                    className="hidden"
                    accept=".jpg,.jpeg,.png"
                  />
                  <img
                    className="w-full h-full object-cover"
                    src={
                      newLogo
                        ? URL.createObjectURL(newLogo)
                        : studioObj?.studioLogo
                        ? studioObj?.studioLogo
                        : null
                    }
                    alt="no data"
                  />
                </div>
              ) : (
                <div
                  onClick={handleChoose}
                  className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33]"
                >
                  <input
                    type="file"
                    onChange={handleFileChange}
                    ref={inputRef}
                    className="hidden"
                    accept=".jpg,.jpeg,.png"
                  />
                  <img src={coverImageIcon} alt="" />
                  <p className="font_normal_14 text-[#161616]">
                    Add a Organisation logo
                  </p>
                </div>
              )}
            </div>
            <div className="px-[24px] mb-[16px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Organisation Name <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                placeholder="Enter studio name"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={formValues?.studioName}
                id="studioName"
                onChange={handleChangeInputs}
              />
            </div>
            <div className="px-[24px] mb-[16px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Instagram
              </p>
              <input
                type="text"
                placeholder="Enter instagram link"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={formValues?.socialMediaLinks?.instagram}
                id="instagram"
                onChange={handleChangeSocialMedia}
              />
            </div>
            <div className="px-[24px] mb-[16px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">Facebook</p>
              <input
                type="text"
                placeholder="Enter facebook link"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={formValues?.socialMediaLinks?.facebook}
                id="facebook"
                onChange={handleChangeSocialMedia}
              />
            </div>
            <div className="px-[24px] mb-[16px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">Twitter</p>
              <input
                type="text"
                placeholder="Enter twitter link"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={formValues?.socialMediaLinks?.twitter}
                id="twitter"
                onChange={handleChangeSocialMedia}
              />
            </div>
            <div className="px-[24px] mb-[16px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">Website</p>
              <input
                type="text"
                placeholder="Enter website link"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                value={formValues?.socialMediaLinks?.website}
                id="website"
                onChange={handleChangeSocialMedia}
              />
            </div>
            <div className="seperatorHorizontalNew"></div>
            <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[16px] pb-[24px]">
              <button
                className="cancelButton font_semibold_12"
                onClick={() => setStudioForm(false)}
              >
                Cancel
              </button>
              <button
                disabled={loading}
                className="eventButton font_semibold_12"
                onClick={() => {
                  updateStudio(studioDataId);
                }}
              >
                {loading ? "Loading" : "Save Organisation"}
              </button>
            </div>
          </div>
        </div>
      </PopupWrapper> */}
    </>
  );
}

export default StudioEditFormPopup;
