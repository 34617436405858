// ConfirmationPopup.js

import React from 'react';

const ConfirmationPopup = ({ showConfirmation, onCancel, onConfirm }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-lg">
                <p className="mb-6 text-lg">Are you sure want to continue with Face Training ?</p>
                <div className="mb-6 text-lg">
                    <p>
                        Total Images - <span className="text-blue-500">{showConfirmation?.processedImages}</span>
                    </p>
                    <p>
                        Session Name - <span className="text-green-500">{showConfirmation?.folderName}</span>
                    </p>
                </div>
                <div className="flex justify-end items-center">
                    <button
                        className="bg-gray-300 px-4 py-2 mr-2 rounded"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={onConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;
