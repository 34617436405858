import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Login from "./Pages/Login/Login";
import ProtectedRoute from "./ProtectedRoute";
import { UserAuthContextProvider } from "./Context/UserAuthContext";
//import Dashboard from "./Pages/Dashboard/Dashboard";
import Layout from "./Layouts/Layout";
import ImageUpload from "./Components/ImageUploader/ImageUpload";
import SelectionToolView from "./Pages/SelectionTool/SelectionToolView";
import Clients from "./Pages/Clients/Clients";
// import Payments from "./Pages/Payments/Payments";
// import PaymentsDetails from "./Pages/PaymentsDetails/PaymentsDetails";
import { DataContextProvider } from "./Context/DataContext";
import AlbumGalleryView from "./Pages/AlbumUploadView/AlbumGalleryView/AlbumGalleryView";
import MetaTags from "./MetaTag/MetaTag";
import InvitationView from "./Pages/InvitationView/InvitationView";
import Studio from "./Pages/Studios/Studio";
import Event from "./Pages/Event/Event";
import EventDetailView from "./Pages/EventDetailedView/EventDetailView";
import Album from "./Pages/Album/Album";
import TrainingSession from "./Pages/TrainingSession/TrainingSession";
import AlbumUploadGoogleDrive from "./Pages/AlbumUploadGoogleDrive/AlbumUploadGoogleDrive";
import OfflineMessage from "./Components/OfflineMessage/OfflineMessage";
import Refund from "./Pages/Refund";
import Shipping from "./Pages/Shipping";
import TandC from "./Pages/TandC";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import UserManagement from "./Pages/UserManagement/UserManagement";
import Tools from "./Pages/Tools/Tools";
import PortfolioCreationPage from "./Pages/PortfolioCreationPage/PortfolioCreationPage";
import PortfolioPage from "./Pages/PortfolioPage/PortfolioPage";
import PortfolioLeadsPage from "./Pages/PortfolioLeadsPage/PortfolioLeadsPage";
import PortfolioPaymentPage from "./Pages/PortfolioPaymentPage/PortfolioPaymentPage";
import PhotoShareTool from "./Pages/PhotoShareTool/PhotoShareTool";
import PhotoShareUploader from "./Components/PhotoShareUploader/PhotoShareUploader";
import QRCodeWithGallery from "./Pages/QRCodeWithGallery/QRCodeWithGallery";
import CreateProfile from "./Pages/Studios/CreateProfile";
import StudioFormPopup from "./Components/Forms/StudioForm/StudioFormPopup";
import StudioEditFormPopup from "./Components/Forms/StudioEditForm/StudioEditFormPopup";
import Payments from "./Pages/Payments/Payments";
import ChoosePackage from "./Pages/choosePackage/ChoosePackage";
import PaymentPage from "./Pages/PaymentPage/PaymentPage";
import PaymentsDetails from "./Pages/PaymentsDetails/PaymentsDetails";
import ActivePackages from "./Pages/ActivePackages/ActivePackages";
import Subscriptions from "./Pages/Subscriptions/Subscriptions";
import Invoice from "./Pages/Invoices/Invoice";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return (
    <div className="App">
      <MetaTags />
      {isOnline ? (
        <UserAuthContextProvider>
          <DataContextProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/refund" element={<Refund />} />
              <Route path="/shipping" element={<Shipping />} />
              <Route path="/tandc" element={<TandC />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route
                path="/photosharetool/:albumId/:photoshareid"
                element={<ProtectedRoute Component={QRCodeWithGallery} />}
              />
              <Route
                path="/createProfile"
                element={<ProtectedRoute Component={CreateProfile} />}
              />

              <Route path="/" element={<Layout />}>
                <Route path="/" element={<Navigate to="/Event" />} />
                <Route
                  path="/clients"
                  element={<ProtectedRoute Component={Clients} />}
                />
                <Route
                  path="/choosepackage"
                  element={<ProtectedRoute Component={ChoosePackage} />}
                />
                <Route
                  path="/invoices/:subId"
                  element={<ProtectedRoute Component={Invoice} />}
                />
                <Route
                  path="/subscriptions"
                  element={<ProtectedRoute Component={Subscriptions} />}
                />
                <Route
                  path="/activepackages"
                  element={<ProtectedRoute Component={ActivePackages} />} 
                />

                <Route
                  path="/paymentpage"
                  element={<ProtectedRoute Component={PaymentPage} />}
                />
                <Route
                  path="/payments"
                  element={<ProtectedRoute Component={Payments} />}
                />
                <Route
                  path="/crewusers"
                  element={<ProtectedRoute Component={UserManagement} />}
                />
                {/* <Route
                  path="/payments/:projectId"
                  element={<ProtectedRoute Component={PaymentsDetails} />}
                /> */}
                <Route
                  path="/organizations"
                  element={<ProtectedRoute Component={Studio} />}
                />

                {/* <Route
                  path="/organizations/create"
                  element={<ProtectedRoute Component={StudioFormPopup} />}
                />
                <Route
                  path="/organizations/edit"
                  element={<ProtectedRoute Component={StudioEditFormPopup} />}
                /> */}
                <Route
                  path="/album"
                  element={<ProtectedRoute Component={Album} />}
                />
                <Route
                  path="/portfolio"
                  element={<ProtectedRoute Component={PortfolioPage} />}
                />
                <Route
                  path="/portfolio/create"
                  element={<ProtectedRoute Component={PortfolioCreationPage} />}
                />
                <Route
                  path="/portfolio/create/:requestId"
                  element={<ProtectedRoute Component={PortfolioPaymentPage} />}
                />
                <Route
                  path="/portfolioLeads"
                  element={<ProtectedRoute Component={PortfolioLeadsPage} />}
                />
                <Route
                  path="/Event"
                  element={<ProtectedRoute Component={Event} />}
                />
                <Route
                  path="/Event/:eventId"
                  element={<ProtectedRoute Component={EventDetailView} />}
                />
                <Route
                  path="/Event/:projectId/facedetection/:shootmatesAlbumId"
                  element={<ProtectedRoute Component={TrainingSession} />}
                />
                <Route
                  path="/Event/:projectId/facedetection/:shootmatesAlbumId/upload/:sectionId"
                  element={<ProtectedRoute Component={ImageUpload} />}
                />
                <Route
                  path="/Event/:projectId/albumdetail/:shootmatesAlbumId"
                  element={<ProtectedRoute Component={AlbumGalleryView} />}
                />
                <Route
                  path="/Event/:projectId/selectiontool/:shootmatesAlbumId/upload/:sectionId"
                  element={<ProtectedRoute Component={ImageUpload} />}
                />
                <Route
                  path="/Event/:projectId/selectiontool/:shootmatesAlbumId"
                  element={<ProtectedRoute Component={SelectionToolView} />}
                />
                <Route
                  path="/Event/:projectId/photosharetool/:shootmatesAlbumId"
                  element={<ProtectedRoute Component={PhotoShareTool} />}
                />
                <Route
                  path="/Event/:projectId/photosharetool/:shootmatesAlbumId/upload/:sectionId"
                  element={<ProtectedRoute Component={PhotoShareUploader} />}
                />
                <Route
                  path="/Event/:projectId/albumdetail/:shootmatesAlbumId/upload/:sectionId"
                  element={<ProtectedRoute Component={ImageUpload} />}
                />
                <Route
                  path="/Event/:projectId/invitationdetails/:shootmatesAlbumId"
                  element={<ProtectedRoute Component={InvitationView} />}
                />
                <Route
                  path="/Event/:projectId/shootmates/:shootmatesAlbumId/albumMedia/:folderId"
                  element={
                    <ProtectedRoute Component={AlbumUploadGoogleDrive} />
                  }
                />
                <Route
                  path="/tools"
                  element={<ProtectedRoute Component={Tools} />}
                />
                <Route
                  path="/paymentdetails"
                  element={<ProtectedRoute Component={PaymentsDetails} />}
                />
              </Route>
            </Routes>
          </DataContextProvider>
        </UserAuthContextProvider>
      ) : (
        <OfflineMessage />
      )}
    </div>
  );
}

export default App;
