import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { dataContext } from "../../Context/DataContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

function Subscriptions() {
  const data = [
    {
      id: 1,
      name: "Subscriptions",
    },
    // {
    //   id: 2,
    //   name: "Invoices",
    // },
  ];
  const {
    getSubscriptions,
    roleSelected,
    subscriptions,
    formatDate,
    bytesToGB,
  } = useContext(dataContext);
  const [isSubscriptions, setIsSubscriptions] = useState("Subscriptions");
  const [paymentPlan, setPaymentPlan] = useState("ONETIME");
  const [status, setStatus] = useState("ACTIVE");
  const navigate = useNavigate();
  useEffect(() => {
    if (isSubscriptions == "Subscriptions") {
      if (!isEmpty(roleSelected) && paymentPlan && status) {
        getSubscriptions(roleSelected?.studioId, paymentPlan, status);
      }
    } else {
      console.log("Invoices");
    }
  }, [roleSelected, paymentPlan, status, isSubscriptions]);

  const handleSub = (data) => {
    setIsSubscriptions(data);
  };

  const handleFrequencyChange = (e) => {
    let data = e.target.value;
    let value = data.toUpperCase();
    setPaymentPlan(value);
  };
  const handleStatusChange = (e) => {
    let data = e.target.value;
    let value = data.toUpperCase();
    setStatus(value);
  };

  return (
    <div>
      <div className="flex flex-col px-[22px] items-start justify-start ">
        <div className="w-[400px] mt-4 gap-[10px] p-[10px]  flex">
          {data?.map((item) => (
            <button
              onClick={() => handleSub(item?.name)}
              className="text-[32px] font-[600]"
              //   className={`w-full
              //     ${
              //       item?.name == isSubscriptions
              //         ? "bg-[#4d58e3] text-[#fff]"
              //         : "bg-[#f6f6f6] border-[1px] border-opacity-40 border-[#111]"
              //     }
              //      rounded-[20px] p-[10px] `}
            >
              {" "}
              {item?.name}
            </button>
          ))}
        </div>

        <div className="flex gap-[20px] mt-[20px] justify-start items-start">
          <div>
            <>
              <label htmlFor="paymentFrequency" className="mr-2 text-md">
                Payment Plan
              </label>
              <select
                id="paymentFrequency"
                onChange={handleFrequencyChange}
                className="p-2 border border-[#787878] rounded"
              >
                <option value="" disabled>
                  Choose Payment Plan
                </option>
                <option value="OneTime">OneTime</option>
                <option value="Quaterly">Quaterly</option>   
              </select>
            </>
          </div>

          <div className="">
            {" "}
            <label htmlFor="status" className="mr-2 text-md">
              Status
            </label>
            <select
              id="status"
              onChange={handleStatusChange}
              className="p-2 border border-[#787878] rounded"
            >
              <option value="" disabled>
                Choose Status
              </option>
              <option value="Active">Active</option>
              <option value="Created">Created</option>
            </select>
          </div>
        </div>
      </div>

      <div className="p-4 overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2 text-left">
                sl no
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Studio ID
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Package Name
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Package Type
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Price
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Storage (GB)
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Payment Plan
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Status
              </th>

              <th className="border border-gray-300 px-4 py-2 text-left">
                Created At
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left">
                Invoice
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptions?.map((item, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">
                  {index + 1}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item?.studioId}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item?.packageDetails?.packageName}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item?.packageDetails?.packageType}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item?.packageDetails?.price}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item?.packageDetails?.storageInGb}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item?.paymentPlan}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {item?.status}
                </td>

                <td className="border border-gray-300 px-4 py-2">
                  {formatDate(item?.updatedAt)}
                </td>

                <td className="border border-gray-300 px-4 py-2">
                  <button
                    onClick={() => navigate(`/invoices/${item?._id}`)}
                    className="px-[10px] rounded-[10px] text-[#fff] py-[5px] bg-[#7f7ff2]"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Subscriptions;
