import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { dataContext } from '../../../Context/DataContext';
import { userAuthContext } from '../../../Context/UserAuthContext';
import { PopupWrapper } from '../../../UI/PopupWrapper/PopupWrapper';
import ClientSearchDropDown from '../../ClientSearchDropDown/ClientSearchDropDown';
import ClientForm from '../ClientForm/ClientForm';
import './LeadForm.css'

function LeadForm({ update, setUpdate, visible, setpopUp, idkey, leadObj, setDropdown }) {
    const [loading, setLoading] = useState(false)
    const { studioId } = useContext(dataContext)
    const { userIdToken, currentUser } = useContext(userAuthContext)
    const tokenData = userIdToken || currentUser?.accessToken;
    const [search, setSearch] = useState("")
    const [clientSearchData, setClientSearchData] = useState([])
    const [clientSelect, setClientSelect] = useState(false)
    const [clientPopUp, setClientPopUp] = useState(false)
    const [studioData, setStudioData] = useState({})
    const initialValues = {
        leadName: leadObj?.leadName ? leadObj.leadName : "",
        jobTypes: leadObj?.jobTypes ? leadObj.jobTypes : "",
        workflows: leadObj?.workflows ? leadObj.workflows : "",
        leadSources: leadObj?.leadSources ? leadObj.leadSources : "",
        notConfirmed: leadObj?.notConfirmed ? leadObj.notConfirmed : "FALSE",
        startDate: leadObj?.startDate ? leadObj.startDate : "",
        endDate: leadObj?.endDate ? leadObj.endDate : "",
        allDay: leadObj?.allDay ? leadObj.allDay : "FALSE",
        startTime: leadObj?.startTime ? leadObj.startTime : "",
        endTime: leadObj?.endTime ? leadObj.endTime : ""
    }
    useEffect(() => {
        let CancelToken = axios.CancelToken;
        let source = CancelToken.source();
        if (search !== "") {
            clientSearch(source)
        }
        return () => {
            source.cancel('Cancelled due to stale request');
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [search])

    useEffect(() => {
        getStudioData()
     // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [])

    const [formValues, setFormValues] = useState(initialValues)

    const handleChange = (e) => {
        e.preventDefault()
        setFormValues({
            ...formValues,
            [e.target.id]: e.target.value
        })
    }

    const getStudioData = async () => {
        try {
            let config = {
                method: 'get',
                url: `${process.env.REACT_APP_GET_ALL_STUDIO_DATA}/${studioId}`,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios(config)
            if (response.status === 201) {
                setStudioData(response.data.data)
            }
        } catch (error) {
            console.log("error", error);
        }
    }


    const clientSearch = async (source) => {
        try {
            let config = {
                method: 'get',
                url: `${process.env.REACT_APP_GET_ALL_CLIENTS}/${studioId}?search=${search}`,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',
                },
                cancelToken: source.token
            }
            const response = await axios(config)
            if (response.status === 201) {
                setClientSearchData(response.data.data)
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("cancelled");
            } else {
                throw error;
            }
        }

    }

    const createLead = async () => {
        setLoading(true)
        try {
            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_CREATE_LEAD}/${studioId}`,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',
                },
                data: formValues
            }
            const response = await axios(config)
            setpopUp(false)
            if (response.status === 201) {
                setLoading(false)
                setUpdate(!update)
            }
        } catch (error) {
            console.log("error", error);
            setpopUp(true)
            setLoading(false)
        }
    }

    const updateLead = async () => {
        setLoading(true)
        try {
            let config = {
                method: 'patch',
                url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesProject/api/shootmates/updateProject/${leadObj._id}/${studioId}`,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',
                }, data: formValues
            }
            const response = await axios(config)
            setpopUp(false)
            if (response.status === 201) {
                setLoading(false)
                setUpdate(!update)
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    return (
        <PopupWrapper visible={visible}>
            <div className='w-[60%] h-[80%] overflow-y-scroll'>
                {clientPopUp &&
                    <ClientForm
                        update={update} setUpdate={setUpdate} visible={clientPopUp} setpopUp={setClientPopUp} idkey="create" />}
                <div className='leadform-heading p-[24px] flex flex-row justify-between'>
                    <h3>{idkey === "update" ? 'Edit Lead' : 'Add a New Lead'}</h3>
                    <button onClick={() => {
                        setpopUp(false)
                        setDropdown(false)
                    }}>Close</button>
                </div>
                <div className='leadform-container'>
                    <div className="leadformgrid-container">
                        <div className='item1'>
                            <p>Client Contact<span className='text-red-600'>*</span></p>
                            <input
                                type="text"
                                placeholder='Start entering...'
                                onFocus={() => { setClientSelect(true) }}
                                onChange={(e) =>
                                    setSearch(e.target.value)}
                                value={search} />
                            {clientSearchData.length !== 0 && clientSelect && search !== '' && <ClientSearchDropDown
                                formValues={formValues} setFormValues={setFormValues} clientSearchData={clientSearchData} setSearch={setSearch} setClientSelect={setClientSelect} />}
                        </div>
                        <div className='item2'>
                            <p>OR</p>
                        </div>
                        <div className='item3'>
                            <button
                                className='px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none'
                                onClick={() => setClientPopUp(true)}>Add New Client</button>
                        </div>
                        <div className='item4'>
                            <p>Lead Deatils</p>
                        </div>
                        <div className='item5'>
                            <p>Lead Name:<span className='text-red-600'>*</span></p>
                            <input
                                type="text"
                                id="leadName"
                                onChange={handleChange}
                                value={formValues.leadName} />
                        </div>
                        <div className='item6'>
                            <p>Job Type:<span className='text-red-600'>*</span></p>
                            <select onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    jobTypes: studioData.jobTypes[e.target.value]
                                })
                            }}
                                value={studioData.jobTypes?.findIndex(el =>
                                    el.jobtypeId === formValues?.jobTypes?.jobtypeId)}>
                                <option value="">--Please choose an option--</option>
                                {studioData?.jobTypes?.map((obj1, index) => {
                                    return (
                                        <option key={index} value={index}
                                        >{obj1.typeName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {/* <div className='item18'>
                            <p>Workflow:<span className='text-red-600'>*</span></p>
                            <select onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    workflows: studioData.workflows[e.target.value]
                                })
                            }}
                                value={studioData.workflows?.findIndex(el =>
                                    el.workflowId === formValues?.workflows?.workflowId)}>
                                <option value="">--Please choose an option--</option>
                                {studioData?.workflows?.map((obj3, index) => {
                                    return (
                                        <option key={index} value={index}
                                        >{obj3.workflowName}</option>
                                    )
                                })}
                            </select>
                        </div> */}
                        <div className='item7'>
                            <p>Lead Sources:<span className='text-red-600'>*</span></p>
                            <select onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    leadSources: studioData.leadSources[e.target.value]
                                })
                            }}
                                value={studioData.leadSources?.findIndex(el =>
                                    el.leadId === formValues?.leadSources?.leadId)}>
                                <option value="">--Please choose an option--</option>
                                {studioData?.leadSources?.map((obj2, index) => {
                                    return (
                                        <option key={index} value={index}>{obj2.leadName}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='item8'>
                            <p>Schedule</p>
                        </div>
                        <div className='item17'>
                            <input type="checkbox" onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    notConfirmed: formValues.notConfirmed === "FALSE" ? "TRUE" : "FALSE"
                                })
                            }} value={formValues.notConfirmed}
                                checked={
                                    formValues.notConfirmed === "FALSE" ? false : true
                                } />
                            <label>Date not yet confirmed</label>
                        </div>
                        {formValues.notConfirmed === "FALSE" && <>
                            <div className='item9'>
                                <p>Date<span className='text-red-600'>*</span></p>
                                <input type="date" onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        startDate: e.target.value
                                    })
                                }}
                                    value={formValues.startDate} />
                            </div>
                            <div className='item10'>
                                <p>To</p>
                            </div>
                            <div className='item11'>
                                <input type="date" onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        endDate: e.target.value
                                    })
                                }}
                                    value={formValues.endDate} />
                            </div>
                            <div className='item12'>
                                <input type="checkbox"
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            allDay: formValues.allDay === "FALSE" ? "TRUE" : "FALSE"
                                        })
                                    }} value={formValues.allDay}
                                    checked={formValues.allDay === "FALSE" ? false : true
                                    } />
                                <label>All Day</label>
                            </div>
                            {formValues.allDay === "FALSE" && <> <div className='item13'>
                                <p>Time<span className='text-red-600'>*</span></p>
                                <input type="time" onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        startTime: e.target.value
                                    })
                                }}
                                    value={formValues.startTime} />
                            </div>
                                <div className='item14'>
                                    <p>To</p>
                                </div>
                                <div className='item15'>
                                    <input type="time" onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            endTime: e.target.value
                                        })
                                    }}
                                        value={formValues.endTime} />
                                </div>
                                <div className='item16'>
                                </div> </>}
                        </>
                        }

                    </div>
                    <div className='row3 flex flex-row mt-[12px] gap-x-[12px]'>
                        <button
                            className='px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none'
                            onClick={() => {
                                setpopUp(false)
                                setDropdown(false)
                            }}>Cancel</button>
                        {loading ?
                            <button className='px-6 py-2 text-white rounded-full bg-[#1b2835] focus:outline-none'>Loading</button> :
                            <button className='px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none'
                                onClick={idkey === "update" ? updateLead : createLead} >Update Lead</button>}
                    </div>
                </div>

            </div >
        </ PopupWrapper >
    )

}

export default LeadForm
