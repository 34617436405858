import React, { useContext, useEffect, useRef, useState } from "react";
import ChoosePackageComponent from "../../Components/ChoosePackageComponent/ChoosePackageComponent";
import { isEmpty, method } from "lodash";
import axios from "axios";
import { dataContext } from "../../Context/DataContext";
import { useNavigate } from "react-router-dom";
import Lottie from "../../Assets/Animation/Loading";
import PackageShowPopUp from "../../Components/ChoosePackageComponent/PackageShowPopUp";
import TrialPopup from "../../Components/TrialPopUp/TrialPopup";
import FlexiLastCard from "../../Components/ChoosePackageComponent/FlexiLastCard";
import SelectedPackagePopup from "../../Components/ChoosePackageComponent/SelectedPackagePopup";
// css in app.css

function ChoosePackage() {
  let plans = [
    {
      id: 1,
      key: "YEARLY",
      name: "Yearly Plans",
    },
    {
      id: 2,
      key: "FLEXI",
      name: "Flexi Plans",
    },
    // {
    //   id: 3,
    //   key: "TRIAL",
    //   name: "Trial",
    // },
  ];
  const {
    getPackages,
    packages,
    loading,
    studioRole,
    selectedPlan,
    setSelectedPlan,
    roleSelected,
    selectedPackage,
    PackageSelection,
    flexiPackagePayment,
    paySelResponse,
    setPaySelResponse,
    activePackages,
  } = useContext(dataContext);
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("YEARLY");
  const [paymentPlan, setPaymentPlan] = useState("");
  const [popOpen, setPopOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [centerIndex, setCenterIndex] = useState(0);
  const [inAPackage, setInApackage] = useState(false);
  const [inTrialPackage, setTrialPackage] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    getPackages();
  }, []);
  // useEffect(() => {
  //   return () => setErrorMsg(false);
  // }, [errorMsg]);
  const handleFrequencyChange = (event) => {
    setErrorMsg(false);
    setPaymentPlan(event);
  };

  useEffect(() => {
    setPaySelResponse("");
  }, [selectedType]);

  const filteredPackages = packages?.filter(
    (item) => item?.packageType === selectedType
  );
  // const flexiPackages = packages?.filter(
  //   (item) => item?.packageType === "FLEXI"
  // );

  const handlePaymentSelect = async (
    packageId,
    studioId,
    paymentPlan,
    packageType
  ) => {
    setPopOpen(false);
    PackageSelection(packageId, studioId, paymentPlan, packageType);
  };
  const handlePaymentFlexi = async (studioId, albumId) => {
    setPopOpen(false);
    flexiPackagePayment(studioId, albumId);
  };

  const handleNavigate = () => {
    if (isEmpty(studioRole)) {
      navigate("/organizations");
    } else {
      navigate("/Event");
    }
  };

  const handleNavigation = () => {
    if (isEmpty(studioRole)) {
      navigate("/organizations");
    } else if (
      activePackages?.activePackage === true &&
      activePackages?.packageDetails?.packageType === "YEARLY"
    ) {
      setInApackage(true);
    } else {
      setPopOpen(true);
    }
  };

  const handleNavigateTrial = () => {
    if (
      activePackages?.activePackage === true &&
      activePackages?.packageDetails?.packageType === "TRIAL"
    ) {
      setTrialPackage(true);
    } else {
      setSelectedType("TRIAL");
    }
  };

  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    const scrollLeft = scrollContainer.scrollLeft;
    const cardWidth = scrollContainer.scrollWidth / filteredPackages.length;

    const newIndex = Math.round(scrollLeft / cardWidth);
    setCenterIndex(newIndex);
  };

  const scrollTo = (direction) => {
    const scrollContainer = scrollContainerRef.current;
    const cardWidth = scrollContainer.scrollWidth / filteredPackages.length;

    if (direction === "left") {
      scrollContainer.scrollBy({ left: -cardWidth, behavior: "smooth" });
    } else if (direction === "right") {
      scrollContainer.scrollBy({ left: cardWidth, behavior: "smooth" });
    }
  };

  console.log(activePackages, "active");

  return (
    <>
      {inAPackage && (
        <SelectedPackagePopup
          msg={"You are already using a Yearly package"}
          visible={inAPackage}
          onClose={setInApackage}
        />
      )}
      {inTrialPackage && (
        <SelectedPackagePopup
          msg={"You are already using a Trial Package"}
          visible={inTrialPackage}
          onClose={setTrialPackage}
        />
      )}

      {popOpen && !isEmpty(roleSelected) && !isEmpty(selectedPackage) && (
        <PackageShowPopUp
          visible={true}
          packageDetails={selectedPackage}
          studioDetails={roleSelected}
          handleFrequencyChange={handleFrequencyChange}
          paymentPlan={paymentPlan}
          setPopOpen={setPopOpen}
          handlePaymentSelect={handlePaymentSelect}
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
          handlePaymentFlexi={handlePaymentFlexi}
          setSelectedType={setSelectedType}
          paySelResponse={paySelResponse}
          loading={loading}
        />
      )}

      {selectedType === "TRIAL" && (
        <TrialPopup
          loading={loading}
          studioDetails={roleSelected}
          setSelectedType={setSelectedType}
          trialData={filteredPackages}
          paySelResponse={paySelResponse}
          handlePaymentSelect={handlePaymentSelect}
        />
      )}
      {loading ? (
        <Lottie />
      ) : (
        // <div className="flex ">
        //   <div
        //     style={{
        //       background: `linear-gradient(26deg, #3035AD 0%, #444BE3 100%)`,
        //     }}
        //     className="w-[234px]  flex-shrink-0 min-h-[100vh] opacity-90"
        //   ></div>
        <div className="min-h-[100vh] relative  w-[100%]  pb-28 px-[56px] bg-[#f6f6f6]">
          <div className="flex items-center pt-5 justify-start gap-[26px]">
            <p className="packageHeading ">Choose any packages to continue</p>
            <div className="planSwitchingDiv ">
              {plans?.map((item, i) => (
                <button
                  onClick={() => setSelectedType(item?.key)}
                  className={` ${
                    item?.key === selectedType
                      ? selectedType === "YEARLY"
                        ? "bg-[#16B67E] text-[#fff] font-[600]"
                        : "bg-[#9747FF] text-[#fff] font-[600]"
                      : "bg-[#F3F3F3] text-[#111] font-[400]"
                  } planSwitchingButton normal-case`}
                  key={i}
                >
                  {" "}
                  {item?.name}{" "}
                </button>
              ))}
            </div>

            <div
              onClick={() => handleNavigateTrial()}
              className="trailPlan ml-[10%] cursor-pointer"
            >
              <p>Start a free trail Now</p>
            </div>
          </div>
          {selectedType === "YEARLY" ? (
            <div className="flex flex-wrap gap-[56px]  mt-[35px]">
              {filteredPackages?.map((item, i) => (
                <ChoosePackageComponent
                  selectedPlan={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                  key={i}
                  index={i}
                  selectedType={selectedType}
                  packages={item}
                />
              ))}
            </div>
          ) : (
            selectedType === "FLEXI" && (
              <div
                ref={scrollContainerRef}
                onScroll={handleScroll}
                className="flex mt-[35px] gap-[20px] w-full overflow-x-auto  [&::-webkit-scrollbar]:hidden"
              >
                {filteredPackages?.map((item, i) => (
                  <>
                    <ChoosePackageComponent
                      centerIndex={centerIndex}
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      key={i}
                      index={i}
                      selectedType={selectedType}
                      packages={item}
                    />
                    {/* Add FlexiLastCard after the 3rd item as an example */}
                    {i === filteredPackages?.length - 1 && <FlexiLastCard />}
                  </>
                ))}
              </div>
            )
          )}

          {/* buttons */}
          {selectedType == "FLEXI" && (
            <>
              {centerIndex + 3 !== filteredPackages?.length && (
                <button
                  onClick={() => scrollTo("right")}
                  className="prevNextBut absolute  right-[5%] bottom-0 top-[35%]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_724_1183)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.707 12.707C15.8944 12.5195 15.9998 12.2652 15.9998 12C15.9998 11.7348 15.8944 11.4805 15.707 11.293L10.05 5.63601C9.95772 5.5405 9.84738 5.46431 9.72537 5.41191C9.60337 5.3595 9.47215 5.33191 9.33937 5.33076C9.20659 5.3296 9.07491 5.3549 8.95202 5.40519C8.82912 5.45547 8.71747 5.52972 8.62357 5.62361C8.52968 5.71751 8.45543 5.82916 8.40515 5.95205C8.35487 6.07495 8.32957 6.20663 8.33072 6.33941C8.33187 6.47219 8.35946 6.60341 8.41187 6.72541C8.46428 6.84742 8.54046 6.95776 8.63597 7.05001L13.586 12L8.63597 16.95C8.45381 17.1386 8.35302 17.3912 8.3553 17.6534C8.35757 17.9156 8.46274 18.1664 8.64815 18.3518C8.83356 18.5372 9.08437 18.6424 9.34657 18.6447C9.60876 18.647 9.86137 18.5462 10.05 18.364L15.707 12.707Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_724_1183">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(-1 0 0 1 24 0)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              )}

              {centerIndex !== 0 && (
                <button
                  onClick={() => scrollTo("left")}
                  className="prevNextBut absolute  left-[5%] bottom-0 top-[35%]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_724_1431)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.29303 12.707C8.10556 12.5195 8.00024 12.2652 8.00024 12C8.00024 11.7348 8.10556 11.4805 8.29303 11.293L13.95 5.63601C14.0423 5.5405 14.1526 5.46431 14.2746 5.41191C14.3966 5.3595 14.5279 5.33191 14.6606 5.33076C14.7934 5.3296 14.9251 5.3549 15.048 5.40519C15.1709 5.45547 15.2825 5.52972 15.3764 5.62361C15.4703 5.71751 15.5446 5.82916 15.5949 5.95205C15.6451 6.07495 15.6704 6.20663 15.6693 6.33941C15.6681 6.47219 15.6405 6.60341 15.5881 6.72541C15.5357 6.84742 15.4595 6.95776 15.364 7.05001L10.414 12L15.364 16.95C15.5462 17.1386 15.647 17.3912 15.6447 17.6534C15.6424 17.9156 15.5373 18.1664 15.3518 18.3518C15.1664 18.5372 14.9156 18.6424 14.6534 18.6447C14.3912 18.647 14.1386 18.5462 13.95 18.364L8.29303 12.707Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_724_1431">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              )}
            </>
          )}

          <div className="cursor-pointer flex mt-[20px] items-center justify-between">
            <p onClick={() => navigate(`/Event`)} className="skipButton">
              {" "}
              Skip for now
            </p>

            {paySelResponse && (
              <p className="text-[16px] text-[red]"> {paySelResponse} </p>
            )}
            {selectedType == "FLEXI" ? (
              <button
                onClick={() => handleNavigate()}
                className="disabled:opacity-50 purchaseContinue capitalize"
              >
                Continue
              </button>
            ) : (
              <button
                disabled={selectedPlan == ""}
                onClick={() => handleNavigation()}
                className="disabled:opacity-50 purchaseContinue capitalize"
              >
                Purchase and Continue
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ChoosePackage;
