import React, { useState } from "react";
import { ref, uploadBytesResumable } from "firebase/storage";
import { photosharetool } from "../../store/firebase-config";

const PhotoShareQRGenerator = ({
  onClose,
  studioDetails,
  selectedMedia,
  memshotAlbumId,
  slugId,
}) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const studioUniqueText = studioDetails?.studioUniqueText || "photosharetool";
  const handleUpload = () => {
    setIsUploading(true);
    const name = `${Date.now()}`;
    const jsonData = {
      media: selectedMedia,
      studioUniqueText: studioUniqueText,
      slugId: slugId,
      name: name,
    };
    const jsonBlob = new Blob([JSON.stringify(jsonData)], {
      type: "application/json",
    });

    const storageRef = ref(photosharetool, `${memshotAlbumId}/${name}.json`);

    const uploadTask = uploadBytesResumable(storageRef, jsonBlob);

    const origin = window.location.origin; // Get the full URL origin (protocol + hostname + port)

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed:", error);
        setIsUploading(false);
      },
      async () => {
        const url = `${origin}/photosharetool/${memshotAlbumId}/${name}`;
        setIsUploading(false);
        window.open(url, "_blank");
      }
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 w-full h-full">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center z-60 max-w-sm w-full">
        <h2 className="text-xl font-bold mb-4">Photo Share QR Generator</h2>

        <div>
          <button
            onClick={handleUpload}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
          >
            {isUploading ? "Loading" : "Generate QR"}
          </button>
          {/* {isUploading && (
            <div className="w-full bg-gray-200 h-4 rounded-full">
              <div
                className="bg-green-500 h-4 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )} */}
        </div>
        <button
          onClick={onClose}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PhotoShareQRGenerator;
