import React, { useState, useEffect } from "react";
import styles from "./Pricing.module.css";

export default function Pricing({
  priceData,
  albumType,
  selectedPhotosharePackage,
  setSelectedPhotosharePackage,
  setPricingDataApi,
  setTotalPrice,
}) {
  const handleSelectPackageWithNoFilter = (data) => {
    setSelectedPhotosharePackage(data);
    setPricingDataApi(data?.config);
    setTotalPrice(data?.config?.price);
  };
  const handleSelectPackageWithFilter = (data, filterSelected) => {
    setSelectedPhotosharePackage(data);
    setPricingDataApi(
      filterSelected === "three" ? data?.filter[0] : data?.filter[1]
    );
    setTotalPrice(
      filterSelected === "three"
        ? data?.filter[0]?.price
        : data?.filter[1]?.price
    );

    // } else {
    //   setSelectedPhotosharePackage(data)
    //   setPricingDataApi(data?.filter[0])
    //   setTotalPrice(data?.filter[0]?.price)
    // }
  };

  return (
    <></>
    // <div className="containFull">
    //   <div className="w-full h-full min-h-[70vh] px-[5.26%] xl:px-[8.33%] mb-[50px] xl:mb-[100px]">
    //     {
    //       albumType === "ALBUM" &&
    //       <SignatureAlbum
    //         handleSelectPackage={handleSelectPackageWithNoFilter}
    //         selectedPhotosharePackage={selectedPhotosharePackage}
    //         name={"Signature Album"}
    //         priceDetail={priceData?.SignatureAlbum}
    //       />
    //     }
    //     {
    //       albumType === "FACEDETECTION" &&
    //       <FaceDetection
    //         handleSelectPackage={handleSelectPackageWithFilter}
    //         selectedPhotosharePackage={selectedPhotosharePackage}
    //         name={"Face Detection"}
    //         priceDetail={priceData?.FaceDetection}
    //       />
    //     }
    //     {
    //       albumType === "SELECTIONTOOL" &&
    //       <SelectionTool
    //         handleSelectPackage={handleSelectPackageWithNoFilter}
    //         selectedPhotosharePackage={selectedPhotosharePackage}
    //         name={"Selection Tool"}
    //         priceDetail={priceData?.SelectionTool}
    //       />
    //     }
    //   </div>
    // </div>
  );
}

function SignatureAlbum({
  priceDetail,
  selectedPhotosharePackage,
  handleSelectPackage,
}) {
  return (
    <div className={`${styles.packageSection} no-scrollbar`}>
      {priceDetail?.map((obj, i) => (
        <div
          className={`${
            selectedPhotosharePackage?.id === obj?.id
              ? styles.selectedCard
              : styles.cards
          }`}
          key={i}
          onClick={() => handleSelectPackage(obj, i)}
        >
          <div className={styles.cardsHeading}>
            <p>{obj?.heading}</p>
          </div>
          <div
            style={{
              background: obj?.priceBgColor,
            }}
            className={styles.cardsPrice}
          >
            <p>{obj?.price}</p>
          </div>
          <div className={styles.cardsDescription}>
            <p className="font_semibold_16 text-[#161616]">{obj?.text}</p>
            {obj?.points ? (
              <div className="px-[20px] mt-[8px]">
                {obj?.points?.map((item, j) => (
                  <ul key={j} style={{ listStyleType: "disc" }}>
                    <li>{item}</li>
                  </ul>
                ))}
              </div>
            ) : (
              <div className="mt-[8px]">
                <p>{obj?.contact}</p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function FaceDetection({
  priceDetail,
  selectedPhotosharePackage,
  handleSelectPackage,
}) {
  return (
    <div className={`${styles.packageSection} no-scrollbar`}>
      {priceDetail?.map((obj, i) => (
        <FaceDetectionData
          data={obj}
          i={i}
          selectedPhotosharePackage={selectedPhotosharePackage}
          handleSelectPackage={handleSelectPackage}
        />
      ))}
    </div>
  );
}

function FaceDetectionData({
  i,
  selectedPhotosharePackage,
  handleSelectPackage,
  data,
}) {
  const [filterSelected, setFilterSelected] = useState("three");
  useEffect(() => {
    // Reset filterSelected when data changes
    setFilterSelected("three");
  }, [data]);

  return (
    <div
      className={`${
        selectedPhotosharePackage?.id === data?.id
          ? styles.selectedCard
          : styles.cards
      }`}
      key={i}
      onClick={() => handleSelectPackage(data, filterSelected, i)}
    >
      <div className={styles.cardsHeading}>
        <p>{data?.heading}</p>
      </div>
      <div className="py-[15px] flex justify-center">
        <div className={styles.filterFaceDetection}>
          <div
            onClick={() => setFilterSelected("three")}
            style={{
              background: filterSelected === "three" ? "#17064A" : "#EDEDED",
              color: filterSelected === "three" ? "#fff" : "#161616",
            }}
            className={styles.filterChip}
          >
            3 Month
          </div>
          <div
            onClick={() => setFilterSelected("one")}
            style={{
              background: filterSelected === "one" ? "#17064A" : "#EDEDED",
              color: filterSelected === "one" ? "#fff" : "#161616",
            }}
            className={styles.filterChip}
          >
            1 Year
          </div>
        </div>
      </div>
      <div
        style={{
          background: data?.priceBgColor,
        }}
        className={styles.cardsPrice}
      >
        <p>
          {filterSelected === "three"
            ? data?.threeMonthPrice
            : data?.oneYearPrice}
        </p>
      </div>
      <div className={styles.cardsDescription}>
        <p className="font_semibold_16 text-[#161616]">
          {filterSelected === "three"
            ? data?.threeMonthText
            : data?.oneYearText}
        </p>
        {data?.points ? (
          <div className="px-[20px] mt-[8px]">
            {data?.points?.map((item, index) => (
              <ul key={index} style={{ listStyleType: "disc" }}>
                <li>{item}</li>
              </ul>
            ))}
          </div>
        ) : (
          <div>
            <p className="font_semibold_16 text-[#161616]">{data?.text}</p>
            <p className="mt-[8px]">{data?.contact}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function SelectionTool({
  priceDetail,
  selectedPhotosharePackage,
  handleSelectPackage,
}) {
  return (
    <div className={`${styles.packageSection} no-scrollbar`}>
      {priceDetail?.map((obj, i) => (
        <div
          className={`${
            selectedPhotosharePackage?.id === obj?.id
              ? styles.selectedCard
              : styles.cards
          }`}
          key={i}
          onClick={() => handleSelectPackage(obj, i)}
        >
          <div className={styles.cardsHeading}>
            <p>{obj?.heading}</p>
          </div>
          <div
            style={{
              background: obj?.priceBgColor,
            }}
            className={styles.cardsPrice}
          >
            <p>{obj?.price}</p>
          </div>
          <div className={styles.cardsDescription}>
            <p className="font_semibold_16 text-[#161616]">{obj?.text}</p>
            {obj?.points ? (
              <div className="px-[20px] mt-[8px]">
                {obj?.points?.map((item, j) => (
                  <ul key={j} style={{ listStyleType: "disc" }}>
                    <li>{item}</li>
                  </ul>
                ))}
              </div>
            ) : (
              <div className="mt-[8px]">
                <p>{obj?.contact}</p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
