import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./InvitationStudio.module.css";
import { dataContext } from "../../Context/DataContext";
export default function InvitaionStudio({
  section,
  setTemplateJson,
  index,
  setImagesToUpload,
  preview,
  packageType,
}) {
  const studioImageRef = useRef();
  const { roleSelected, studioDetails } = useContext(dataContext);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    section?.sectionData?.studioLogo || ""
  );
  const handleThanksImageButton = () => {
    studioImageRef.current.click();
  };
  useEffect(() => {
    if (section?.sectionData) {
      setTemplateJson((prev) => {
        const newArray = prev.sections;
        const newObject = newArray[index];
        newObject.sectionData.studioName =
          studioDetails?.studioName || roleSelected?.studioName;
        newObject.sectionData.email =
          studioDetails?.email || roleSelected?.email;
        newObject.sectionData.phoneNumber =
          studioDetails?.phoneNumber || roleSelected?.phoneNumber;
        newObject.sectionData.website =
          studioDetails?.website || roleSelected?.website;
        newObject.sectionData.studioLogo =
          section?.sectionData?.studioLogo ||
          studioDetails?.studioLogo ||
          roleSelected?.studioLogo;
        newArray[index] = newObject;
        return {
          ...prev,
          sections: newArray,
        };
      });
      setImageUrl(
        section?.sectionData?.studioLogo ||
          studioDetails?.studioLogo ||
          roleSelected?.studioLogo
      );
    }
  }, [section?.sectionData]);
  const handleCoverImageChange = (e) => {
    let imageFile = e.target.files[0];
    setLoading(true);
    setImageUrl(imageFile && URL.createObjectURL(imageFile));
    setImagesToUpload((prev) => ({
      ...prev,
      [index]: {
        index: index,
        type: section?.sectionProps?.sectionType,
        images: [imageFile],
      },
    }));
  };
  return (
    <div className=" bg-[#000] px-[20px] ">
      {preview ? (
        <div className="p-[16px] flex items-center justify-center border-y-[1px] border-y-[#353535] ">
          <div className="w-[150px] h-[110px] p-[10px]">
            <img
              src={imageUrl}
              className="w-full h-full object-contain opacity-1"
            />
          </div>
          <div className="px-[40px] py-[16px] border-l-[1px] border-l-[#ffffff1a] text-[#fff]">
            <h2 className="font_bold_21 mb-[10px]">
              {section?.sectionData?.studioName}
            </h2>
            <h3 className="font_normal_13 mb-[19px]">
              {section?.sectionData?.website}
            </h3>
            <p className="font_normal_13 opacity-[0.6]">
              {section?.sectionData?.phoneNumber} {section?.sectionData?.email}
            </p>
          </div>
        </div>
      ) : (
        <div className="py-[35px]">
          <div className={styles.dottedSectionContainerWhite}>
            <div className="flex items-center justify-center gap-[22px]">
              <div
                className={styles.imageSection}
                onClick={() =>
                  packageType !== "YEARLY" && handleThanksImageButton()
                }
              >
                {imageUrl ? (
                  <img
                    style={{
                      display: loading ? "none" : "block",
                    }}
                    src={imageUrl}
                    alt=""
                    className="w-full h-full object-contain opacity-1"
                    onLoad={() => setLoading(false)}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g opacity="0.6">
                      <path
                        opacity="0.5"
                        d="M19 2H5C4.20508 2.00237 3.4434 2.31921 2.8813 2.8813C2.31921 3.4434 2.00237 4.20508 2 5V13.86L5.88 9.98C6.4517 9.43586 7.21074 9.13238 8 9.13238C8.78926 9.13238 9.5483 9.43586 10.12 9.98L12.991 12.867L13.879 11.979C14.4426 11.4185 15.2052 11.1039 16 11.1039C16.7948 11.1039 17.5574 11.4185 18.121 11.979L22 15.86V5C21.9976 4.20508 21.6808 3.4434 21.1187 2.8813C20.5566 2.31921 19.7949 2.00237 19 2Z"
                        fill="white"
                      />
                      <path
                        d="M10.12 9.97994C9.5483 9.4358 8.78926 9.13232 8 9.13232C7.21074 9.13232 6.4517 9.4358 5.88 9.97994L2 13.8599V18.9999C2.00237 19.7949 2.31921 20.5565 2.8813 21.1186C3.4434 21.6807 4.20508 21.9976 5 21.9999H19C19.4034 21.9997 19.8026 21.9181 20.1737 21.76C20.5449 21.6019 20.8803 21.3706 21.16 21.0799L10.12 9.97994Z"
                        fill="white"
                      />
                      <path
                        opacity="0.25"
                        d="M22 15.8579L18.121 11.9789C17.5574 11.4185 16.7949 11.1039 16 11.1039C15.2052 11.1039 14.4426 11.4185 13.879 11.9789L12.991 12.8669L21.156 21.0759C21.698 20.5209 22.001 19.7759 22 18.9999V15.8579Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                )}
                {loading && (
                  <div className="w-full h-full flex items-center justify-center text-[#fff] text-[12px] opacity-[0.6]">
                    <p>Loading...</p>
                  </div>
                )}
              </div>
              <div className={styles.details}>
                <h2>{section?.sectionData?.studioName}</h2>
                <h3>{section?.sectionData?.website}</h3>
                <p>
                  {section?.sectionData?.phoneNumber}{" "}
                  {section?.sectionData?.email}
                </p>
              </div>
            </div>
            {packageType !== "YEARLY" && (
              <button
                onClick={handleThanksImageButton}
                className={`mt-[20px] ${styles.replaceButton}`}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  ref={studioImageRef}
                  onChange={handleCoverImageChange}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.584 10.0827C4.978 10.7285 5.547 11.2494 6.22501 11.5849C6.90302 11.9205 7.66227 12.0569 8.41469 11.9785C9.16711 11.9001 9.88189 11.6099 10.4761 11.1418C11.0703 10.6736 11.5197 10.0466 11.772 9.33338H13.1653C12.9097 10.3216 12.3759 11.2157 11.6273 11.9097C10.8787 12.6037 9.9468 13.0683 8.94208 13.2485C7.93737 13.4288 6.90203 13.317 5.95893 12.9265C5.01583 12.536 4.20454 11.8831 3.62133 11.0454L2 12.6667V8.66671H6L4.584 10.0827ZM11.4167 5.91671C11.0225 5.27082 10.4533 4.7499 9.77504 4.41435C9.09683 4.0788 8.33739 3.94236 7.58479 4.02087C6.83219 4.09937 6.11726 4.3896 5.5229 4.85789C4.92853 5.32619 4.47908 5.95337 4.22667 6.66671H2.83333C3.08891 5.67811 3.62273 4.78359 4.37146 4.08929C5.12019 3.39499 6.05239 2.93007 7.05743 2.7497C8.06247 2.56932 9.09816 2.68107 10.0416 3.07168C10.985 3.46228 11.7966 4.11535 12.38 4.95338L14 3.33338V7.33338H10L11.4167 5.91671Z"
                    fill="#EFEEEE"
                  />
                </svg>
                {imageUrl ? "Replace Image" : "Browse Image"}
              </button>
            )}
            <div className={styles.dottedSectionTitleWhiteAbsolute}>
              <p>Studio Details</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
