import React from 'react';

function OfflineMessage() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-red-500 text-white p-12 text-center border-[20px]">
        <p>You are currently offline. Please check your network connection.</p>
      </div>
    </div>
  );
}

export default OfflineMessage;
