import React from "react";
import { useDropzone } from "react-dropzone";

const UploadPopup = ({
  isUploading,
  handleImageImport,
  showUploadPopup,
  setShowUploadPopup,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/jpg",
    noClick: false,
    noKeyboard: false,
    multiple: true,
    preventDropOnDocument: false,
    disabled: isUploading,
    onDrop: handleImageImport,
  });

  return (
    <div
      className={`fixed inset-0 w-full h-full flex justify-center items-center bg-black bg-opacity-60 z-50 transition-opacity duration-300 ${
        showUploadPopup ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="relative w-full max-w-lg bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="flex justify-between items-center p-5 bg-gradient-to-r from-blue-600 to-blue-800">
          <h2 className="text-xl text-white font-semibold">Upload Media</h2>
          <button
            onClick={() => setShowUploadPopup(false)}
            className="text-white text-xl font-semibold focus:outline-none hover:text-gray-300 transition duration-200"
          >
            &times;
          </button>
        </div>
        <div
          {...getRootProps()}
          className={`p-10 border-4 border-dashed rounded-lg transition-colors duration-200 ${
            isDragActive
              ? "border-blue-600 bg-blue-50"
              : "border-gray-300 bg-gray-100"
          } flex flex-col items-center justify-center`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-blue-600 font-medium">
              Drop your images here...
            </p>
          ) : (
            <>
              <p className="text-gray-500">Drag & drop image files, or</p>
              <button
                type="button"
                className="mt-4 text-blue-700 bg-transparent border border-blue-700 hover:bg-blue-700 hover:text-white font-medium rounded-lg text-sm px-6 py-2.5 transition duration-200"
              >
                Browse Files
              </button>
            </>
          )}
        </div>
        <div className="flex justify-end p-5 bg-gray-100 border-t border-gray-300">
          <button
            onClick={() => setShowUploadPopup(false)}
            disabled={isUploading}
            type="button"
            className={`text-white font-medium rounded-lg text-sm px-6 py-2.5 transition duration-200 ${
              isUploading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 focus:outline-none"
            }`}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>
          <button
            onClick={() => setShowUploadPopup(false)}
            type="button"
            className="ml-3 text-gray-700 bg-transparent border border-gray-700 hover:bg-gray-700 hover:text-white font-medium rounded-lg text-sm px-6 py-2.5 transition duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadPopup;
