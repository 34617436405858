import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import "./PackagePopup.css";
import { NewPopUpWrapper } from "../../UI/PopupWrapper/NewPopUpWrapper";
function PackageShowPopUp({
  visible,
  packageDetails,
  handleFrequencyChange,
  studioDetails,
  paymentPlan,
  handlePaymentSelect,
  setPopOpen,
  setErrorMsg,
  errorMsg,
  handlePaymentFlexi,
  setSelectedType,
  paySelResponse,
  loading,
}) {
  return (
    <NewPopUpWrapper visible={visible}>
      <div className="bg-[#fff] popUpShadow  min-h-[400px] max-h-[450px] min-w-[400px] rounded-[10px] flex flex-col trialPop mt-[5%]   ">
        <div className="flex w-full border-opacity-20 justify-between pb-[20px] items-start border-b-[1px] border-[#475467] ">
          <div className=" flex items-center gap-[12px]">
            <button className=" headDiv">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20.8939 13.553C21.0123 13.7901 21.0318 14.0646 20.948 14.316C20.8641 14.5675 20.6839 14.7754 20.4469 14.894L12.4469 18.894C12.3081 18.9634 12.1551 18.9995 11.9999 18.9995C11.8447 18.9995 11.6917 18.9634 11.5529 18.894L3.55291 14.894C3.32418 14.7707 3.15245 14.5631 3.07409 14.3154C2.99574 14.0676 3.01691 13.799 3.13312 13.5666C3.24934 13.3342 3.45147 13.1561 3.6967 13.0701C3.94193 12.9842 4.211 12.997 4.44691 13.106L11.9999 16.88L19.5539 13.105C19.791 12.9866 20.0655 12.9671 20.3169 13.051C20.5684 13.1348 20.7763 13.315 20.8949 13.552M12.0079 5C12.0452 5 12.0822 5.00233 12.1189 5.007L12.2299 5.027L12.3159 5.051L12.3279 5.057L12.3399 5.059L12.3689 5.073L12.4189 5.092L12.4349 5.101L12.4469 5.106L20.4469 9.106C20.6128 9.18914 20.7523 9.31679 20.8497 9.47468C20.9472 9.63256 20.9988 9.81445 20.9988 10C20.9988 10.1856 20.9472 10.3674 20.8497 10.5253C20.7523 10.6832 20.6128 10.8109 20.4469 10.894L12.4469 14.894C12.3081 14.9634 12.1551 14.9995 11.9999 14.9995C11.8447 14.9995 11.6917 14.9634 11.5529 14.894L3.55291 10.894C3.38703 10.8109 3.24755 10.6832 3.15007 10.5253C3.0526 10.3674 3.00098 10.1856 3.00098 10C3.00098 9.81445 3.0526 9.63256 3.15007 9.47468C3.24755 9.31679 3.38703 9.18914 3.55291 9.106L11.5529 5.106L11.5639 5.101L11.5819 5.091L11.6599 5.059L11.6709 5.057L11.6839 5.051L11.7699 5.027L11.8799 5.007L11.9359 5.002L12.0079 5Z"
                  fill="#13BB82"
                />
              </svg>
            </button>
            <div>
              <p className="FreeTrial flex items-center gap-[4px]">
                {packageDetails?.packageName}
                <span className="days">{packageDetails?.packageType}</span>
              </p>
              <p className="">
                Total Storage{" "}
                <span className="font-[600]">
                  {packageDetails?.storageInGb}{" "}
                  {typeof packageDetails?.storageInGb === "number" ? "GB" : ""}
                </span>{" "}
              </p>
            </div>
          </div>

          <button onClick={() => setPopOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <p className="paymentText">Choose your payment method</p>

        <div
          onClick={() => handleFrequencyChange("ONETIME")}
          className={`${
            paymentPlan === "ONETIME"
              ? "PaySelBox"
              : "border-[#475467] border-opacity-20  border-[1px]"
          } payTypeBox w-full flex justify-between items-start `}
        >
          <div className="w-full">
            <div className="rupees justify-between items-start flex">
              <p>₹ {packageDetails?.price}</p>
              <button
                className={`${
                  paymentPlan === "ONETIME" ? "iconSel" : "iconNotSel"
                } `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.6666 3.5L5.24992 9.91667L2.33325 7"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="flex justify-between w-full items-start">
              <p className="oneTimeOrEmi"> One Time Pay </p>
              <p className="expiry">
                {packageDetails?.storageTenureInMonth} Month Expiry
              </p>
            </div>
          </div>
        </div>

        <div
          onClick={() => handleFrequencyChange("QUATERLY")}
          className={`${
            paymentPlan === "QUATERLY"
              ? "PaySelBox"
              : "border-[#475467] border-opacity-20  border-[1px]"
          } payTypeBox flex w-full justify-between items-start `}
        >
          <div className="w-full">
            <div className="rupees justify-between items-start flex">
              <p>
                ₹ {Math.round(packageDetails?.price / 4)}/
                <span className="ruppesEmi">Quarterly</span>{" "}
              </p>
              <button
                className={`${
                  paymentPlan === "QUATERLY" ? "iconSel" : "iconNotSel"
                } `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.6666 3.5L5.24992 9.91667L2.33325 7"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="flex justify-between w-full items-start">
              <p className="oneTimeOrEmi"> 4 Months EMI Plans </p>
              <p className="expiry">
                {packageDetails?.storageTenureInMonth} Month Expiry
              </p>
            </div>
          </div>
        </div>
        {paySelResponse && (
          <p className="text-[16px] text-[red]">{paySelResponse} </p>
        )}
        {errorMsg && (
          <p className="text-[14px] text-end mr-[10%] text-[red] font-[400] ">
            Please Select Payment Type
          </p>
        )}
        <div
          className={`flex ${
            paySelResponse ? "mt-0" : "mt-[20px]"
          } items-center gap-[12px] justify-center`}
        >
          <button onClick={() => setPopOpen(false)} className="cancelButtonPop">
            Cancel
          </button>
          <button
            onClick={() => {
              paymentPlan === ""
                ? setErrorMsg(true)
                : handlePaymentSelect(
                    packageDetails?._id,
                    studioDetails?.studioId,
                    paymentPlan,
                    packageDetails?.packageType
                  );
            }}
            className="continueButton"
          >
            {loading ? "Loading" : "Continue"}
          </button>
        </div>
      </div>

      {/* <div className="bg-[#fff] my-auto rounded-[20px]  flex flex-col ">
        <div className="justify-start items-start flex  gap-[30px]  p-[20px]">
          <div className="flex flex-col gap-[5px]">
            <p className="underline text-[20px] font-[500]">SelectedPackage </p>
            <p className="text-[16px]">
              packageName: <span>{packageDetails?.packageName}</span>
            </p>
            <p>
              price: <span>{packageDetails?.price}</span>
            </p>
            <p>
              storageInGb:<span>{packageDetails?.storageInGb}</span>{" "}
            </p>
            <p>
              {" "}
              packageType: <span>{packageDetails?.packageType}</span>
            </p>
            {packageDetails?.packageCode && (
              <p>
                PackageCode: <span>{packageDetails?.packageCode}</span>
              </p>
            )}
          </div>

          <div className="flex flex-col gap-[5px]">
            <p className="underline text-[20px] font-[500]">StudioDetails </p>
            <p className="text-[16px]">
              StudioId: <span>{studioDetails?.studioId}</span>
            </p>
            <p>
              StudioName: <span>{studioDetails?.studioName}</span>
            </p>
            {packageDetails?.packageType == "YEARLY" && (
              <>
                <label
                  htmlFor="paymentFrequency "
                  className=" mt-[15px] text-md"
                >
                  Choose payment type
                </label>
                {errorMsg && (
                  <p className="text-[14px] text-end mr-[10%] text-[red] font-[400] ">
                    Please Select Payment Type
                  </p>
                )}
                <div className={`p-2 ${errorMsg ? "border-[red]" : ""}`}>
                  <label className="mr-4">
                    <input
                      type="radio"
                      name="paymentFrequency"
                      value="ONETIME"
                      checked={paymentPlan === "ONETIME"}
                      onChange={handleFrequencyChange}
                      className="mr-1"
                    />
                    One Time
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="paymentFrequency"
                      value="QUATERLY"
                      checked={paymentPlan === "QUATERLY"}
                      onChange={handleFrequencyChange}
                      className="mr-1"
                    />
                    QUATERLY
                  </label>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="justify-between items-center px-[10%] pb-[2%] flex">
          <button
            onClick={() => setPopOpen(false)}
            className="border-[1px] rounded-[20px] border-[#111] px-[20px] py-[10px] "
          >
            {" "}
            close{" "}
          </button>

          <button
            onClick={() => {
              packageDetails?.packageType == "TRIAL"
                ? handlePaymentSelect(
                    packageDetails?._id,
                    studioDetails?.studioId,
                    "ONETIME",
                    packageDetails?.packageType
                  )
                : paymentPlan === ""
                ? setErrorMsg(true)
                : handlePaymentSelect(
                    packageDetails?._id,
                    studioDetails?.studioId,
                    paymentPlan,
                    packageDetails?.packageType
                  );
            }}
            className="border-[1px] rounded-[20px] px-[20px] py-[10px] border-[#111] "
          >
            {" "}
            Proceed{" "}
          </button>
        </div>
      </div> */}
    </NewPopUpWrapper>
  );
}

export default PackageShowPopUp;
