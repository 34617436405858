import React, { useContext, useEffect, useState } from "react";
import { dataContext } from "../../Context/DataContext";
import { isEmpty } from "lodash";
import axios from "axios";
import Loading from "../../Assets/Animation/Loading";
import styles from "./PortfolioPage.module.css";
import { Link } from "react-router-dom";

export default function PortfolioPage() {
  const { roleSelected } = useContext(dataContext);
  const { studioUniqueText } = roleSelected || {};
  const [dataLoading, setDataLoading] = useState(false);
  const [created, setCreated] = useState(true);

  useEffect(() => {
    if (!isEmpty(roleSelected)) {
      getDraftJson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleSelected]);

  const getDraftJson = async () => {
    setDataLoading(true);
    try {
      const config = {
        method: "GET",
        url: `https://storage.googleapis.com/studio_portfolio/${studioUniqueText}/draft.json`,
      };
      await axios(config);
      setCreated(true);
      setDataLoading(false);
    } catch (e) {
      setDataLoading(false);
      setCreated(false);
      console.log(e);
    }
  };

  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        <div className={styles.portfolio}>
          {created ? (
            <div className="w-full">
              <div className="w-full flex justify-between">
                <h1> Portfolio</h1>
                <div className="flex items-center gap-[8px]">
                  <Link
                    to={`https://${studioUniqueText}.memshots.com`}
                    target="_blank"
                  >
                    <button className={styles.buttonSecondary}>View</button>
                  </Link>
                  <Link to="/portfolio/create">
                    <button className="ml-auto">Edit Portfolio</button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full min-h-[60vh] gap-[30px] flex flex-col justify-center items-center ">
              <h1>Create Your Portfolio</h1>
              <Link to="/portfolio/create">
                <button>Create</button>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
}
