import React from "react";
import { useNavigate } from "react-router-dom";
// Css in app.css

function NoData({ text, url, butText, top, left }) {
  const navigate = useNavigate();
  return (
    <div
      style={{ top: top ? top : 0, left: left ? left : "10%" }}
      className="absolute inset-0   flex justify-center items-center flex-col"
    >
      <div className="relative">
        <svg
          className="absolute right-0 -top-2"
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <circle
            cx="4"
            cy="4"
            r="4"
            transform="matrix(-1 0 0 1 8 0)"
            fill="#13BB82"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <circle
            cx="16"
            cy="16"
            r="15"
            transform="matrix(-1 0 0 1 32 0)"
            stroke="#161616"
            stroke-width="2"
          />
        </svg>
      </div>
      <p className="NoDataHead">{"Welcome to Memshots"}</p>
      <p className="NoDataText mx-[30%]">{text}</p>
      {butText && (
        <button onClick={() => navigate(url)} className="eventButton mt-4 ">
          {butText}
        </button>
      )}
    </div>
  );
}

export default NoData;
