import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { dataContext } from "../../../Context/DataContext";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./StudioFormPopup.css";
import { studioStorage } from "../../../store/firebase-config";
import { ref, uploadBytes, updateMetadata } from "firebase/storage";
//import uploadImage from '../../../Assets/fileUpload.svg'
import coverImageIcon from "../../../Assets/coverImageIcon.svg";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";
import successTick from "../../../Assets/successTick.svg";

import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
let cancelTokenSource = null;

function StudioFormPopup({
  update,
  setUpdate,
  visible,
  setStudioForm,
  studioObj,
  idkey,
  studioId,
}) {
  const inputRef = useRef();
  const inputRefDark = useRef();
  const [loading, setLoading] = useState(false);
  // const [progress, setProgress] = useState(0)
  const [studioLogo, setStudioLogo] = useState(null);
  const [studioLogoDark, setStudioLogoDark] = useState(null);
  const { userIdToken, currentUser } = useContext(userAuthContext);
  const [logoAdd, setLogoAdd] = useState(false);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { refresh, setRefresh } = useContext(dataContext);
  const initialValues = {
    studioName: studioObj?.studioName ? studioObj?.studioName : "",
    email: studioObj?.email ? studioObj?.email : "",
    phone_number: studioObj?.phone_number ? studioObj?.phone_number : "",
    whatsAppNumber: studioObj?.whatsAppNumber ? studioObj?.whatsAppNumber : "",
    // whatsAppNumber: studioObj?.whatsAppNumber ? studioObj?.whatsAppNumber : "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [imgExtError, setImgExtError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (formValues?.studioUniqueText) {
      const timeout = setTimeout(() => {
        checkUniqueTextAvailability();
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.studioUniqueText]);

  const createStudio = async () => {
    setLoading(true);

    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/register`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: formValues,
      };
      const response = await axios(config);

      if (response.status === 201) {
        if (studioLogo) {
          await uploadStudioLogo(studioLogo, studioLogoDark, response);
        }
        {
          location?.pathname != "/createProfile" && setUpdate(!update);
        }
        setRefresh(!refresh);
        if (location?.state?.previousLocation) {
          navigate(location?.state?.previousLocation);
        } else if (location?.pathname === "/createProfile") {
          navigate(`/choosepackage`);
          return;
        } else if (location?.pathname === "/organizations") {
          navigate(`/Event`);
        }
        setStudioForm(false);
      }
      setStudioForm(false);
    } catch (error) {
      console.log("error", error);
      // setStudioForm(false);
      setLoading(false);
      setRefresh(!refresh);
    }
  };
  //handle logo choose
  const handleChoose = async () => {
    inputRef.current.click();
  };
  const handleChooseDark = async () => {
    inputRefDark.current.click();
  };
  //handle logo change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setStudioLogo(file);
  };
  const handleFileChangeDarkImage = (e) => {
    const file = e.target.files[0];
    setStudioLogoDark(file);
  };
  //upload studio logo firebase
  // const uploadStudioLogo = async (studioLogo,  response) => {
  //   try {
  //     const file = studioLogo;
  //     if (!file) {
  //       console.error("No file selected.");
  //       return;
  //     }

  //     if (file.type !== "image/png" && file.type !== "image/jpeg") {
  //       console.error("Only PNG and JPG images are allowed.");
  //       return;
  //     }

  //     const fileExtension = file.type.split("/")[1];
  //     const fileName = `logo.${fileExtension}`;

  //     const storageRef = ref(
  //       studioStorage,
  //       `/${response.data.studioId}/${fileName}`
  //     );

  //     await uploadBytes(storageRef, file);

  //     const newMetadata = {
  //       cacheControl: "no-cache, no-store, must-revalidate",
  //       contentType: file.type,
  //     };
  //     await updateMetadata(storageRef, newMetadata);

  //     const bucketName = "studioprofile";
  //     const filePath = `${response.data.studioId}/${fileName}`;
  //     const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;

  //     await updateStudio(response.data.studioId, gcsBucketUrl);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const uploadStudioLogo = async (studioLogo, studioLogoDark, response) => {
    try {
      const logos = {};
      if (studioLogo) logos.light = studioLogo;
      if (studioLogoDark) logos.dark = studioLogoDark;

      const uploadedUrls = {};

      if (Object.keys(logos).length === 0) {
        setImgExtError("No logos provided for upload.");
        return;
      }

      for (const [type, file] of Object.entries(logos)) {
        const allowedTypes = ["image/png", "image/svg+xml"];

        if (!allowedTypes.includes(file.type)) {
          setImgExtError(
            `Only PNG,  and SVG images are allowed for the ${type} logo.`
          );
          continue;
        }

        const fileExtension = file.type.split("/")[1];
        const fileName = `logo_${type}.${fileExtension}`;
        const storageRef = ref(
          studioStorage,
          `/${response.data.studioId}/${fileName}`
        );
        await uploadBytes(storageRef, file);

        const newMetadata = {
          cacheControl: "no-cache, no-store, must-revalidate",
          contentType: file.type,
        };
        await updateMetadata(storageRef, newMetadata);

        const bucketName = "studioprofile";
        const filePath = `${response.data.studioId}/${fileName}`;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        uploadedUrls[type] = gcsBucketUrl;
      }

      await updateStudio(response.data.studioId, {
        lightLogoUrl: uploadedUrls.light || "",
        darkLogoUrl: uploadedUrls.dark || "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateStudio = async (studioId, gcsBucketUrl) => {
    try {
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/updateStudio/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          studioLogo: gcsBucketUrl?.lightLogoUrl,
          studioLogoDark: gcsBucketUrl?.darkLogoUrl,
        },
      };

      const response = await axios(config);

      setLoading(false);
      {
        location?.pathname != "/createProfile" && setUpdate(!update);
      }
      setRefresh(!refresh);
      return response;
    } catch (error) {
      console.log("error", error);
      setRefresh(!refresh);
    }
  };

  // const updateStudio = async (studioId, gcsBucketUrl) => {
  //   try {
  //     let config = {
  //       method: "patch",
  //       url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/updateStudio/${studioId}`,
  //       headers: {
  //         Authorization: `Bearer ${tokenData}`,
  //         "Content-Type": "application/json",
  //       },
  //       data: {
  //         studioLogo: gcsBucketUrl,
  //       },
  //     };
  //     const response = await axios(config);
  //     setLoading(false);
  //     setUpdate(!update);
  //     setRefresh(!refresh);
  //     return response;
  //   } catch (error) {
  //     console.log("error", error);
  //     setRefresh(!refresh);
  //   }
  // };

  const handleSave = async () => {
    // if (idkey === "update") {
    //   updateStudio();
    // } else {
    const errors = validate(formValues);
    if (isEmpty(errors) && studioLogo) {
      createStudio();
    } else {
      let errors = {};
      errors.studioLogo = "Please upload logo";
      setFormErrors(errors);
    }
    // }
  };

  const handleLogo = () => {
    // setLogoAdd(true);
    const errors = validate(formValues);
    if (isEmpty(errors)) {
      setLogoAdd(true);
    }
  };

  const validateEmail = (emailAdress) => {
    // eslint-disable-next-line no-useless-escape
    let regexEmail =
      // eslint-disable-next-line no-useless-escape
      /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (emailAdress?.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  };
  // const validatePhone = (number) => {
  //   let regexmobile =
  //     // eslint-disable-next-line no-useless-escape
  //     /^\+?(\d{1,4}|\(\d{1,4}\))[-.\s]?((\d{1,3})?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/;
  //   if (number?.match(regexmobile)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const validate = (values) => {
    let errors = {};
    if (!values?.studioName) {
      errors.studioName = "Please enter Organisation Name";
    }
    if (!values?.studioUniqueText) {
      errors.studioUniqueText = "Please enter unique domain name";
    }
    if (!values?.whatsAppNumber) {
      errors.whatsAppNumber = "Please enter whatsapp number";
    }
    if (!values?.phone_number) {
      errors.phone_number = "Please enter mobile number";
    }
    // if (!values?.address) {
    //   errors.address = "Please enter address";
    // }
    // if (!values?.stateCountry) {
    //   errors.stateCountry = "Please enter state / country";
    // }
    // if (!values?.pinCode) {
    //   errors.pinCode = "Please enter pin code";
    // }
    if (formErrors?.studioUniqueText) {
      errors.studioUniqueText = formErrors?.studioUniqueText;
    }
    // if (!validatePhone(values?.phone_number)) {
    //   errors.phone_number = "Please enter valid mobile number";
    // }
    if (!validateEmail(values?.email)) {
      errors.email = "Please enter valid Email Id";
    }
    // if (!validatePhone(values?.whatsAppNumber)) {
    //   errors.whatsAppNumber = "Please enter valid whatsapp number";
    // }
    setFormErrors(errors);
    return errors;
  };

  //check domain name available
  const checkUniqueTextAvailability = async () => {
    // Cancel the previous request (if any)
    if (cancelTokenSource !== null) {
      cancelTokenSource.cancel("Request canceled due to button click.");
    }
    // Create a new cancel token source
    cancelTokenSource = axios.CancelToken.source();
    try {
      setLoading(true);
      let config = {
        method: "POST",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/studio/checkuniquetext`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          studioUniqueText: formValues.studioUniqueText,
        },
        cancelToken: cancelTokenSource.token,
      };
      const response = await axios(config);
      if (response?.data?.exists) {
        setFormErrors((prevState) => ({
          ...prevState,
          studioUniqueText:
            "This unique domain name is already taken. Please try something else.",
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          studioUniqueText: "",
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const handleChangeInputs = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    if (id === "studioUniqueText") {
      value = value = value
        ?.replace(/\s/g, "") // Remove all whitespace
        ?.toLowerCase() // Convert to lowercase
        ?.replace(/[^a-z0-9-]/g, "") // Remove all non-alphanumeric characters except hyphens
        ?.replace(/^-+|-+$/g, ""); // Remove leading or trailing hyphens
    }
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };
  const handleChangeSocialMedia = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    setFormValues({
      ...formValues,
      socialMediaLinks: { ...formValues?.socialMediaLinks, [id]: value },
    });
  };

  return (
    // <PopupWrapper visible={visible}>
    <div className=" p-[25px] ">
      <div className="justify-end  items-end flex">
        {location.pathname === "/createProfile" ? (
          logoAdd ? (
            <button
              onClick={() => logoAdd && setLogoAdd(false)}
              className="border-[1px] border-[#787878] bg-[#f1f0f0] p-[10px] rounded-[50px]"
            >
              <img src={popupsCloseIcon} alt="" className="cursor-pointer" />
            </button>
          ) : (
            ""
          )
        ) : (
          <button
            onClick={() => (logoAdd ? setLogoAdd(false) : setStudioForm(false))}
            className="border-[1px] border-[#787878] bg-[#f1f0f0] p-[10px] rounded-[50px]"
          >
            <img src={popupsCloseIcon} alt="" className="cursor-pointer" />
          </button>
        )}
      </div>
      <div className="   ">
        <p className="font_semibold_20  text text-[#161616]">
          {idkey === "update"
            ? "Edit Your Studio Profile"
            : "Set up Your Studio Profile"}
        </p>
        <p className="para mt-[15px]">
          Let’s setup your studio profile and explore all the advanced features.
        </p>
        {/* <img
          src={popupsCloseIcon}
          alt=""
          onClick={() => setStudioForm(false)}
          className="cursor-pointer"
        /> */}
      </div>

      <div className="flex studioForm mt-[40px] justify-start items-start ">
        {!logoAdd ? (
          <>
            <div>
              <p className="studioDetails">Studio Details</p>
              <div className="flex gap-[35px]">
                <div className="flex flex-col">
                  <label>Studio Name *</label>
                  <input
                    value={formValues.studioName}
                    id="studioName"
                    className={` font_normal_14 `}
                    onChange={handleChangeInputs}
                    style={{ borderColor: formErrors?.studioName && "#f25555" }}
                    type="text"
                    placeholder="Enter studio name"
                  />
                  {formErrors?.studioName && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.studioName}
                    </p>
                  )}
                  <label>Email *</label>
                  <input
                    type="text"
                    placeholder="Enter email address"
                    className={` font_normal_14 `}
                    value={formValues.email}
                    style={{ borderColor: formErrors?.email && "#f25555" }}
                    id="email"
                    onChange={handleChangeInputs}
                  />
                  {formErrors?.email && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.email}
                    </p>
                  )}
                </div>

                <div className="flex flex-col">
                  <label>Domain Name *</label>
                  <input
                    type="text"
                    placeholder="Enter unique domain name"
                    className={` font_normal_14 `}
                    style={{
                      borderColor: formErrors?.studioUniqueText && "#f25555",
                    }}
                    value={formValues?.studioUniqueText}
                    id="studioUniqueText"
                    onChange={handleChangeInputs}
                  />
                  {formErrors?.studioUniqueText && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.studioUniqueText}
                    </p>
                  )}
                  {formValues.studioUniqueText && (
                    <p className="font_normal_12 text-[#8C8C8C]">
                      {`https://${formValues.studioUniqueText}.memshots.com`}
                      {!formErrors?.studioUniqueText && (
                        <img
                          src={successTick}
                          alt=""
                          className="inline ml-[5px] w-[15px] h-[15px]"
                        />
                      )}
                    </p>
                  )}
                  <label>Mobile Number *</label>
                  <input
                    type="number"
                    placeholder="Enter mobile number"
                    className={`font_normal_14 `}
                    style={{
                      borderColor: formErrors?.phone_number && "#f25555",
                    }}
                    value={formValues?.phone_number}
                    id="phone_number"
                    onChange={handleChangeInputs}
                  />
                  {formErrors?.phone_number && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.phone_number}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-[35px]">
                <div className="flex flex-col">
                  <label>Whatsapp Number *</label>
                  <input
                    type="number"
                    placeholder="Enter whatsapp number"
                    className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                    value={formValues.whatsAppNumber}
                    style={{
                      borderColor: formErrors?.whatsAppNumber && "#f25555",
                    }}
                    id="whatsAppNumber"
                    onChange={handleChangeInputs}
                  />
                  {formErrors?.whatsAppNumber && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.whatsAppNumber}
                    </p>
                  )}
                  <label>State/Country </label>
                  <input
                    type="text"
                    placeholder="State/Country"
                    className={`font_normal_14 `}
                    style={{
                      borderColor: formErrors?.stateCountry && "#f25555",
                    }}
                    value={formValues?.stateCountry}
                    id="stateCountry"
                    onChange={handleChangeInputs}
                  />
                  {formErrors?.stateCountry && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.stateCountry}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label>Address </label>
                  <textarea
                    rows={1}
                    type="message"
                    placeholder="Enter Address"
                    className={`w-full mt-[8px] focus:outline-none font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                    value={formValues.address}
                    style={{ borderColor: formErrors?.address && "#f25555" }}
                    id="address"
                    onChange={handleChangeInputs}
                  />
                  {formErrors?.address && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.address}
                    </p>
                  )}
                  <label>Pin Code </label>
                  <input
                    type="number"
                    placeholder="Enter Pin Code"
                    className={`font_normal_14 `}
                    style={{
                      borderColor: formErrors?.pinCode && "#f25555",
                    }}
                    value={formValues?.pinCode}
                    id="pinCode"
                    onChange={handleChangeInputs}
                  />
                  {formErrors?.pinCode && (
                    <p className="font_normal_12 text-[#f25555]">
                      {formErrors?.pinCode}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="ml-[35px]">
              {" "}
              <p className="studioDetails">Social Connection</p>
              <div className="flex flex-col">
                <label>Instagram</label>
                <input
                  type="text"
                  placeholder="Enter instagram link"
                  className={` font_normal_14 `}
                  value={formValues?.socialMediaLinks?.instagram}
                  id="instagram"
                  onChange={handleChangeSocialMedia}
                />
                <label>Facebook</label>
                <input
                  type="text"
                  placeholder="Enter facebook link"
                  className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
                  value={formValues?.socialMediaLinks?.facebook}
                  id="facebook"
                  onChange={handleChangeSocialMedia}
                />
                <label>Twitter</label>
                <input
                  type="text"
                  placeholder="Enter twitter link"
                  className={` font_normal_14 `}
                  value={formValues?.socialMediaLinks?.twitter}
                  id="twitter"
                  onChange={handleChangeSocialMedia}
                />
                <label>Website</label>
                <input
                  type="text"
                  placeholder="Enter website link"
                  className={` font_normal_14 `}
                  value={formValues?.socialMediaLinks?.website}
                  id="website"
                  onChange={handleChangeSocialMedia}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="">
            <p className="logoText">Add your studio logos</p>
            <div className="flex mt-[15px] justify-start items-start gap-[43px]">
              {studioLogo ? (
                <div className="w-[300px] h-[300px] flex-shrink-0">
                  <img
                    className="w-full h-full border-[1px] border-[#787878] object-cover"
                    src={URL.createObjectURL(studioLogo)}
                    alt="no data"
                  />
                </div>
              ) : (
                <>
                  <div
                    style={{ borderColor: formErrors?.studioLogo && "#f25555" }}
                    onClick={handleChoose}
                    className="logoBox justify-center  flex  gap-[10px]  items-center "
                  >
                    <input
                      type="file"
                      onChange={handleFileChange}
                      ref={inputRef}
                      style={{ display: "none" }}
                      className="hidden"
                      accept=".png,.svg,.PNG,.SVG"
                    />
                    <img src={coverImageIcon} alt="" />
                    <p className="font_normal_14 logoImgText">Add logo *</p>
                    {formErrors?.studioLogo && (
                      <p className="font_normal_12 text-[#f25555]">
                        {formErrors?.studioLogo}
                      </p>
                    )}
                  </div>
                </>
              )}

              {studioLogoDark ? (
                <div className="w-[300px] h-[300px] flex-shrink-0">
                  <img
                    className="w-full h-full border-[1px] border-[#787878] object-cover"
                    src={URL.createObjectURL(studioLogoDark)}
                    alt="no data"
                  />
                </div>
              ) : (
                <div
                  onClick={handleChooseDark}
                  className="logoBox justify-center bg-[#111] text-[#fff]  flex gap-[10px]  items-center "
                >
                  <input
                    type="file"
                    onChange={handleFileChangeDarkImage}
                    ref={inputRefDark}
                    style={{ display: "none" }}
                    className="hidden"
                    accept=".png,.svg,.PNG,.SVG"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M13 19C13 19.7 13.13 20.37 13.35 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 3.9 3.9 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13ZM13.96 12.29L11.21 15.83L9.25 13.47L6.5 17H13.35C13.75 15.88 14.47 14.91 15.4 14.21L13.96 12.29ZM20 18V15H18V18H15V20H18V23H20V20H23V18H20Z"
                      fill="white"
                    />
                  </svg>
                  <p className="font_normal_14 logoImgText">Add logo</p>
                </div>
              )}
              <div className=" w-[300px] h-[300px] flex-shrink-0"></div>
            </div>
          </div>
        )}
      </div>
      {logoAdd && (
        <>
          {imgExtError && (
            <p className="text-[12px] text-[red]">{imgExtError}</p>
          )}
          <p className="text-start logoUploadText mt-[24px] mr-[50%]">
            Please try to Upload your logos on*PNG or *SVG format. Also upload
            in white and black, these logos will shows on our different products
            so which may cause contrast issues{" "}
          </p>
        </>
      )}
      <div className=" justify-end  gap-[20px] items-end flex">
        {" "}
        {location.pathname !== "/createProfile" && (
          <button
            className="studioFormCancelButton "
            onClick={() => setStudioForm(false)}
            // onClick={handleSave}
            disabled={loading}
          >
            Cancel
          </button>
        )}
        <button
          className="studioFormsaveButton disabled:opacity-30 mt-[32px] "
          onClick={() => (logoAdd ? handleSave() : handleLogo())}
          // onClick={handleSave}
          disabled={loading}
        >
          {loading ? "Loading" : logoAdd ? "Create Organisation" : "Next"}
        </button>
      </div>

      {/* <div className="pt-[12px]">
        <p className="px-[24px] font_semibold_16 text-[#161616] mb-[16px]">
          Organisation details
        </p>
        <div className="mb-[16px] mx-[24px] rounded-[8px] border-[#8C8C8C4D] border-[1px] flex items-center justify-center cursor-pointer w-[510px] h-[100px]">
          {studioLogo ? (
            <div className="w-full h-full">
              <img
                className="w-full h-full object-cover"
                src={URL.createObjectURL(studioLogo)}
                alt="no data"
              />
            </div>
          ) : (
            <div
              onClick={handleChoose}
              className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33]"
            >
              <input
                type="file"
                onChange={handleFileChange}
                ref={inputRef}
                className="hidden"
                accept=".jpg,.jpeg,.png"
              />
              <img src={coverImageIcon} alt="" />
              <p className="font_normal_14 text-[#161616]">
                Add a Organisation logo
              </p>
            </div>
          )}
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
            Organisation Name <span className="text-red-500">*</span>
          </p>
          <input
            type="text"
            placeholder="Enter studio name"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            value={formValues.studioName}
            id="studioName"
            onChange={handleChangeInputs}
            style={{ borderColor: formErrors?.studioName && "#f25555" }}
          />
          {formErrors?.studioName && (
            <p className="font_normal_12 text-[#f25555]">
              {formErrors?.studioName}
            </p>
          )}
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
            Domain Name <span className="text-red-500">*</span>
          </p>
          <input
            type="text"
            placeholder="Enter unique domain name"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            style={{ borderColor: formErrors?.studioUniqueText && "#f25555" }}
            value={formValues?.studioUniqueText}
            id="studioUniqueText"
            onChange={handleChangeInputs}
          />
          {formErrors?.studioUniqueText && (
            <p className="font_normal_12 text-[#f25555]">
              {formErrors?.studioUniqueText}
            </p>
          )}
          {formValues.studioUniqueText && (
            <p className="font_normal_12 text-[#8C8C8C]">
              {`https://${formValues.studioUniqueText}.memshots.com`}
              {!formErrors?.studioUniqueText && (
                <img
                  src={successTick}
                  alt=""
                  className="inline ml-[5px] w-[15px] h-[15px]"
                />
              )}
            </p>
          )}
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
            Mobile Number <span className="text-red-500">*</span>
          </p>
          <input
            type="text"
            placeholder="Enter mobile number"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            style={{ borderColor: formErrors?.phone_number && "#f25555" }}
            value={formValues?.phone_number}
            id="phone_number"
            onChange={handleChangeInputs}
          />
          {formErrors?.phone_number && (
            <p className="font_normal_12 text-[#f25555]">
              {formErrors?.phone_number}
            </p>
          )}
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
            Email <span className="text-red-500">*</span>
          </p>
          <input
            type="text"
            placeholder="Enter email address"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            value={formValues.email}
            style={{ borderColor: formErrors?.email && "#f25555" }}
            id="email"
            onChange={handleChangeInputs}
          />
          {formErrors?.email && (
            <p className="font_normal_12 text-[#f25555]">{formErrors?.email}</p>
          )}
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
            Whatsapp Number <span className="text-red-500">*</span>
          </p>
          <input
            type="text"
            placeholder="Enter whatsapp number"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            value={formValues.whatsAppNumber}
            style={{ borderColor: formErrors?.whatsAppNumber && "#f25555" }}
            id="whatsAppNumber"
            onChange={handleChangeInputs}
          />
          {formErrors?.whatsAppNumber && (
            <p className="font_normal_12 text-[#f25555]">
              {formErrors?.whatsAppNumber}
            </p>
          )}
        </div>

        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">Instagram</p>
          <input
            type="text"
            placeholder="Enter instagram link"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            value={formValues?.socialMediaLinks?.instagram}
            id="instagram"
            onChange={handleChangeSocialMedia}
          />
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">Facebook</p>
          <input
            type="text"
            placeholder="Enter facebook link"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            value={formValues?.socialMediaLinks?.facebook}
            id="facebook"
            onChange={handleChangeSocialMedia}
          />
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">Twitter</p>
          <input
            type="text"
            placeholder="Enter twitter link"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            value={formValues?.socialMediaLinks?.twitter}
            id="twitter"
            onChange={handleChangeSocialMedia}
          />
        </div>
        <div className="px-[24px] mb-[16px]">
          <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">Website</p>
          <input
            type="text"
            placeholder="Enter website link"
            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
            value={formValues?.socialMediaLinks?.website}
            id="website"
            onChange={handleChangeSocialMedia}
          />
        </div>
        <div className="seperatorHorizontalNew"></div>
        <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[16px] pb-[24px]">
          <button
            className="cancelButton font_semibold_12"
            onClick={() => setStudioForm(false)}
          >
            Cancel
          </button>
          <button
            className="eventButton font_semibold_12"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Loading" : "Save Organisation"}
          </button>
        </div>
      </div> */}
    </div>
    // </PopupWrapper>
  );
}

export default StudioFormPopup;
