import React, { useContext, useState, useEffect } from "react";
import Lottie from "../../Assets/Animation/Loading";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import MessagePopup from "../../Components/PopUps/MessagePopup/MessagePopup";
import { userAuthContext } from "../../Context/UserAuthContext";
import ErrorPopup from "../../Components/PopUps/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import axios from "axios";
import MetaDataPopupSelectionTool from "../../Components/MetaDataPopup/MetaDataPopupSelectionTool";
import SelectionFolderCreate from "../../Components/Forms/SelectionFolderCreateForm/SelectionFolderCreate";
import AddUser from "../../Components/Forms/AddUserForm/AddUser";
import CrewUsers from "../../Components/PopUps/CrewUsers";
import "./PhotoShareTool.css";
//import albumThumb from "../../Assets/albumThumn.png";
import options from "../../Assets/NavbarIcons/options.svg";
import userIcon from "../../Assets/userIcon.svg";
import photoShareIcon from "../../Assets/photoshare.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import refresshIcon from "../../Assets/refresshIcon.svg";
import fileUploadIcon from "../../Assets/fileUploadIcon.svg";
import AlbumDetailDropdown from "../../Components/AlbumDetailDropdown/AlbumDetailDropdown";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import ImageGalleryPopup from "../../Components/ImageGalleryPopup/ImageGalleryPopup";
import Watermark from "../../Components/Watermark/Watermark";

function PhotoShareTool() {
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [compressType, setCompressType] = useState("compress");
  const [viewUserListPopup, setViewUserListPopup] = useState(false);
  const initialFormData = {
    folderName: "",
    gdriveUrl: "",
    description: "",
    coverImage: "",
    title: "",
  };
  const [sessionForm, setsessionForm] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    folderName: "",
    gdriveUrl: "",
  });
  const uploadTypes = ["DESKTOP", "GOOGLE_DRIVE"];
  const [selectedUploadType, setSelectedUploadType] = React.useState(
    uploadTypes[0]
  );
  const handleUploadTypeChange = (event) => {
    setSelectedUploadType(event.target.value);
  };
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [addWatermarkPopup, setAddWatermarkPopup] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({});
  const { projectId, shootmatesAlbumId } = useParams();
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const [albmTrainingSession, setalbmTrainingSession] = useState([]);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId } = useContext(dataContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        if (studioId) {
          await Promise.all([getAlbumDetails(), getGAlleryData()]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  const getAlbumDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_DETAILS_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
        //setAuthorisedUsers(response?.data?.data?.authorisedUsers);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getGAlleryData = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_SECTION_DATAS}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setalbmTrainingSession(response?.data?.data?.sections);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async () => {
    try {
      verifyUser();
      let isValid = true;
      const errors = {};
      if (formData.folderName.trim() === "") {
        errors.folderName = "Gallery Name is empty";
        isValid = false;
      }
      if (selectedUploadType === "GOOGLE_DRIVE") {
        if (formData.gdriveUrl.trim() === "") {
          errors.gdriveUrl = "Google Drive URL is empty";
          isValid = false;
        }
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      const isCompress = compressType === "compress" ? true : false;
      let url =
        // selectedUploadType === "GOOGLE_DRIVE"
        //   ? `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/creategdrivefolderdeskdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`
        //   : `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;
        selectedUploadType === "GOOGLE_DRIVE"
          ? `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/creategdrivefolderdeskdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`
          : `https://asia-south1-shootmates.cloudfunctions.net/test_medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;

      let config = {
        method: "post",
        url: url,
        data: {
          ...formData,
          title: formData?.folderName,
          description: "photo share tool",
          coverImage: "photo share tool",
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setFormData(initialFormData);
          setsessionForm(false);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData({
        folderName: "",
        gdriveUrl: "",
      });
      setLoading(false);
      setsessionForm(false);
      setUpdate(!update);
    }
  };

  const handleAlbumMetaDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const handleUserListingButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewUserListPopup(true);
  };

  const handleGalleryPopupEdit = (folderId, divData) => {
    const initialFormData = {
      title: divData?.title,
      description: divData?.description,
      coverImage: divData?.coverImage,
    };
    setFormData(initialFormData);
  };

  const triggerGDriveUpload = async (sectionId) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncgdriveupload/${projectId}/${studioId}/${shootmatesAlbumId}/${sectionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          sectionId: sectionId,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleCompressTypeChange = (event) => {
    setCompressType(event.target.value);
  };

  const handleRefreshImages = async (folderId) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncimagesdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleDelete = async (folderId) => {
    setIsLoading(true);
    try {
      let config = {
        method: "delete",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/deletefolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        data: {
          folderId: folderId,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setShowDeletePopup(false);
        setUpdate(!update);
      }
    } catch (err) {
      console.error("Error deleting folder:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="albumDetailContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div>
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          {metaDataPopup && (
            <MetaDataPopupSelectionTool
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={albumDetails}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          {isOpenGallery && (
            <ImageGalleryPopup
              visible={isOpenGallery}
              setIsOpenGallery={setIsOpenGallery}
              albumId={albumDetails?.memshotAlbumId}
              projectId={projectId}
              shootmatesAlbumId={shootmatesAlbumId}
              studioId={studioId}
            />
          )}
          {viewUserListPopup && (
            <CrewUsers
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
              setViewUserListPopup={setViewUserListPopup}
            />
          )}
          {addUserPopup && (
            <AddUser
              addUserPopup={addUserPopup}
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
              visible={addUserPopup}
              setpopUp={setAddUserPopup}
            />
          )}
          {addWatermarkPopup && (
            <Watermark
              setAddWatermarkPopup={setAddWatermarkPopup}
              projectId={projectId}
              studioId={studioId}
              albumId={shootmatesAlbumId}
              visible={addWatermarkPopup}
            />
          )}
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          {sessionForm && (
            <SelectionFolderCreate
              initialFormData={initialFormData}
              setFormData={setFormData}
              uploadTypes={uploadTypes}
              selectedUploadType={selectedUploadType}
              handleUploadTypeChange={handleUploadTypeChange}
              visible={sessionForm}
              handleCompressTypeChange={handleCompressTypeChange}
              compressType={compressType}
              setpopUp={setsessionForm}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              error={error}
              setError={setError}
            />
          )}
          <div className="flex justify-between items-center  px-[20px] py-[12px]">
            <div className="flex gap-[8px] items-center">
              <img src={photoShareIcon} alt="" className="w-[62px] h-[62px]" />
              <div className=" flex flex-col gap-[8px]">
                <p className="font_semibold_20 text-[#161616]">
                  {albumDetails?.albumName}
                </p>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          <div className="px-[20px] py-[16px] flex justify-between items-center">
            <div className="flex items-center gap-[16px]">
              <div
                onClick={() => {
                  setsessionForm(true);
                }}
                className="shortButton"
              >
                <img src={options} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Create folder</p>
              </div>
              <div
                onClick={() => {
                  setAddWatermarkPopup(true);
                }}
                className="shortButton"
              >
                <img src={options} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Add Watermark</p>
              </div>
              <div onClick={handleUserListingButton} className="shortButton">
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">
                  View / Manage Crew
                </p>
              </div>
            </div>
            <div className="flex items-center gap-[16px]">
              <div onClick={handleAlbumMetaDataButton} className="shortButton">
                <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Settings</p>
              </div>
              <div
                onClick={() => {
                  setUpdate(!update);
                }}
                className="shortButton"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Refresh</p>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>
          <div className="px-[20px] py-[18px] flex flex-col gap-[16px]">
            {albmTrainingSession &&
              albmTrainingSession.map((div, index) => (
                <DivWithData
                  isLoading={isLoading}
                  showDeletePopup={showDeletePopup}
                  handleDelete={handleDelete}
                  setShowDeletePopup={setShowDeletePopup}
                  handleRefreshImages={handleRefreshImages}
                  triggerGDriveUpload={triggerGDriveUpload}
                  handleGalleryPopupEdit={handleGalleryPopupEdit}
                  key={index}
                  divData={div}
                  error={error}
                  albumDetails={albumDetails}
                  setError={setError}
                  projectId={projectId}
                  albumId={shootmatesAlbumId}
                  loading={loading}
                  setLoading={setLoading}
                  studioId={studioId}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
export default PhotoShareTool;
const ConfirmationPopup = ({ handleDelete, folderId, onCancel, isLoading }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50">
    <div className="bg-white p-6 rounded-md shadow-lg">
      <p className="text-lg font-medium text-gray-900 mb-4">
        Are you sure you want to delete this folder?
      </p>
      <div className="flex justify-end gap-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
          disabled={isLoading}
        >
          No
        </button>
        <button
          onClick={() => handleDelete(folderId)}
          className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 flex items-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
              </svg>
              Deleting...
            </>
          ) : (
            "Yes, Delete"
          )}
        </button>
      </div>
    </div>
  </div>
);

const DivWithData = ({
  isLoading,
  showDeletePopup,
  handleDelete,
  setShowDeletePopup,
  handleRefreshImages,
  triggerGDriveUpload,
  divData,
  error,
  setError,
  albumDetails,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="  rounded-[8px] border-[1px] border-[#8C8C8C4D]  py-[14px]">
      {error.errorStatus === true && (
        <PopupWrapper visible={error.errorStatus}>
          <ErrorPopup error={error} setError={setError} />
        </PopupWrapper>
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          handleDelete={handleDelete}
          folderId={divData?.folderId}
          onCancel={() => setShowDeletePopup(false)}
          isLoading={isLoading}
        />
      )}
      <div className="flex items-center justify-between px-[12px] pb-[19px]">
        <div className="flex gap-[8px] items-center">
          <div className=" flex flex-col gap-[3px]">
            <p className="font_medium_16 text-[#161616]">{divData?.title}</p>
          </div>
        </div>
        <div className="flex items-center gap-[12px]">
          <div
            onClick={() => {
              navigate(`${location.pathname}/upload/${divData?.folderId}`);
            }}
            className="px-[8px] py-[5px] rounded-[4px] bg-[#4D58E3] cursor-pointer"
          >
            <p className="font_normal_11 text-[#FFFFFF]">
              View / Upload Images
            </p>
          </div>
          {albumDetails?.userConfirmation !== true && (
            <div
              onClick={() => {
                handleRefreshImages(divData?.folderId);
              }}
              className="px-[8px] py-[5px] rounded-[4px] bg-[#04AA6D] cursor-pointer"
            >
              <p className="font_normal_11 text-[#FFFFFF]">Refresh Images</p>
            </div>
          )}
          {divData?.uploadType === "GDRIVE" ||
            (divData?.folderType === "GDRIVE" && (
              <div
                onClick={() => {
                  triggerGDriveUpload(divData?.folderId);
                }}
                className="px-[8px] py-[5px] rounded-[4px] bg-[#006A67] cursor-pointer"
              >
                <p className="font_normal_11 text-[#FFFFFF]"> Import images</p>
              </div>
            ))}
          <div
            onClick={() => setShowDeletePopup(true)}
            className="px-[8px] py-[5px] rounded-[4px] bg-red-500 cursor-pointer"
          >
            <p className="font_normal_11 text-[#FFFFFF]">Delete Folder</p>
          </div>
        </div>
      </div>
      <div className="seperatorHorizontalNew"></div>
      <div className="px-[12px] pt-[13px] flex justify-between items-end">
        <div className="flex flex-col gap-[12px]">
          <div className="flex items-center">
            <img src={fileUploadIcon} alt="" />
            <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
              Files uploaded
            </p>
            <p className="font_normal_12 text-[#4D58E3]">
              {divData?.processedImages}
            </p>
          </div>
          <div className="flex items-center">
            <img src={fileUploadIcon} alt="" />
            <p className="font_normal_12 text-[#000000] ml-[4px] mr-[10px]">
              Selected Photos
            </p>
            <p className="font_normal_12 text-[#4D58E3]">
              {divData?.selectedCount ? divData?.selectedCount : 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
