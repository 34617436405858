import React, { useState } from "react";
import ImageModel from "../ImageModel/ImageModel";

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <p className="text-lg mb-4">
          Are you sure you want to delete this image?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-200"
            onClick={onCancel}
          >
            No
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300"
            onClick={onConfirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageListPhotoShare = ({
  handleDeleteImage,
  folderId,
  uploadedMedias,
  memshotAlbumId,
  folderName,
  deletingMedia,
  selectedMedia,
  setSelectedMedias,
  type,
  getBucketName,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loadingImages, setLoadingImages] = useState(new Set());
  const [viewingMedia, setViewingMedia] = useState(null);
  const [mediaToDelete, setMediaToDelete] = useState(null); // Track the media to delete

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleImageLoad = (media) => {
    setLoadingImages((prev) => {
      const newSet = new Set(prev);
      newSet.delete(media);
      return newSet;
    });
  };

  const handleImageError = (media) => {
    setLoadingImages((prev) => {
      const newSet = new Set(prev);
      newSet.add(media);
      return newSet;
    });
  };

  const toggleSelection = (media) => {
    setSelectedMedias((prev) => {
      if (prev.includes(media)) {
        return prev.filter((item) => item !== media);
      } else {
        return [...prev, media];
      }
    });
  };

  const confirmDelete = () => {
    if (mediaToDelete) {
      handleDeleteImage(mediaToDelete, folderId);
      setMediaToDelete(null); // Reset after deletion
    }
  };

  if (!uploadedMedias || uploadedMedias.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-2xl text-red-500">No images found</p>
      </div>
    );
  }
  const bucketName = getBucketName(type);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 m-4">
      {uploadedMedias &&
        uploadedMedias.map(
          (media, index) =>
            media !== "folderCheck.keep" && (
              <div
                key={index}
                className={`relative group rounded-lg overflow-hidden shadow-lg transition-transform transform-gpu duration-300 ease-in-out ${
                  selectedMedia.includes(
                    `https://storage.googleapis.com/albumdata/${memshotAlbumId}/${folderName}/${media}`
                  )
                    ? "border-4 border-blue-500"
                    : ""
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="block w-full h-40 overflow-hidden">
                  {loadingImages.has(media) ? (
                    <div className="flex items-center justify-center w-full h-full bg-gray-200">
                      <div className="loader">Loading...</div>
                    </div>
                  ) : (
                    <img
                      className="object-cover w-full h-full"
                      src={`https://storage.googleapis.com/albumthumbnails/${memshotAlbumId}/${folderName}/d_${media}`}
                      alt={media}
                      onLoad={() => handleImageLoad(media)}
                      onError={() => handleImageError(media)}
                    />
                  )}
                </div>
                {hoveredIndex === index && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                    <div className="text-center text-white space-x-4">
                      <button
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
                        onClick={() =>
                          setViewingMedia(
                            `https://storage.googleapis.com/albumthumbnails/${memshotAlbumId}/${folderName}/d_${media}`
                          )
                        }
                      >
                        View
                      </button>
                      <button
                        className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300"
                        onClick={() => setMediaToDelete(media)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
                {deletingMedia === media && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-300 ease-in-out">
                    <div className="text-center text-white">Deleting...</div>
                  </div>
                )}
                <div className="absolute bottom-0 w-full p-2 bg-black bg-opacity-50 text-center text-white">
                  <label className="flex items-center justify-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                      checked={selectedMedia.includes(
                        `https://storage.googleapis.com/${bucketName}/${memshotAlbumId}/${folderName}/${media}`
                      )}
                      onChange={() =>
                        toggleSelection(
                          `https://storage.googleapis.com/${bucketName}/${memshotAlbumId}/${folderName}/${media}`
                        )
                      }
                    />
                    <span></span>
                  </label>
                </div>
              </div>
            )
        )}
      {viewingMedia && (
        <ImageModel
          media={viewingMedia}
          onClose={() => setViewingMedia(null)}
        />
      )}
      {mediaToDelete && (
        <ConfirmationModal
          onConfirm={confirmDelete}
          onCancel={() => setMediaToDelete(null)}
        />
      )}
    </div>
  );
};

export default ImageListPhotoShare;
