import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDPLUEOeNQn9cJUaBk6KV4h1krKm0wnsI8",
  authDomain: "shootmates.firebaseapp.com",
  projectId: "shootmates",
  storageBucket: "shootmates.appspot.com",
  messagingSenderId: "167880547054",
  appId: "1:167880547054:web:9c0b12fdd30b31776ab1ff",
  measurementId: "G-5MPW333593",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//Storage
export const storage = getStorage(app);

export const mediaStorage = getStorage(app, "albumdata");
export const privateStorage = getStorage(app, "shootmatesprivatecloud");
export const aiUnPaid = getStorage(app, "aimemshots");
export const studioStorage = getStorage(app, "studioprofile");
export const memshotAlbumMetadata = getStorage(app, "memshot_album_metadata");
export const shootmatesalbumdata = getStorage(app, "shootmatesalbumdata");
export const photosharetool = getStorage(app, "photosharetoolqr");
export const portfolioStorage = getStorage(app, "studio_portfolio");
export const authentication = getAuth(app);

export const bucketStorageMap = {
  albumdata: mediaStorage,
  aimemshots:aiUnPaid,
  shootmatesprivatecloud: privateStorage,
  studioprofile: studioStorage,
  memshot_album_metadata: memshotAlbumMetadata,
  shootmatesalbumdata: shootmatesalbumdata,
  photosharetoolqr: photosharetool,
  studio_portfolio: portfolioStorage,
};