import React, { useContext } from "react";
import { dataContext } from "../../Context/DataContext";
// css in navbar.css

function PackageStatusBut({}) {
  const { activePackages } = useContext(dataContext);
  return (
    <button
      className={`${
        activePackages?.activePackage
          ? "packageTypeBut"
          : "packageTypeButNotActive "
      } `}
    >
      {" "}
      {activePackages?.activePackage
        ? activePackages?.packageStatus === "ACTIVE" &&
          activePackages?.packageDetails?.packageType?.charAt(0).toUpperCase() +
            activePackages?.packageDetails?.packageType?.slice(1).toLowerCase()
        : "Flexi"}{" "}
      Package{" "}
    </button>
  );
}

export default PackageStatusBut;
