import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";

function SelectedPackagePopup({ msg, visible, onClose }) {
  return (
    <PopupWrapper visible={visible}>
      <div className="bg-[#fff] popUpShadow  h-[200px] w-[400px] rounded-[10px] flex flex-col trialPop mt-[5%]   ">
        <p className="text-[18px] text-center mx-[8%] mt-2">
          {msg} . If you want to make any changes please contact   admin
        </p>
        <div className="justify-center items-center flex ">
          <button
            onClick={() => onClose(false)}
            className="bg-[#373CBD] w-[100px]  px-[10px] py-[5px] text-[#fff] rounded-[8px]"
          >
            {" "}
            Ok
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default SelectedPackagePopup;
