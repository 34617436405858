import React from "react";
import { useNavigate } from "react-router";

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="py-[32px] px-[56px] w-full bg-black text-white text-[12px] flex flex-col lg:flex-row lg:justify-between items-center gap-[16px] lg:gap-0">
      <div className="flex flex-col gap-[6px] lg:gap-[12px] items-center lg:items-start text-center lg:text-left">
        <p className="font-semibold text-[14px]">Policy</p>
        <div className="flex flex-col lg:gap-[6px]">
          <p
            onClick={() => {
              navigate("/privacypolicy");
            }}
            className="cursor-pointer"
          >
            Privacy Policy
          </p>
          <p
            onClick={() => {
              navigate("/refund");
            }}
            className="cursor-pointer"
          >
            Cancellation and Refund Policy
          </p>
          <p
            onClick={() => {
              navigate("/shipping");
            }}
            className="cursor-pointer"
          >
            Shipping and Delivery Policy
          </p>
          <p
            onClick={() => {
              navigate("/tandc");
            }}
            className="cursor-pointer"
          >
            Terms and Conditions
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-[6px] lg:gap-[12px] items-center lg:items-start text-center lg:text-left">
        <p className="font-semibold text-[14px]">Contact Us</p>
        <div>
          <p>
            Merchant Legal entity name: MEMSHOTS PRIVATE LIMITED. <br />
            Registered Address: Krishna Prabha House, Mannoor, Keezhillam P.O,
            Kunnathunad Ernakulam KERALA 683541. <br />
            Operational Address: Krishna Prabha House, Mannoor, Keezhillam P.O,
            Kunnathunad Ernakulam KERALA 683541. <br />
            Telephone No: +91 9986619796
            <br />
            E-Mail ID: hello@shootmates.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
