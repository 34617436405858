import { useState } from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import styles from "./PopUp.module.css";

const CommentPopup = ({
  visible,
  setpopUp,
  handleAddComment,
  commentsObj,
  deleteComment,
}) => {
  const [comment, setComment] = useState("");
  return (
    <PopupWrapper visible={visible} setpopUp={setpopUp}>
      <div className="w-full h-full flex items-center justify-center">
        <div className={styles.popUpContainer}>
          <div className={styles.headerContainer}>
            <div>
              <p className={styles.popUpHeader}>Add Comments</p>
              {/* <p className={styles.headerPara}>{value.Para}</p> */}
            </div>
          </div>

          <div className={styles.body}>
            {commentsObj?.comments?.length > 0 && (
              <div>
                <h4 className="font_medium_16">Comments</h4>
                <ul>
                  {commentsObj?.comments.map((obj) => {
                    return (
                      <li className="flex gap-[15px]">
                        <p>{obj?.comment}</p>
                        <p
                          className="text-[#1024e9] underline"
                          onClick={() =>
                            deleteComment(commentsObj?._id, obj?._id)
                          }
                        >
                          remove
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className={styles.form}>
              <div className="flex items-center relative">
                <p className={`mt-[10px] font_medium_16`}>
                  Comment<span className="text-red-600">*</span>
                </p>
              </div>
              <textarea
                type="text"
                className={`  scrollbar-hide `}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={() => setpopUp(false)}>
              Cancel
            </button>
            <button
              className={styles.button}
              onClick={() => handleAddComment(comment, commentsObj?._id)}
            >
              Add Comment
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default CommentPopup;
