import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { dataContext } from '../../Context/DataContext';
import { userAuthContext } from '../../Context/UserAuthContext';
import { PopupWrapper } from '../../UI/PopupWrapper/PopupWrapper';
import './Delete.css'
import deleteIcon from '../../Assets/deleteIcon.svg'

function Delete({ update, setUpdate, visible, setRemove, Objid, url, setDropdown }) {
    const [loading, setLoading] = useState(false)
    const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext)
    const tokenData = userIdToken || currentUser?.accessToken;
    const { studioId } = useContext(dataContext)
    useEffect(() => {
        verifyUser()
     // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [])

    const remove = async () => {
        setLoading(true)
        try {

            let config = {
                method: 'delete',
                url: `${url}/${Objid._id}/${studioId}`,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    'Content-Type': 'application/json',
                },
            }
            const response = await axios(config)
            if (response.status === 200) {
                setRemove(false)
                setUpdate(!update)
            }
        } catch (error) {
            console.log("error", error);
            setLoading(false)
        }
    }


    return (
        <PopupWrapper visible={visible} setRemove={setRemove}>
            <div className='deleteClient'>
                <div className='deleteClient-top'>
                    <img src={deleteIcon} alt="" />
                    <div className='deleteClient-top-text'>
                        <p className='deleteClient-top-text1'>Delete </p>
                        <p className='deleteClient-top-text2'>Are you sure  you want to delete? This action cannot be undone</p>
                    </div>
                </div>
                <div className='deleteClient-bottom'>
                    <div className='deleteClient-button'>
                        <button className='deleteClient-button1' onClick={() => {
                            setRemove(false)
                            setDropdown(false)
                        }}>Cancel</button>
                        {loading ? <button className='deleteClient-button2'>Loading</button> : <button className='deleteClient-button2' onClick={remove}>Delete</button>}
                    </div>
                </div>
            </div>
        </PopupWrapper>
    )
}

export default Delete
