import React, { useContext, useState, useRef, useEffect } from "react";
import Lottie from "../../../Assets/Animation/Loading";
import { shootmatesalbumdata } from "../../../store/firebase-config";
import GalleryEditForm from "../../../Components/Forms/GalleryEditForm/GalleryEditForm";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { dataContext } from "../../../Context/DataContext";
import qrCodeIcon from "../../../Assets/qrCodeIcon.svg";
import { userAuthContext } from "../../../Context/UserAuthContext";
import Lottie1 from "../../../Assets/Animation/LoadingWhite";
import ErrorPopup from "../../../Components/PopUps/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import AlbumEditFormNew from "../../../Components/AlbumSection/AlbumEditFormNew";
import axios from "axios";
import { ref, uploadBytes } from "firebase/storage";
import MetaDataPopupAlbum from "../../../Components/MetaDataPopup/MetaDataPopupAlbum";
import GalleryCreateForm from "../../../Components/Forms/GalleryCreateForm/GalleryCreateForm";
import AddUser from "../../../Components/Forms/AddUserForm/AddUser";
import AddPin from "../../../Components/Forms/AddPin/AddPin";
import "./AlbumGalleryView.css";
//import albumThumb from "../../../Assets/albumThumn.png";
import options from "../../../Assets/NavbarIcons/options.svg";
import userIcon from "../../../Assets/userIcon.svg";
import editIcon from "../../../Assets/editIcon.svg";
import eventSignatureAlbum from "../../../Assets/eventSignatureAlbum.svg";
import detailsIcon from "../../../Assets/detailsIcon.svg";
import refresshIcon from "../../../Assets/refresshIcon.svg";
//import gDriveIcon from "../../../Assets/gDriveIcon.svg";
import fileUploadIcon from "../../../Assets/fileUploadIcon.svg";
import CrewUsers from "../../../Components/PopUps/CrewUsers";
//import faceDetectionStatusIcon from "../../../Assets/faceDetectionStatusIcon.svg";
//import filesIcon from "../../../Assets/filesIcon.svg";
//import AlbumDetailDropdown from "../../../Components/AlbumDetailDropdown/AlbumDetailDropdown";
import setPinIcon from "../../../Assets/setPinIcon.svg";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import MessagePopupAlbum from "../../../Components/PopUps/MessagePopup/MessagePopupAlbum";

function AlbumGalleryViewNew() {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const [copiedLive, setCopiedLive] = useState(false);
  const [viewUserListPopup, setViewUserListPopup] = useState(false);
  const inputRefAlbumCoverImage = useRef(null);
  const initialFormData = {
    folderName: "",
    gdriveUrl: "",
    description: "",
    coverImage: "",
    title: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [compressType, setCompressType] = useState("compress");
  const [messagePopup, setMessagePopup] = useState({
    republish: false,
    visible: false,
    type: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    folderName: "",
    gdriveUrl: "",
  });
  const uploadTypes = ["DESKTOP", "GOOGLE_DRIVE"];
  const [selectedUploadType, setSelectedUploadType] = React.useState(
    uploadTypes[0]
  );
  const handleUploadTypeChange = (event) => {
    setSelectedUploadType(event.target.value);
  };
  // const [copied, setCopied] = useState(false);
  const [editAlbumTemplate, setEditAlbumTemplate] = useState({
    mode: false,
    albumId: null,
  });
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [addPinPopup, setaddPinPopup] = useState(false);
  const [galleryPopup, setGalleryPopup] = useState(false);
  const [galleryPopupEdit, setGalleryPopupEdit] = useState({
    visible: false,
    galleryId: "",
  });
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({});
  const [albumCoverImageLoading, setAlbumCoverImageLoading] = useState(false);
  const [publishCrmAlbumLoading, setPublishCrmAlbumLoading] = useState(false);
  const { projectId, shootmatesAlbumId } = useParams();
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const [albmTrainingSession, setalbmTrainingSession] = useState([]);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId, roleSelected, getStudioDetails, studioDetails } =
    useContext(dataContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        if (studioId) {
          await Promise.all([getAlbumDetails(), getGAlleryData()]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  const getAlbumDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_DETAILS_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
        //setAuthorisedUsers(response?.data?.data?.authorisedUsers);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getGAlleryData = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_SECTION_DATAS}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        // setAlbumDetails(response?.data?.data?.shootmatesAlbumData)
        setalbmTrainingSession(response?.data?.data?.sections);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAlbumGalleryImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      try {
        setAlbumCoverImageLoading(true);
        const uniqueIdentifier = Date.now();
        const fileName = `${uniqueIdentifier}_${selectedFile.name}`;
        const storageRef = ref(
          shootmatesalbumdata,
          `${studioId}/${projectId}/galleryCoverImages/${fileName}`
        );
        await uploadBytes(storageRef, selectedFile);
        const bucketName = "shootmatesalbumdata";
        const filePath = `${studioId}/${projectId}/galleryCoverImages/${fileName}`;
        const gcsBucketUrl = `https://storage.googleapis.com/${bucketName}/${filePath}`;
        setFormData((prevDetails) => ({
          ...prevDetails,
          coverImage: gcsBucketUrl,
        }));
        setAlbumCoverImageLoading(false);
      } catch (error) {
        console.error("Error uploading studio logo:", error);
        setAlbumCoverImageLoading(false);
      }
    }
  };

  const publishToFaceDetectionTraining = async (folderId, folderName) => {
    setLoading(true);
    try {
      verifyUser();
      let config = {
        method: "post",
        url: `${
          process.env.REACT_APP_PUBLISH_TO_TRAINING_ALBUM
        }/${projectId}/${studioId}/${shootmatesAlbumId}?privatePhotos=${
          albumDetails?.paidPhotos === true ? true : false
        }`,
        data: {
          folderId: folderId,
          folderName: folderName,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const publishCrmAlbum = async (status) => {
    if (status === "PUBLISHING") {
      setMessagePopup({
        republish: true,
        visible: true,
        type: "success",
        message:
          "Please try after sometime album is currently in stage of publishing",
      });
      //setUpdate(!update)
      return;
    }
    setPublishCrmAlbumLoading(true);
    try {
      verifyUser();
      let config = {
        method: "post",
        // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/albumoperations/publish/album/${projectId}/${studioId}/${shootmatesAlbumId}`,
        url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/albumoperations/publish/album/${projectId}/${studioId}/${shootmatesAlbumId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          republish: false,
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
        setUpdate(!update);
      } else {
        setPublishCrmAlbumLoading(false);
        setMessagePopup({
          republish: true,
          visible: true,
          type: "error",
          message: "Please try again..",
        });
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setPublishCrmAlbumLoading(false);
    }
  };

  const removeAlbumCoverImage = () => {
    setFormData((prevDetails) => ({
      ...prevDetails,
      coverImage: "",
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async () => {
    try {
      let isValid = true;
      const errors = {};
      if (formData.folderName.trim() === "") {
        errors.folderName = "Gallery Name is empty";
        isValid = false;
      }
      if (selectedUploadType === "GOOGLE_DRIVE") {
        if (formData.gdriveUrl.trim() === "") {
          errors.gdriveUrl = "Google Drive URL is empty";
          isValid = false;
        }
      }
      if (formData.coverImage.trim() === "") {
        errors.coverImage = "Gallery Cover image is empty";
        isValid = false;
      }
      if (formData.description.trim() === "") {
        errors.description = "Gallery Description is empty";
        isValid = false;
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      let url =
        selectedUploadType === "GOOGLE_DRIVE"
          ? `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/creategdrivefolderdeskdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`
          : `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;
      let config = {
        method: "post",
        url: url,
        data: { ...formData, title: formData?.folderName },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });

          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setFormData(initialFormData);
          setGalleryPopup(false);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData({
        folderName: "",
        gdriveUrl: "",
      });
      setLoading(false);
      setGalleryPopup(false);
      setUpdate(!update);
    }
  };

  const handleSubmitGalleryEdit = async (galleryId) => {
    try {
      let isValid = true;
      const errors = {};
      if (formData.coverImage.trim() === "") {
        errors.coverImage = "Gallery Cover image is empty";
        isValid = false;
      }
      if (formData.description.trim() === "") {
        errors.description = "Gallery Description is empty";
        isValid = false;
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/albumsession/editAlbumGallery/${projectId}/${studioId}/${shootmatesAlbumId}/${galleryId}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setGalleryPopupEdit({ visible: false, galleryId: "" });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData(initialFormData);
      setLoading(false);
      setGalleryPopupEdit({ visible: false, galleryId: "" });
      setUpdate(!update);
    }
  };

  const handleAlbumMetaDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const handleGalleryPopupEdit = (folderId, divData) => {
    const initialFormData = {
      title: divData?.title,
      description: divData?.description,
      coverImage: divData?.coverImage,
    };
    setFormData(initialFormData);
    setGalleryPopupEdit({ visible: true, folderId: folderId });
  };

  const triggerGDriveUpload = async (sectionId) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncgdriveupload/${projectId}/${studioId}/${shootmatesAlbumId}/${sectionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    const linkText = `${albumDetails?.slugId}`;
    const linkToCopy = `https://${
      roleSelected?.studioUniqueText || "album"
    }.memshots.com/${linkText}`;

    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopiedLive(true);
    setTimeout(() => {
      setCopiedLive(false);
    }, 1000);
  };

  const handleCompressTypeChange = (event) => {
    setCompressType(event.target.value);
  };

  const handleUserListingButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewUserListPopup(true);
  };

  const handleRefreshImages = async (folderId) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncimagesdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleDelete = async (folderId) => {
    setIsLoading(true);
    try {
      let config = {
        method: "delete",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/deletefolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setShowDeletePopup(false);
        setUpdate(!update);
      }
    } catch (err) {
      console.error("Error deleting folder:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="albumDetailContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {albumDetails?.memshotAlbumStatus === "SEND_TO_MEMSHOTS" && (
        <p className="font_normal_16 text-[#2B547E] ml-[30px]">
          Status -Publishing
        </p>
      )}

      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div>
          {publishCrmAlbumLoading && (
            <Lottie1 loadingText={"Publishing Album..."} />
          )}
          {messagePopup?.visible && (
            <MessagePopupAlbum
              publishCrmAlbum={publishCrmAlbum}
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          {metaDataPopup && (
            <MetaDataPopupAlbum
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={albumDetails}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          
          {addUserPopup && (
            <AddUser
              addUserPopup={addUserPopup}
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
              visible={addUserPopup}
              setpopUp={setAddUserPopup}
            />
          )}
          {viewUserListPopup && (
            <CrewUsers
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
              setViewUserListPopup={setViewUserListPopup}
            />
          )}
          {addPinPopup && (
            <AddPin
              addPinPopup={addPinPopup}
              setaddPinPopup={setaddPinPopup}
              projectId={projectId}
              albumId={albumDetails?.memshotAlbumId}
              visible={addPinPopup}
            />
          )}
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          {editAlbumTemplate?.mode && (
            <AlbumEditFormNew
            
              update={update}
              setUpdate={setUpdate}
              visible={editAlbumTemplate?.mode}
              setAlbum={setEditAlbumTemplate}
              projectId={projectId}
              albumId={editAlbumTemplate?.albumId}
            />
          )}
          {galleryPopup && (
            <GalleryCreateForm
              handleCompressTypeChange={handleCompressTypeChange}
              compressType={compressType}
              initialFormData={initialFormData}
              setFormData={setFormData}
              uploadTypes={uploadTypes}
              selectedUploadType={selectedUploadType}
              handleUploadTypeChange={handleUploadTypeChange}
              visible={galleryPopup}
              setpopUp={setGalleryPopup}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleAlbumGalleryImageChange={handleAlbumGalleryImageChange}
              inputRefAlbumCoverImage={inputRefAlbumCoverImage}
              albumCoverImageLoading={albumCoverImageLoading}
              handleSubmit={handleSubmit}
              removeAlbumCoverImage={removeAlbumCoverImage}
              error={error}
              setError={setError}
            />
          )}
          {galleryPopupEdit?.visible && (
            <GalleryEditForm
              initialFormData={initialFormData}
              setFormData={setFormData}
              galleryPopupEdit={galleryPopupEdit}
              visible={galleryPopupEdit?.visible}
              setpopUp={setGalleryPopupEdit}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleAlbumGalleryImageChange={handleAlbumGalleryImageChange}
              inputRefAlbumCoverImage={inputRefAlbumCoverImage}
              albumCoverImageLoading={albumCoverImageLoading}
              handleSubmit={handleSubmitGalleryEdit}
              removeAlbumCoverImage={removeAlbumCoverImage}
              error={error}
              setError={setError}
            />
          )}
          <div className="flex justify-between items-center  px-[20px] py-[12px]">
            <div className="flex gap-[8px] items-center">
              <img
                src={eventSignatureAlbum}
                alt=""
                className="w-[62px] h-[62px]"
              />
              <div className=" flex flex-col gap-[8px]">
                <p className="font_semibold_20 text-[#161616]">
                  {albumDetails?.albumName}
                </p>
                <p className="font_normal_14  text-[#8C8C8C] ">
                  {albumDetails?.albumName}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              {/* <div className=" cursor-pointer mr-[14px] py-[5px] pl-[5px] pr-[10px] border-[1px] border-[#8C8C8C4D] flex items-center gap-[10px] rounded-[4px]">
                <img src={albumDetailPreviewIcon} alt="" />
                <p className="font_normal_11 text-[#161616]">Preview</p>
              </div> */}
              {/* <div className="mr-[24px] cursor-pointer">
                <img src={albumDetailAssignedUsers} alt="" />
              </div> */}
              {albumDetails?.memshotAlbumStatus === "SEND_TO_MEMSHOTS" ? (
                <button
                  onClick={() => {
                    publishCrmAlbum("PUBLISHED");
                  }}
                  className="publishButton font_semibold_12"
                >
                  Publishing Album..
                </button>
              ) : (
                <button
                  onClick={() => {
                    publishCrmAlbum("PUBLISHED");
                  }}
                  className="publishButton font_semibold_12 "
                >
                  Publish Album
                </button>
              )}
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          <div className="px-[20px] py-[16px] flex justify-between items-center">
            <div onClick={copyToClipboard} className="shortButtonBlack">
              <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
              <p className="font_normal_11 text-[#F6F6F6]">
                {copiedLive ? "Album Link Copied!" : "Copy Album Link"}
              </p>
            </div>
            <div className="flex items-center gap-[16px]">
              <div
                onClick={() => {
                  setGalleryPopup(true);
                }}
                className="shortButton"
              >
                <img src={options} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">
                  Create album gallery
                </p>
              </div>
              {/* <div
                onClick={() => {
                  setAddUserPopup(true);
                }}
                className="shortButton"
              >
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Add user</p>
              </div> */}
              <div onClick={handleUserListingButton} className="shortButton">
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">
                  View / Manage Crew
                </p>
              </div>
              <div
                onClick={() => {
                  setEditAlbumTemplate({
                    mode: true,
                    albumId: shootmatesAlbumId,
                  });
                }}
                className="shortButton"
              >
                <img src={editIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">
                  Edit album template
                </p>
              </div>
              <div
                onClick={() => {
                  setaddPinPopup(true);
                }}
                className="shortButton"
              >
                <img src={setPinIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Set pin</p>
              </div>
            </div>
            <div className="flex items-center gap-[16px]">
              <div onClick={handleAlbumMetaDataButton} className="shortButton">
                <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Settings</p>
              </div>

              <div
                onClick={() => {
                  setUpdate(!update);
                }}
                className="shortButton"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Refresh</p>
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>
          {/* <StatusAndReports type={"Album"} /> */}
          {/* <UserListing
                        authorisedUsers={authorisedUsers}
                        isComponentVisible={isComponentVisible}
                        toggleComponentVisibility={toggleComponentVisibility} /> */}
          <div className="px-[20px] py-[18px] flex flex-col gap-[16px]">
            {albmTrainingSession &&
              albmTrainingSession.map((div, index) => (
                <DivWithData
                  showDeletePopup={showDeletePopup}
                  handleDelete={handleDelete}
                  setShowDeletePopup={setShowDeletePopup}
                  isLoading={isLoading}
                  handleRefreshImages={handleRefreshImages}
                  triggerGDriveUpload={triggerGDriveUpload}
                  handleGalleryPopupEdit={handleGalleryPopupEdit}
                  publishToFaceDetectionTraining={
                    publishToFaceDetectionTraining
                  }
                  key={index}
                  divData={div}
                  error={error}
                  setError={setError}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AlbumGalleryViewNew;
const ConfirmationPopup = ({ handleDelete, folderId, onCancel, isLoading }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50">
    <div className="bg-white p-6 rounded-md shadow-lg">
      <p className="text-lg font-medium text-gray-900 mb-4">
        Are you sure you want to delete this folder?
      </p>
      <div className="flex justify-end gap-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
          disabled={isLoading}
        >
          No
        </button>
        <button
          onClick={() => handleDelete(folderId)}
          className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 flex items-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
              </svg>
              Deleting...
            </>
          ) : (
            "Yes, Delete"
          )}
        </button>
      </div>
    </div>
  </div>
);
const DivWithData = ({
  showDeletePopup,
  handleDelete,
  setShowDeletePopup,
  isLoading,
  handleRefreshImages,
  triggerGDriveUpload,
  handleGalleryPopupEdit,
  divData,
  error,
  setError,
  albumDetails,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex items-center rounded-lg border border-gray-300 p-4 shadow-sm hover:shadow-md transition-shadow duration-200 hover:bg-blue-50">
      {error.errorStatus === true && (
        <PopupWrapper visible={error.errorStatus}>
          <ErrorPopup error={error} setError={setError} />
        </PopupWrapper>
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          handleDelete={handleDelete}
          folderId={divData?.folderId}
          onCancel={() => setShowDeletePopup(false)}
          isLoading={isLoading}
        />
      )}
      {/* Folder Icon */}
      <div className="flex-shrink-0 w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
        <svg
          className="w-8 h-8 text-blue-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M4 4a2 2 0 012-2h4l2 2h6a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
        </svg>
      </div>

      {/* Folder Details */}
      <div className="ml-4 flex-grow">
        <div className="flex items-center justify-between mb-2">
          <p className="text-lg font-medium text-gray-900">{divData?.title}</p>
        </div>
        <div className="flex items-center justify-between mb-2">
          <p className="font_normal_16 text-[#8C8C8C]">
            {divData?.description}
          </p>
        </div>

        {/* Folder Stats */}
        <div className="flex gap-6 text-sm text-gray-700">
          <div className="flex items-center">
            <img
              src={fileUploadIcon}
              alt="Files uploaded"
              className="w-5 h-5"
            />
            <span className="ml-2">Files uploaded:</span>
            <span className="ml-2 text-blue-600">
              {divData?.processedImages}
            </span>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex items-center gap-3">
        <div
          onClick={() => {
            divData?.folderType === "GDRIVE"
              ? triggerGDriveUpload(divData?.folderId)
              : navigate(`${location.pathname}/upload/${divData?.folderId}`);
          }}
          className="flex items-center px-3 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white cursor-pointer transition-colors duration-200"
        >
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path d="M8 7v6h4V7h5l-7-7-7 7h5zM3 13h14v7H3v-7z" />
          </svg>
          <p className="text-sm">
            {divData?.folderType === "GDRIVE"
              ? "Import Drive Images"
              : "Upload images"}
          </p>
        </div>
        <div
          onClick={() => {
            navigate(`${location.pathname}/upload/${divData?.folderId}`);
          }}
          className="flex items-center px-3 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white cursor-pointer transition-colors duration-200"
        >
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path d="M8 7v6h4V7h5l-7-7-7 7h5zM3 13h14v7H3v-7z" />
          </svg>
          <p className="text-sm">View Images</p>
        </div>
        {albumDetails?.userConfirmation !== true && (
          <div
            onClick={() => {
              handleRefreshImages(divData?.folderId);
            }}
            className="flex items-center px-3 py-2 rounded-md bg-green-600 hover:bg-green-700 text-white cursor-pointer transition-colors duration-200"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 14V8h2v6H9zM9 6V4h2v2H9z" />
            </svg>
            <p className="text-sm">Refresh Images</p>
          </div>
        )}
        <div
          onClick={() => handleGalleryPopupEdit(divData?.folderId, divData)}
          className="flex items-center px-3 py-2 rounded-md bg-yellow-600 hover:bg-yellow-700 text-white cursor-pointer transition-colors duration-200"
        >
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path d="M4 13.5V16h2.5L15.374 7.126 12.874 4.626 4 13.5zM14.121 3.879l2 2L13.621 8.379l-2-2L14.121 3.879z" />
          </svg>
          <p className="text-sm">Edit Gallery</p>
        </div>
        <div
          onClick={() => setShowDeletePopup(true)}
          className="flex items-center px-3 py-2 rounded-md bg-red-600 hover:bg-red-700 text-white cursor-pointer transition-colors duration-200"
        >
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path d="M6 2a1 1 0 00-1 1v1H3v2h1v12a2 2 0 002 2h8a2 2 0 002-2V6h1V4h-2V3a1 1 0 00-1-1H6zm1 4h8v12H7V6zm2-2h4v1H9V4z" />
          </svg>
          <p className="text-sm">Delete Folder</p>
        </div>
      </div>
    </div>
  );
};

