import React from "react";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./GalleryCreateForm.css";
import ErrorPopup from "../../PopUps/ErrorPopup/ErrorPopup";
import coverImageIcon from '../../../Assets/coverImageIcon.svg'
import popupsCloseIcon from '../../../Assets/popupsCloseIcon.svg'

export default function GalleryCreateForm({
    setFormData,
    uploadTypes,
    selectedUploadType,
    initialFormData,
    handleUploadTypeChange,
    visible,
    setpopUp,
    formData,
    formErrors,
    handleInputChange,
    handleAlbumGalleryImageChange,
    inputRefAlbumCoverImage,
    albumCoverImageLoading,
    handleSubmit,
    removeAlbumCoverImage,
    error,
    setError,
    handleCompressTypeChange,
    compressType
}) {
    return (
        <PopupWrapper visible={visible} setpopUp={setpopUp}>
            {error.errorStatus === true && (
                <ErrorPopup error={error} setError={setError} />
            )}

            <div className=" GalleryCreateFormContainer mt-[78px]  ">
                <div className="p-[24px] sticky top-0 bg-white flex items-center justify-between rounded-t-[12px]">
                    <p className="font_semibold_20 text-[rgb(22,22,22)]">Add a new album gallery</p>
                    <img
                        onClick={() => {
                            setpopUp(false);
                            setFormData(initialFormData);
                        }}
                        src={popupsCloseIcon}
                        alt=""
                        className="cursor-pointer" />
                </div>
                <div className='seperatorHorizontalNew'></div>
                <div className=" pt-[12px]">
                    <p className="px-[24px] font_semibold_16 text-[#161616] mb-[16px]">Gallery details</p>
                    <div className="mx-[24px] rounded-[8px] border-[#8C8C8C4D] border-[1px]   cursor-pointer w-[420px] h-[100px] flex justify-center items-center ">
                        {formData?.coverImage ? <div className="w-full h-full">
                            <img
                                src={formData?.coverImage}
                                alt=""
                                className="w-full h-full object-cover" />
                        </div>
                            :
                            <div
                                onClick={() => inputRefAlbumCoverImage.current.click()}
                                className="flex gap-[10px] items-center border-[1px] border-[#8C8C8C33]">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleAlbumGalleryImageChange}
                                    className="hidden"
                                    id="coverImage"
                                    name="coverImage"
                                    ref={inputRefAlbumCoverImage}
                                />
                                <img src={coverImageIcon} alt="" />
                                <p className="font_normal_14 text-[#161616]">
                                    {albumCoverImageLoading
                                        ? "Uploading..."
                                        : "Choose Album Cover"}</p>
                            </div>}
                    </div>
                    <div className="flex justify-end px-[24px] mt-[8px]">
                        {formData?.coverImage &&
                            <p
                                onClick={removeAlbumCoverImage}
                                className="font_normal_12 text-[#4D58E3] cursor-pointer">
                                Change Cover image</p>}
                    </div>
                    <div className="px-[24px] mt-[16px] mb-[20px] flex flex-col ">
                        <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                            Gallery Name <span className="text-red-500">*</span>
                        </p>
                        <input
                            type="text"
                            name="folderName"
                            placeholder="Enter gallery name"
                            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${formErrors.folderName ? "border-red-500" : "border-[#8C8C8C4D]"}`}
                            value={formData.folderName}
                            onChange={handleInputChange}
                        />
                        {formErrors.folderName && (
                            <p className="font_normal_12 text-red-500">{formErrors.folderName}</p>
                        )}
                    </div>
                    <div className="px-[24px] flex flex-col  mb-[20px]">
                        <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                            Gallery description <span className="text-red-500">*</span>
                        </p>
                        <input
                            type="text"
                            name="description"
                            placeholder="Enter gallery description"
                            className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${formErrors.description ? "border-red-500" : "border-[#8C8C8C4D]"
                                }`}
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                        {formErrors.description && (
                            <p className="font_normal_12 text-red-500">{formErrors.description}</p>
                        )}
                    </div>
                    <div className='seperatorHorizontalNew'></div>
                    <div className="px-[24px] mt-[16px]">
                        <p className="font_semibold_16 text-[#161616] mb-[8px]">
                            Choose any photo uploading method
                        </p>
                        {/* <select
                            id="uploadType"
                            name="uploadType"
                            value={selectedUploadType}
                            onChange={handleUploadTypeChange}
                            className="w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]"
                        >
                            {uploadTypes.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select> */}

                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-[10px]">
                                <input type="radio"
                                    id="uploadType2"
                                    name="uploadType"
                                    value="DESKTOP"
                                    onChange={handleUploadTypeChange}
                                    checked={selectedUploadType === "DESKTOP"}
                                    className="cursor-pointer" />
                                <label for="uploadType2">Upload from Computer</label>
                            </div>
                            <div className="flex items-center gap-[10px]">
                                <input type="radio"
                                    id="uploadType1"
                                    name="uploadType"
                                    value="GOOGLE_DRIVE"
                                    onChange={handleUploadTypeChange}
                                    className="cursor-pointer" />
                                <label for="uploadType1">Upload from Google Drive</label>
                            </div>
                        </div>
                        {selectedUploadType === "GOOGLE_DRIVE" && (
                            <div className="mt-[8px]">
                                <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                                    Google Drive Url <span className="text-red-500">*</span>
                                </p>
                                <input
                                    type="text"
                                    name="gdriveUrl"
                                    placeholder="Enter public Google Drive url"
                                    className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px]  ${formErrors.gdriveUrl ? "border-red-500" : "border-[#8C8C8C4D]"
                                        }`}
                                    value={formData.gdriveUrl}
                                    onChange={handleInputChange}
                                />
                                {formErrors.gdriveUrl && (
                                    <p className="font_normal_12 text-red-500">{formErrors.gdriveUrl}</p>
                                )}
                            </div>
                        )}
                    </div>
                    {formErrors.coverImage && (
                        <p className="text-red-500 mt-2">{formErrors.coverImage}</p>
                    )}
                    {/* <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="uploadType">
                            COMPRESSION NEEDED
                        </label>
                        <select
                            id="compressType"
                            name="compressType"
                            value={compressType}
                            onChange={handleCompressTypeChange}
                            className="border rounded p-2 w-10/12"
                        >
                            <option key="compress" value="compress">
                                compress
                            </option>
                            <option key="original" value="original">
                                original
                            </option>
                        </select>
                    </div> */}
                    <div className='seperatorHorizontalNew mt-[16px] '></div>

                    <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
                        <button
                            className="cancelButton font_semibold_12"
                            onClick={() => {
                                setpopUp(false)
                                setFormData(initialFormData)
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            class=" eventButton"
                            onClick={handleSubmit}>
                            <p className="text-[#F6F6F6] font_semibold_12">Create gallery</p>
                        </button>
                    </div>
                </div>
            </div>

        </PopupWrapper>
    );
}
