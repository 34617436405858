import React from "react";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import "./TrialPopup.css";
import { NewPopUpWrapper } from "../../UI/PopupWrapper/NewPopUpWrapper";

function TrialPopup({
  trialData,
  setSelectedType,
  studioDetails,
  handlePaymentSelect,
  paySelResponse,
  loading,
}) {
  return (
    <NewPopUpWrapper visible={true}>
      <div className="bg-[#fff] min-h-[400px] popUpShadow max-h-[450px] min-w-[400px] rounded-[10px] flex flex-col trialPop mt-[5%]   ">
        <div className="flex w-full border-opacity-20 justify-between pb-[20px] items-start border-b-[1px] border-[#475467] ">
          <div className=" flex items-center gap-[12px]">
            <button className=" headDiv">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M11 15H6L13 1V9H18L11 23V15Z" fill="#373CBD" />
              </svg>
            </button>
            <div>
              <p className="FreeTrial flex items-center gap-[4px]">
                Free Trial{" "}
                <span className="days">
                  {trialData?.[0]?.storageTenureInDays} Days
                </span>
              </p>
              <p className="mt-[9px]">
                Total Storage{" "}
                <span className="font-[600]">
                  {trialData?.[0]?.storageInGb}GB
                </span>{" "}
              </p>
            </div>
          </div>

          <button onClick={() => setSelectedType("YEARLY")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="border-[1px] border-[#475467] mt-[20px] rounded-[10px] border-opacity-20  justify-start items-start flex flex-col  p-[20px]">
          <p className="headTrial">
            Free Trial for {trialData?.[0]?.storageTenureInDays} Days
          </p>
          <p className="demo">Demo version</p>

          {trialData?.[0]?.services?.map((item, i) => (
            <div
              className={`flex items-center gap-[4px] ${
                i === 0 ? "mt-[20px]" : "mt-[12px]"
              } `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z"
                  fill="#373CBD"
                />
                <path
                  d="M8.8167 12.9833C8.65003 12.9833 8.4917 12.9166 8.37503 12.8L6.0167 10.4416C5.77503 10.2 5.77503 9.79996 6.0167 9.55829C6.25837 9.31663 6.65837 9.31663 6.90003 9.55829L8.8167 11.475L13.1 7.19163C13.3417 6.94996 13.7417 6.94996 13.9834 7.19163C14.225 7.43329 14.225 7.83329 13.9834 8.07496L9.25837 12.8C9.1417 12.9166 8.98337 12.9833 8.8167 12.9833Z"
                  fill="white"
                />
              </svg>
              <p className="itemName">{item?.name}</p>
            </div>
          ))}
        </div>
        {paySelResponse && (
          <p className="text-[16px] text-[red]">{paySelResponse} </p>
        )}
        <div
          className={`flex ${
            paySelResponse ? "mt-0" : "mt-[20px]"
          } items-center gap-[12px] justify-center`}
        >
          <button
            onClick={() => setSelectedType("YEARLY")}
            className="cancelButtonPop"
          >
            Cancel
          </button>
          <button
            disabled={loading}
            onClick={() => {
              handlePaymentSelect(
                trialData?.[0]?._id,
                studioDetails?.studioId,
                "ONETIME",
                trialData?.[0]?.packageType
              );
            }}
            className="continueButton"
          >
            {loading ? "Loading" : "Continue"}
          </button>
        </div>
      </div>
    </NewPopUpWrapper>
  );
}

export default TrialPopup;
