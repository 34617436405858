import React, { useState } from "react";
import ImageModel from "../ImageModel/ImageModel";

const ConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <p className="text-lg mb-4">
          Are you sure you want to delete this image?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-gray-200"
            onClick={onCancel}
          >
            No
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300"
            onClick={onConfirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageList = ({
  handleDeleteImage,
  folderId,
  uploadedMedias,
  memshotAlbumId,
  folderName,
  deletingMedia,
  type,
  getBucketName,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loadingImages, setLoadingImages] = useState(new Set());
  const [viewingMedia, setViewingMedia] = useState(null);
  const [mediaToDelete, setMediaToDelete] = useState(null); // Track the media to delete

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleImageLoad = (media) => {
    setLoadingImages((prev) => {
      const newSet = new Set(prev);
      newSet.delete(media);
      return newSet;
    });
  };

  const handleImageError = (media) => {
    setLoadingImages((prev) => {
      const newSet = new Set(prev);
      newSet.add(media);
      return newSet;
    });
  };

  const confirmDelete = () => {
    if (mediaToDelete) {
      handleDeleteImage(mediaToDelete, folderId);
      setMediaToDelete(null); // Reset after deletion
    }
  };

  if (!uploadedMedias || uploadedMedias.length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-2xl text-red-500">No images found</p>
      </div>
    );
  }
  const bucketName = getBucketName(type);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 p-4">
      {uploadedMedias &&
        uploadedMedias.map(
          (media, index) =>
            media !== "folderCheck.keep" && (
              <div
                key={index}
                className="relative group rounded-lg overflow-hidden shadow-lg transition-transform transform-gpu duration-300 ease-in-out hover:scale-105"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="block w-full h-40 overflow-hidden rounded-lg">
                  {loadingImages.has(media) ? (
                    <div className="flex items-center justify-center w-full h-full bg-gray-200">
                      <div className="loader">Loading...</div>
                    </div>
                  ) : (
                    <img
                      className="object-cover w-full h-full rounded-lg"
                      src={`https://storage.googleapis.com/albumthumbnails/${memshotAlbumId}/${folderName}/d_${media}`}
                      alt={media}
                      onLoad={() => handleImageLoad(media)}
                      onError={() => handleImageError(media)}
                    />
                  )}
                  {/* Image Name Always Visible */}
                  <div className="absolute bottom-0 left-0 w-full bg-white bg-opacity-90 p-1">
                    <p
                      className="text-black text-sm font-medium truncate px-2"
                      title={media} // Show full name on hover
                    >
                      {media}
                    </p>
                  </div>
                </div>
                {hoveredIndex === index && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 group-hover:opacity-100 transition-opacity duration-300 ease-in-out rounded-lg">
                    <div className="text-center text-white space-x-4">
                      <button
                        className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
                        onClick={() =>
                          setViewingMedia(
                            `https://storage.googleapis.com/${bucketName}/${memshotAlbumId}/${folderName}/${media}`
                          )
                        }
                      >
                        View
                      </button>
                      <button
                        className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300"
                        onClick={() => setMediaToDelete(media)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
                {deletingMedia === media && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-300 ease-in-out rounded-lg">
                    <div className="text-center text-white">Deleting...</div>
                  </div>
                )}
              </div>
            )
        )}
      {viewingMedia && (
        <ImageModel
          media={viewingMedia}
          onClose={() => setViewingMedia(null)}
        />
      )}
      {mediaToDelete && (
        <ConfirmationModal
          onConfirm={confirmDelete}
          onCancel={() => setMediaToDelete(null)}
        />
      )}
    </div>
  );
};

export default ImageList;
