import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "../../Assets/Animation/Loading";
import { useNavigate } from "react-router-dom";
import ClientDropDown from "../../Components/ClientDropDown/ClientDropDown";
import ClientForm from "../../Components/Forms/ClientForm/ClientForm";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import "./Clients.css";
import whiteAddButton from "../../Assets/whiteAddButton.svg";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import Pagination from "../../Components/Pagination/Pagination";
import { isEmpty } from "lodash";

function Clients() {
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { studioId } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const navigate = useNavigate();
  const limit = 20;

  useEffect(() => {
    verifyUser();
    if (studioId) {
      getClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, studioId, limit, currentPage]);

  const dateShortFormat = (dateString) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();

    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${day}  ${monthName}  ${year}`;
  };

  const getClient = async () => {
    setLoading(true);
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALL_CLIENTS}/${studioId}?page=${currentPage}&limit=${limit}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setTotalPages(Math.ceil(response?.data?.length / limit));
        setClientData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <div className="clientContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[{ label: "Event", url: "/Event" }]}
      />
      {popup && (
        <ClientForm
          update={update}
          setUpdate={setUpdate}
          visible={popup}
          setpopUp={setPopup}
          idkey="create"
        />
      )}
      {!isEmpty(clientData) && (
        <div className="flex justify-between p-[24px] items-center">
          <p className="font_semibold_20 text-[#161616]">Clients Overview</p>
          <button
            onClick={() => setPopup(true)}
            className="eventButton flex items-center gap-[6px] "
          >
            <img src={whiteAddButton} alt="" className="w-[20px] h-[20px]" />
            <p className="font_semibold_12 ">Add New Client</p>
          </button>
        </div>
      )}
      <div className="seperatorHorizontalNew"></div>
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <>
          {isEmpty(clientData) ? (
            <div className="w-full h-[50vh] flex flex-col gap-[20px] items-center justify-center">
              <p className="font_medium_22">
                Please provide your client details for your records.
              </p>
              <button
                onClick={() => setPopup(true)}
                className="eventButton flex items-center gap-[6px] "
              >
                <img
                  src={whiteAddButton}
                  alt=""
                  className="w-[20px] h-[20px]"
                />
                <p className="font_semibold_12 ">Create</p>
              </button>
            </div>
          ) : (
            <>
              <div className="clientTable">
                <table>
                  <tbody>
                    <tr>
                      <th>Client Name</th>
                      <th>Location</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Company</th>
                      <th>Date Created</th>
                      <th>Country</th>
                      <th className="w-[44px]"></th>
                    </tr>
                    {clientData.map((obj) => {
                      return (
                        <tr key={obj._id} onClick={() => navigate()}>
                          <td>
                            {obj.firstName} {obj.lastName}
                          </td>
                          <td>{obj.location}</td>
                          <td>{obj.phone}</td>
                          <td>{obj.email}</td>
                          <td>
                            {obj.isCompany === "TRUE" ? obj.company : "-"}
                          </td>
                          <td>{dateShortFormat(obj.createdAt)}</td>
                          <td>{obj.country}</td>
                          <td className="flex justify-end">
                            <ClientDropDown
                              update={update}
                              setUpdate={setUpdate}
                              clientObj={obj}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                  limit={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  dataLength={clientData.length}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Clients;
