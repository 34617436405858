import GR1 from "../../Assets/GR1.png";
import GR2 from "../../Assets/GR2.png";
import GR3 from "../../Assets/GR3.png";
export const loginGraphicCardData = [
  {
    id: 1,
    img: GR1,
    head: "Welcome to Memshots",
    text: "Unlock the possibilities of Memshots, Best tool ever made for photographers and studios. Glad to see you again. Login to your account below",
  },

  {
    id: 2,
    img: GR2,
    head: "Let us help you run your photography Business",
    text: "Prioritize your work based on customer needs and build a enlightening connections with your customers.",
  },
  {
    id: 3,
    img: GR3,
    head: "Let get started!",
    text: "Prioritize your work based on customer needs and build a enlightening connections with your customers.",
  },
];
