import React, { useState } from "react";
import PhotoShareQRGenerator from "./PhotoShareQRGenerator";

const UploadPanel = ({
  memshotAlbumId,
  slugId,
  studioDetails,
  setShowUploadPopup,
  uploadedMediasLength,
  setRefresh,
  refresh,
  selectedMedia,
  setSelectedMedias,
}) => {
  const [showQRGenerator, setShowQRGenerator] = useState(false);

  const handleClick = () => {
    setShowUploadPopup(true);
  };

  const clearCart = () => {
    setSelectedMedias([]);
  };

  const openCart = () => {
    setShowQRGenerator(true);
  };

  return (
    <>
      <div className="bg-gradient-to-r from-gray-800 via-gray-700 to-gray-600 text-white p-6 rounded-xl shadow-2xl mb-4 transform transition duration-300 ease-in-out hover:shadow-lg">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-6">
            <span className="text-gray-300 text-lg">
              <strong className="text-white">Uploaded Media:</strong>{" "}
              {uploadedMediasLength}
            </span>
            <button
              className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-6 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={handleClick}
            >
              Upload New Media
            </button>
            <button
              className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-6 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-green-500"
              onClick={() => setRefresh(!refresh)}
            >
              Refresh
            </button>
            {selectedMedia?.length > 0 && (
              <>
                <button
                  className="bg-cyan-600 hover:bg-cyan-500 text-white font-semibold py-2 px-6 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-cyan-500"
                  onClick={openCart}
                >
                  Generate QR Code{" "}
                  <span className="text-sm">({selectedMedia?.length})</span>
                </button>
                <button
                  onClick={clearCart}
                  className="bg-red-600 hover:bg-red-500 text-white font-semibold py-2 px-6 rounded-lg transition-transform transform hover:scale-105 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Clear Cart
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {showQRGenerator && (
        <div className="mt-4">
          <PhotoShareQRGenerator
            slugId={slugId}
            studioDetails={studioDetails}
            memshotAlbumId={memshotAlbumId}
            onClose={() => setShowQRGenerator(false)}
            selectedMedia={selectedMedia}
          />
        </div>
      )}
    </>
  );
};

export default UploadPanel;
