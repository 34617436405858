import React from 'react'
import { PopupWrapper } from '../../../UI/PopupWrapper/PopupWrapper'
import './SelectionEditForm.css'
import ErrorPopup from '../../PopUps/ErrorPopup/ErrorPopup'

export default function SelectionEditForm({
    initialFormData,
    setFormData,
    galleryPopupEdit,
    visible,
    setpopUp,
    formData,
    formErrors,
    handleInputChange,
    handleSubmit,
    error,
    setError }) {
    return (
        <PopupWrapper visible={visible} setpopUp={setpopUp}>
            {error.errorStatus === true &&
                <ErrorPopup error={error} setError={setError} />
            }
            <div className='w-[60%] h-[80%] overflow-y-scroll  bg-[#ffff]'>
                <div className='heading p-[24px] flex flex-row justify-between'>
                    <h3>Edit Details of Selection Tool</h3>
                    <button onClick={() => {
                        setpopUp(false)
                        setFormData(initialFormData)
                        setpopUp({ "visible": false, "galleryId": "" })
                    }}>Close</button>
                </div>
                <div className='ml-4 mt-4 md:ml-4 lg:ml-10'>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="coverImage">
                        Folder Name
                    </label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Enter Folder Name"
                        className={`border rounded p-4 w-10/12 ${formErrors?.title ? 'border-red-500' : ''}`}
                        value={formData?.title}
                        onChange={handleInputChange}
                    />
                    {formErrors?.title && <p className="text-red-500">{formErrors?.title}</p>}
                    <br />
                    <div class="text-center ">
                        <button class="mx-auto bg-blue-500 mb-4 text-white font-bold py-2 px-2 rounded" onClick={() => { handleSubmit(galleryPopupEdit?.folderId) }}>Edit</button>
                    </div>

                </div>
            </div >
        </ PopupWrapper >
    )
}
