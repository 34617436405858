import React, { useEffect, useRef, useState } from "react";
import dropdownArrow from "../../Assets/dropdownArrow.svg";
import "./UsersDropDown.css";
import StudioImage from "../StudioImage/StudioImage";

function UsersDropDown({ studioRole, setRoleSelected }) {
  const [dropdown, setDropdown] = useState(false);
  const modalRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  return (
    <div className="">
      <div className="dropdown" ref={modalRef}>
        <img
          onClick={(e) => {
            setDropdown((prevState) => {
              e.stopPropagation();
              return !prevState;
            });
          }}
          src={dropdownArrow}
          alt=""
          className="ml-[5px] w-[12px] h-[12px]"
        />
        {dropdown && (
          <div className="dropdownContent">
            {studioRole?.map((obj, index) => {
              return (
                <div key={index}>
                  <div
                    onClick={() => {
                      setRoleSelected(obj);
                      localStorage.setItem("studioId", obj?.studioId);
                      window.location.href = `/Event`;
                      setDropdown(false);
                    }}
                    className="dropdownList"
                  >
                    <StudioImage
                      studioId={obj?.studioId}
                      fallbackUrl={obj.studioLogo}
                    />
                    <p className="font_normal_12">{obj.studioName} </p>
                  </div>
                  <div className="seperatorHorizontalNew"></div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default UsersDropDown;
