





import React, { useContext, useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Lottie from "../../Assets/Animation/Loading";
import SelectionEditForm from "../../Components/Forms/SelectionEditForm/SelectionEditForm";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import qrCodeIcon from "../../Assets/qrCodeIcon.svg";
import MessagePopup from "../../Components/PopUps/MessagePopup/MessagePopup";
import { userAuthContext } from "../../Context/UserAuthContext";
import AddPinSelectionTool from "../../Components/Forms/AddPinSelectionTool/AddPinSelectionTool";
import setPinIcon from "../../Assets/setPinIcon.svg";
import ErrorPopup from "../../Components/PopUps/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import AlbumEditForm from "../../Components/AlbumSection/AlbumEditForm";
// import ToolBarSelectionTool from "../../Components/ToolBarSelectionTool/ToolBarSelectionTool";
import axios from "axios";
import MetaDataPopupSelectionTool from "../../Components/MetaDataPopup/MetaDataPopupSelectionTool";
import SelectionFolderCreate from "../../Components/Forms/SelectionFolderCreateForm/SelectionFolderCreate";
import AddUser from "../../Components/Forms/AddUserForm/AddUser";
import CrewUsers from "../../Components/PopUps/CrewUsers";
import "./SelectionToolView.css";
//import albumThumb from "../../Assets/albumThumn.png";
import options from "../../Assets/NavbarIcons/options.svg";
import userIcon from "../../Assets/userIcon.svg";
import eventInvitation from "../../Assets/eventInvitation.svg";
import detailsIcon from "../../Assets/detailsIcon.svg";
import refresshIcon from "../../Assets/refresshIcon.svg";
import fileUploadIcon from "../../Assets/fileUploadIcon.svg";
//import AlbumDetailDropdown from "../../Components/AlbumDetailDropdown/AlbumDetailDropdown";
import Breadcrumb from "../../Components/BreadCrumb/BreadCrumb";
import ImageGalleryPopup from "../../Components/ImageGalleryPopup/ImageGalleryPopup";
import Watermark from "../../Components/Watermark/Watermark";
import ChangeStatus from "../../Components/PopUps/changeStatus/ChangeStatus";

function SelectionToolView() {
  const [isOpenGallery, setIsOpenGallery] = useState(false);
  const [downloadLinkReady, setDownloadLinkReady] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [metaDataPopup, setMetaDataPopup] = useState(false);
  const [compressType, setCompressType] = useState("compress");
  const [viewUserListPopup, setViewUserListPopup] = useState(false);
  const initialFormData = {
    folderName: "",
    gdriveUrl: "",
    description: "",
    coverImage: "",
    title: "",
  };
  const [sessionForm, setsessionForm] = useState(false);
  const [copy, setCopy] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    folderName: "",
    gdriveUrl: "",
  });
  const uploadTypes = ["DESKTOP", "GOOGLE_DRIVE"];
  const [selectedUploadType, setSelectedUploadType] = React.useState(
    uploadTypes[0]
  );
  const handleUploadTypeChange = (event) => {
    setSelectedUploadType(event.target.value);
  };
  const [editAlbumTemplate, setEditAlbumTemplate] = useState({
    mode: false,
    albumId: null,
  });
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [addPinPopup, setaddPinPopup] = useState(false);
  const [addWatermarkPopup, setAddWatermarkPopup] = useState(false);
  const [galleryPopupEdit, setGalleryPopupEdit] = useState({
    visible: false,
    galleryId: "",
  });
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({});
  const [trainingData, setTrainingData] = useState({});
  const { projectId, shootmatesAlbumId } = useParams();
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const [albmTrainingSession, setalbmTrainingSession] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const { studioId } = useContext(dataContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        if (studioId) {
          await Promise.all([getAlbumDetails(), getGAlleryData()]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId, update]);

  const getAlbumDetails = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_DETAILS_DATA}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumDetails(response?.data?.data);
        //setAuthorisedUsers(response?.data?.data?.authorisedUsers);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getGAlleryData = async () => {
    try {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_GET_ALBUM_SECTION_DATAS}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setTrainingData(response?.data?.data);
        setalbmTrainingSession(response?.data?.data?.sections);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async () => {
    try {
      verifyUser();
      let isValid = true;
      const errors = {};
      if (formData.folderName.trim() === "") {
        errors.folderName = "Gallery Name is empty";
        isValid = false;
      }
      if (selectedUploadType === "GOOGLE_DRIVE") {
        if (formData.gdriveUrl.trim() === "") {
          errors.gdriveUrl = "Google Drive URL is empty";
          isValid = false;
        }
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      let url =
        // selectedUploadType === "GOOGLE_DRIVE"
        //   ? `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/creategdrivefolderdeskdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`
        //   : `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;
        selectedUploadType === "GOOGLE_DRIVE"
          ? `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/creategdrivefolderdeskdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`
          : `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/createfolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}`;
      let config = {
        method: "post",
        url: url,
        data: {
          ...formData,
          title: formData?.folderName,
          description: "photo selection",
          coverImage: "photo selection",
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setFormData(initialFormData);
          setsessionForm(false);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData({
        folderName: "",
        gdriveUrl: "",
      });
      setLoading(false);
      setsessionForm(false);
      setUpdate(!update);
    }
  };

  const handleSubmitGalleryEdit = async (galleryId) => {
    try {
      let isValid = true;
      const errors = {};
      if (formData.title.trim() === "") {
        errors.title = "Gallery Cover image is empty";
        isValid = false;
      }
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/albumsession/editAlbumGallery/${projectId}/${studioId}/${shootmatesAlbumId}/${galleryId}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
        } else {
          setUpdate(!update);
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: false,
            header: "Message",
          });
          setGalleryPopupEdit({ visible: false, galleryId: "" });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setFormData(initialFormData);
      setLoading(false);
      setGalleryPopupEdit({ visible: false, galleryId: "" });
      setUpdate(!update);
    }
  };

  const handleAlbumMetaDataButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setMetaDataPopup(true);
  };

  const handleUserListingButton = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    setViewUserListPopup(true);
  };

  const handleGalleryPopupEdit = (folderId, divData) => {
    const initialFormData = {
      title: divData?.title,
      description: divData?.description,
      coverImage: divData?.coverImage,
    };
    setFormData(initialFormData);
    setGalleryPopupEdit({ visible: true, folderId: folderId });
  };

  const triggerGDriveUpload = async (sectionId) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncgdriveupload/${projectId}/${studioId}/${shootmatesAlbumId}/${sectionId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleDelete = async (folderId) => {
    setIsLoading(true);
    try {
      let config = {
        method: "delete",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/deletefolderdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        data: {
          folderId: folderId,
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setShowDeletePopup(false);
        setUpdate(!update);
      }
    } catch (err) {
      console.error("Error deleting folder:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = () => {
    if (!albumDetails?.memshotAlbumId) {
      setError({
        errorMessage: "Memshots Album is Processing Please Wait",
        errorStatus: true,
        header: "Message",
      });
      return;
    }
    const linkText = `${albumDetails?.slugId}`;
    const linkToCopy = `https://selection.memshots.com/${linkText}/select`;

    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  const handleCompressTypeChange = (event) => {
    setCompressType(event.target.value);
  };

  const handleDownload = async () => {
    try {
      setDownloadLinkReady(true);
      verifyUser();

      let config = {
        method: "get",
        url: `${process.env.REACT_APP_DOWNLOAD_AS_EXCEL}/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);

      // Assuming the JSON data is in response.data.data
      const jsonData = response?.data?.data;
      console.log("jsonData", jsonData);

      // Generate PDF
      const doc = new jsPDF();
      doc.text(`${albumDetails?.albumName} Selected Images`, 20, 20);

      const columns = [
        { title: "No", dataKey: "No" },
        { title: "Folder Name", dataKey: "folderName" },
        { title: "Name", dataKey: "name" },
        // { title: "View Image", dataKey: "url" } // Column for the button
      ];

      const rows = jsonData.map((item) => ({
        No: item.No,
        folderName: item.folderName,
        name: item.name,
        // url: decodeURIComponent(item.url) // Store URL for linking
      }));

      doc.autoTable({
        head: [columns.map((col) => col.title)],
        body: rows.map((row) =>
          columns.map((col) => {
            // if (col.dataKey === 'url') {
            //   return 'View Image'; // Display button text
            // }
            return row[col.dataKey];
          })
        ),
        startY: 30,
        didDrawCell: (data) => {
          if (data.column.index === 3) {
            // URL column
            const { doc, cell } = data;
            const url = rows[data.row.index].url; // Get URL for the button

            if (cell.textPos?.x && cell.textPos?.y) {
              doc.setTextColor(0, 0, 255); // Blue color for the button
              doc.textWithLink("View Image", cell.textPos.x, cell.textPos.y, {
                url,
              });
              doc.setTextColor(0, 0, 0); // Reset color to black
            }
          }
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 50 }, // Adjusted width to ensure it's large enough
          2: { cellWidth: 50 }, // Adjusted width to ensure it's large enough
          3: { cellWidth: 60 }, // Increased width for the button
        },
        styles: { overflow: "linebreak", cellPadding: 2 },
        margin: { right: 10 }, // Adds some margin to avoid text overflow
      });

      // Save the PDF
      doc.save(`${albumDetails?.albumName}.pdf`);

      setDownloadLinkReady(false);
    } catch (error) {
      console.error("Error downloading or generating file:", error);
      setDownloadLinkReady(false);
    }
  };

  const handleChangeSelectionStatus = async () => {
    try {
      setLoading(true);
      verifyUser();
      let config = {
        method: "post",
        // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/changeSelectionStatus/${projectId}/${studioId}/${shootmatesAlbumId}`,
        url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/changeSelectionStatus/${projectId}/${studioId}/${shootmatesAlbumId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      await axios(config);
      setUpdate(!update);
      setChangeStatus(true);
    } catch (error) {
      setUpdate(!update);
      console.error("Error downloading file:", error);
    }
  };

  const handleRefreshImages = async (folderId) => {
    setLoading(true);
    try {
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/medias/api/shootmates/medias/syncimagesdashboard/${projectId}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        if (response.data.error === true) {
          setError({
            errorMessage: response?.data?.msg,
            errorStatus: true,
            header: "Message",
          });
          setLoading(false);
          return;
        }
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <div className="albumDetailContainer min-h-[100vh]">
      <Breadcrumb
        update={update}
        setUpdate={setUpdate}
        items={[
          { label: "Event", url: "/Event" },
          { label: "Event Detail", url: `/Event/${projectId}` },
        ]}
      />
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div>
          {/* {changeStatus && ( */}
            <ChangeStatus visible={changeStatus} onClose={setChangeStatus} />
          {/* )} */}
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          {metaDataPopup && (
            <MetaDataPopupSelectionTool
              update={update}
              setUpdate={setUpdate}
              visible={metaDataPopup}
              setView={setMetaDataPopup}
              data={albumDetails}
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
            />
          )}
          {isOpenGallery && (
            <ImageGalleryPopup
              visible={isOpenGallery}
              setIsOpenGallery={setIsOpenGallery}
              albumId={albumDetails?.memshotAlbumId}
              projectId={projectId}
              shootmatesAlbumId={shootmatesAlbumId}
              studioId={studioId}
            />
          )}
          {viewUserListPopup && (
            <CrewUsers
              studioId={studioId}
              shootmatesAlbumId={shootmatesAlbumId}
              projectId={projectId}
              setViewUserListPopup={setViewUserListPopup}
            />
          )}
          {addUserPopup && (
            <AddUser
              addUserPopup={addUserPopup}
              setAddUserPopup={setAddUserPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
              visible={addUserPopup}
              setpopUp={setAddUserPopup}
            />
          )}
          {addPinPopup && (
            <AddPinSelectionTool
              setaddPinPopup={setaddPinPopup}
              projectId={projectId}
              albumId={shootmatesAlbumId}
              visible={addPinPopup}
              privateKey={albumDetails?.privateKey}
              update={update}
              setUpdate={setUpdate}
            />
          )}
          {addWatermarkPopup && (
            <Watermark
              setAddWatermarkPopup={setAddWatermarkPopup}
              projectId={projectId}
              studioId={studioId}
              albumId={shootmatesAlbumId}
              visible={addWatermarkPopup}
            />
          )}
          {error.errorStatus === true && (
            <PopupWrapper visible={error.errorStatus}>
              <ErrorPopup error={error} setError={setError} />
            </PopupWrapper>
          )}
          {editAlbumTemplate?.mode && (
            <AlbumEditForm
              visible={editAlbumTemplate?.mode}
              setAlbum={setEditAlbumTemplate}
              projectId={projectId}
              albumId={editAlbumTemplate?.albumId}
            />
          )}
          {sessionForm && (
            <SelectionFolderCreate
              initialFormData={initialFormData}
              setFormData={setFormData}
              uploadTypes={uploadTypes}
              selectedUploadType={selectedUploadType}
              handleUploadTypeChange={handleUploadTypeChange}
              visible={sessionForm}
              handleCompressTypeChange={handleCompressTypeChange}
              compressType={compressType}
              setpopUp={setsessionForm}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              error={error}
              setError={setError}
            />
          )}
          {galleryPopupEdit?.visible && (
            <SelectionEditForm
              initialFormData={initialFormData}
              setFormData={setFormData}
              galleryPopupEdit={galleryPopupEdit}
              visible={galleryPopupEdit?.visible}
              setpopUp={setGalleryPopupEdit}
              formData={formData}
              formErrors={formErrors}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmitGalleryEdit}
              error={error}
              setError={setError}
            />
          )}

          <div className="flex justify-between items-center  px-[20px] py-[12px]">
            <div className="flex gap-[8px] items-center">
              <img src={eventInvitation} alt="" className="w-[62px] h-[62px]" />
              <div className=" flex flex-col gap-[8px]">
                <p className="font_semibold_20 text-[#161616]">
                  {albumDetails?.albumName}
                </p>

                <p className="font_normal_14  text-[#8C8C8C] ">
                  {`${
                    trainingData?.totalSelectedCount
                      ? trainingData?.totalSelectedCount
                      : 0
                  } / ${
                    trainingData?.totalMedias ? trainingData?.totalMedias : 0
                  } Photos Selected`}
                </p>
                {albumDetails?.userConfirmation === true && (
                  <p className="font_normal_14  text-[#8C8C8C] ">
                    User Selection Status -{" "}
                    <span className="text-[#21c251]">Confirmed</span>
                  </p>
                )}
                {albumDetails?.editRequested === true && (
                  <p className="font_normal_14  text-[#8C8C8C] ">
                    User Requested For Edit -{" "}
                    <span className="text-[#21c251]">Requested</span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="seperatorHorizontalNew mt-[6px]"></div>
          <div className="px-[20px] py-[16px] flex justify-between items-center">
            <div className="flex items-center gap-[16px]">
              <div
                onClick={() => {
                  setsessionForm(true);
                }}
                className="shortButton"
              >
                <img src={options} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Create folder</p>
              </div>
              <div
                onClick={() => {
                  setAddWatermarkPopup(true);
                }}
                className="shortButton"
              >
                <img src={options} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Add Watermark</p>
              </div>
              <div
                onClick={() => {
                  setaddPinPopup(true);
                }}
                className="shortButton"
              >
                <img src={setPinIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Set / View Pin</p>
              </div>
              <div
                onClick={() => copyToClipboard("live")}
                className="shortButtonBlack"
              >
                <img src={qrCodeIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#F6F6F6]">
                  {copy ? "Selection Link Copied!" : "Selection Link "}
                </p>
              </div>
              {downloadLinkReady ? (
                <div className="shortButton">
                  <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="font_normal_11 text-[#161616]">
                    Preparing Excel
                  </p>
                </div>
              ) : (
                <div className="shortButton" onClick={handleDownload}>
                  <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                  <p className="font_normal_11 text-[#161616]">
                    Download Excel Of Selected Images
                  </p>
                </div>
              )}
              <div onClick={handleUserListingButton} className="shortButton">
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">
                  View / Manage Crew
                </p>
              </div>
              <div
                onClick={() => {
                  setIsOpenGallery(true);
                }}
                className="shortButton"
              >
                <img src={userIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">
                  View Selected Images
                </p>
              </div>
            </div>
            <div className="flex items-center gap-[16px]">
              <div
                onClick={handleAlbumMetaDataButton}
                className=" ml-2 w-[100px] shortButton"
              >
                <img src={detailsIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Settings</p>
              </div>
              {/* <div
                onClick={() => {
                  setUpdate(!update);
                }}
                className="shortButton"
              >
                <img src={refresshIcon} alt="" className="w-[16px] h-[16px]" />
                <p className="font_normal_11 text-[#161616]">Refresh</p>
              </div> */}
              {loading ? (
                <div className="shortButton">
                  <img
                    src={refresshIcon}
                    alt=""
                    className="w-[16px] h-[16px]"
                  />
                  <p className="font_normal_11 text-[#161616]">Loading..</p>
                </div>
              ) : (
                <div
                  onClick={handleChangeSelectionStatus}
                  className="shortButton"
                >
                  <img
                    src={refresshIcon}
                    alt=""
                    className="w-[16px] h-[16px]"
                  />
                  <p className="font_normal_11 text-[#161616]">
                    Change Selection Status
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="seperatorHorizontalNew"></div>
          <div className="px-[20px] py-[18px] flex flex-col gap-[16px]">
            {albmTrainingSession &&
              albmTrainingSession.map((div, index) => (
                <DivWithData
                  showDeletePopup={showDeletePopup}
                  handleDelete={handleDelete}
                  setShowDeletePopup={setShowDeletePopup}
                  isLoading={isLoading}
                  handleRefreshImages={handleRefreshImages}
                  triggerGDriveUpload={triggerGDriveUpload}
                  handleGalleryPopupEdit={handleGalleryPopupEdit}
                  key={index}
                  divData={div}
                  error={error}
                  albumDetails={albumDetails}
                  setError={setError}
                  projectId={projectId}
                  albumId={shootmatesAlbumId}
                  loading={loading}
                  setLoading={setLoading}
                  studioId={studioId}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
export default SelectionToolView;

const ConfirmationPopup = ({ handleDelete, folderId, onCancel, isLoading }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-50">
    <div className="bg-white p-6 rounded-md shadow-lg">
      <p className="text-lg font-medium text-gray-900 mb-4">
        Are you sure you want to delete this folder?
      </p>
      <div className="flex justify-end gap-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 rounded-md bg-gray-600 text-white hover:bg-gray-700"
          disabled={isLoading}
        >
          No
        </button>
        <button
          onClick={() => handleDelete(folderId)}
          className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 flex items-center"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                  fill="none"
                />
              </svg>
              Deleting...
            </>
          ) : (
            "Yes, Delete"
          )}
        </button>
      </div>
    </div>
  </div>
);

const DivWithData = ({
  showDeletePopup,
  handleDelete,
  setShowDeletePopup,
  isLoading,
  handleRefreshImages,
  triggerGDriveUpload,
  handleGalleryPopupEdit,
  divData,
  error,
  setError,
  albumDetails,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex items-center rounded-lg border border-gray-300 p-4 shadow-sm hover:shadow-md transition-shadow duration-200 hover:bg-blue-50">
      {error.errorStatus === true && (
        <PopupWrapper visible={error.errorStatus}>
          <ErrorPopup error={error} setError={setError} />
        </PopupWrapper>
      )}
      {showDeletePopup && (
        <ConfirmationPopup
          handleDelete={handleDelete}
          folderId={divData?.folderId}
          onCancel={() => setShowDeletePopup(false)}
          isLoading={isLoading}
        />
      )}
      {/* Folder Icon */}
      <div className="flex-shrink-0 w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
        <svg
          className="w-8 h-8 text-blue-600"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M4 4a2 2 0 012-2h4l2 2h6a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V4z" />
        </svg>
      </div>

      {/* Folder Details */}
      <div className="ml-4 flex-grow">
        <div className="flex items-center justify-between mb-2">
          <p className="text-lg font-medium text-gray-900">{divData?.title}</p>
        </div>

        {/* Folder Stats */}
        <div className="flex gap-6 text-sm text-gray-700">
          <div className="flex items-center">
            <img
              src={fileUploadIcon}
              alt="Files uploaded"
              className="w-5 h-5"
            />
            <span className="ml-2">Files uploaded:</span>
            <span className="ml-2 text-blue-600">
              {divData?.processedImages}
            </span>
          </div>
          <div className="flex items-center">
            <img
              src={fileUploadIcon}
              alt="Selected Photos"
              className="w-5 h-5"
            />
            <span className="ml-2">Selected Photos:</span>
            <span className="ml-2 text-blue-600">
              {divData?.selectedCount || 0}
            </span>
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex items-center gap-3">
        <div
          onClick={() => {
            divData?.uploadType === "GDRIVE" || divData?.folderType === "GDRIVE"
              ? triggerGDriveUpload(divData?.folderId)
              : navigate(`${location.pathname}/upload/${divData?.folderId}`);
          }}
          className="flex items-center px-3 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white cursor-pointer transition-colors duration-200"
        >
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path d="M8 7v6h4V7h5l-7-7-7 7h5zM3 13h14v7H3v-7z" />
          </svg>
          <p className="text-sm">
            {divData?.uploadType === "GDRIVE" ||
            divData?.folderType === "GDRIVE"
              ? "Import images"
              : "Upload images"}
          </p>
        </div>
        {divData?.uploadType === "GDRIVE" ||
          (divData?.folderType === "GDRIVE" && (
            <div
              onClick={() => {
                navigate(`${location.pathname}/upload/${divData?.folderId}`);
              }}
              className="flex items-center px-3 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white cursor-pointer transition-colors duration-200"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M8 7v6h4V7h5l-7-7-7 7h5zM3 13h14v7H3v-7z" />
              </svg>
              <p className="text-sm">View images</p>
            </div>
          ))}

        {albumDetails?.userConfirmation !== true && (
          <div
            onClick={() => {
              handleRefreshImages(divData?.folderId);
            }}
            className="flex items-center px-3 py-2 rounded-md bg-green-600 hover:bg-green-700 text-white cursor-pointer transition-colors duration-200"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 14V8h2v6H9zM9 6V4h2v2H9z" />
            </svg>
            <p className="text-sm">Refresh Images</p>
          </div>
        )}
        {/* <div
          onClick={() => handleGalleryPopupEdit(divData?.folderId, divData)}
          className="flex items-center px-3 py-2 rounded-md bg-yellow-600 hover:bg-yellow-700 text-white cursor-pointer transition-colors duration-200"
        >
          <svg
            className="w-5 h-5 mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M4 13.5V16h2.5L15.374 7.126 12.874 4.626 4 13.5zM14.121 3.879l2 2L13.621 8.379l-2-2L14.121 3.879z"
            />
          </svg>
          <p className="text-sm">Edit Gallery</p>
        </div> */}
        <div
          onClick={() => setShowDeletePopup(true)}
          className="flex items-center px-3 py-2 rounded-md bg-red-600 hover:bg-red-700 text-white cursor-pointer transition-colors duration-200"
        >
          <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path d="M6 2a1 1 0 00-1 1v1H3v2h1v12a2 2 0 002 2h8a2 2 0 002-2V6h1V4h-2V3a1 1 0 00-1-1H6zm1 4h8v12H7V6zm2-2h4v1H9V4z" />
          </svg>
          <p className="text-sm">Delete Folder</p>
        </div>
      </div>
    </div>
  );
};












