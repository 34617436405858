import React, { useContext, useEffect, useState } from 'react'
import './Album.css'
import axios from 'axios'
import { dataContext } from '../../Context/DataContext'
import { userAuthContext } from '../../Context/UserAuthContext'
import { useNavigate } from 'react-router-dom'
import Lottie from '../../Assets/Animation/Loading'
import LeadFormAssignAlbum from '../../Components/Forms/LeadFormAssignAlbum/LeadFormAssignAlbum'
import AlbumCreateForm from '../../Components/AlbumSection/AlbumCreateForm'
import UserAddForm from '../../Components/UserAddSection/UserAddForm'
import blueAddButton from '../../Assets/blueAddButton.svg'
import EnabledIcon from '../../Assets/EnabledIcon.svg'
import downloadIcon from '../../Assets/downloadIcon.svg'
import pricingDetails from '../../Assets/pricingDetails.svg'
import paymenyPendIcon from '../../Assets/paymenyPendIcon.svg'

function Album() {
  const navigate = useNavigate()
  const { studioId } = useContext(dataContext)
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext)
  const tokenData = userIdToken || currentUser?.accessToken
  const [loading, setLoading] = useState(false)
  const [createAlbum, setCreateAlbum] = useState(false)
  const [addUserFormPopup, setAddUserFormPopup] = useState(false)
  const [workFlowTasks, setWorkflowTasks] = useState([])
  const [albumData, setAlbumData] = useState([])
  const [albumState, setAlbumState] = useState('purchased')
  const [update, setUpdate] = useState(false)
  const [leadForm, setLeadForm] = useState(false)
  const [albumId, setAlbumId] = useState()
  const [templateName, setTemplateName] = useState()
  const [albumTemplateId, setAlbumTemplateId] = useState()
  const [albumName, setAlbumName] = useState()
  const [albumTaskId, setAlbumTaskId] = useState()
  //const pageNumber = 1
  //const limit = 20
  const values = {
    hasEmailTask: false,
    isDrag: true,
    isCrew: false,
    workFlowTaskColor: '#000000',
  }

  useEffect(() => {
    verifyUser()
    if (studioId) {
      getAlbums()
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [update, studioId, albumState])

  const getAlbums = async () => {
    let isUsed = true
    if (albumState === 'purchased') {
      isUsed = 'false'
    } else {
      isUsed = 'true'
    }
    setLoading(true)
    try {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_GET_ALL_ALBUMS}/${studioId}?isUsed=${isUsed}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
      }
      const response = await axios(config)
      if (response.status === 201) {
        setAlbumData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }
  return (
    <div className="pl-[60px] pr-[30px]">
      {createAlbum && (
        <AlbumCreateForm
          update={update}
          setUpdate={setUpdate}
          visible={createAlbum}
          setAlbum={setCreateAlbum}
          workFlowTasks={workFlowTasks}
          setWorkFlowTasks={setWorkflowTasks}
          values={values}
        />
      )}
      {addUserFormPopup && (
        <UserAddForm
          albumTaskId={albumTaskId}
          update={update}
          setUpdate={setUpdate}
          visible={addUserFormPopup}
          setAddUserFormPopup={setAddUserFormPopup}
        />
      )}
      {leadForm && (
        <LeadFormAssignAlbum
          update={update}
          setUpdate={setUpdate}
          visible={leadForm}
          closePopup={setLeadForm}
          albumId={albumId}
          albumTemplateId={albumTemplateId}
          templateName={templateName}
          albumName={albumName}
        />
      )}
      <div className='flex gap-[16px] items-center pt-[20px]'>
        <div className='w-[20px] h-[20px] rounded-[6px] bg-[#EB35BF]'>
        </div>
        <p className='font_medium_22'>Album</p>
      </div>
      <div className=" flex justify-between mt-[30px] mb-[20px]">
        <div className='flex gap-[16px]'>
          <button
            className={`px-[16px] py-[8px] rounded-[30px] font_medium_15 ${albumState === 'purchased' ? 'bg-[#0064FF] text-[#FFFFFF]'
              : 'bg-[#E6E8EB] text-[#828D97]'
              }  focus:outline-none`}
            onClick={() => {
              setAlbumState('purchased')
            }}
          >
            Purchased albums
          </button>
          <button
            className={`px-[16px] py-[8px] rounded-[30px] font_medium_15 ${albumState === 'used' ? 'bg-[#0064FF] text-[#FFFFFF]'
              : 'bg-[#E6E8EB] text-[#828D97]'
              }  focus:outline-none`}
            onClick={() => {
              setAlbumState('used')
            }}
          >
            Used albums
          </button>
        </div>
        <div
          className='pl-[8px] pr-[12px] py-[8px] flex gap-[6px] bg-[#EEF0F6] rounded-[6px] '
          onClick={() => setCreateAlbum(true)}>
          <img
            src={blueAddButton}
            alt="blueAddButton"
          />
          <p className='font_medium_15 text-[#0064FF]'>Add new album</p>
        </div>
      </div>
      <div className="flex flex-row gap-5 text-sm  mx-3">

      </div>
      {loading ? (
        <>
          <Lottie />
        </>
      ) : (
        <div>
          <table className='albumTable'>

            <thead>
              <tr>
                <th>Album Name</th>
                <th>Status</th>
                <th>Face Detection</th>
                <th>Download QR Code</th>
                <th>Preview</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {albumData &&
                albumData.length > 0 &&
                albumData?.map((obj, index) => {
                  return (
                    <tr key={index} >
                      <td>
                        <div className='flex flex-col gap-[6px]'>
                          <div className='flex flex-col gap-[4px]'>
                            <p className='font_medium_16'>{obj?.albumName}</p>
                            <p className='font_medium_11 text-[#89909C]'>Unassigned</p>
                          </div>
                          <p className='font_normal_11'>896 images</p>
                        </div>
                      </td>
                      <td>
                        <div className='flex flex-col gap-[8px]' >
                          <div className='flex gap-[9px] items-center bg-[#F4FCF5] rounded-[30px] px-[10px] py-[5px] w-fit'>
                            <div className=' bg-[#4D9352] rounded-full w-[7px] h-[7px] '></div>
                            <p className='font_medium_13 text-[#4D9352]'>Party assigned</p>
                          </div>
                          <div className='flex flex-col gap-[5px]'>
                            <div className='flex flex-row gap-[4px]'>
                              <img src={paymenyPendIcon} alt="paymenyPendIcon" />
                              <p className='font_normal_11 text-[#1B2835]'>Payment pending</p>
                            </div>
                            <div>
                                <p className='font_medium_13 text-[#0064FF]'>Click to pay</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='flex flex-col gap-[5px] '>
                          <div className='flex flex-row gap-[3px] items-center bg-[#F4FCF5] rounded-[30px] px-[10px] py-[5px] w-fit'>
                            <div>
                              <img src={EnabledIcon} alt="EnabledIcon" />
                            </div>
                            <p className='font_medium_14 text-[#4D9352]'>Enabled</p>
                          </div>
                          <div className='flex flex-row gap-[4px] items-center'>
                            <img src={pricingDetails} alt="pricingDetails" />
                            <p className='font_normal_11 opacity-[0.6] '>See pricing details</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='flex gap-[6px] items-center bg-[#EEF0F6] rounded-[6px] px-[10px] py-[8px] w-fit'>
                          <div>
                            <img src={downloadIcon} alt="downloadIcon" />
                          </div>
                          <p className='font_medium_14 text-[#0064FF]'>Download</p>
                        </div>
                      </td>
                      <td>
                        <div className=' bg-[#FBF6FA] border-[#E1D7E0] border-[1px] rounded-[6px] px-[12px] py-[8px] w-fit'>
                          <p className='font_medium_14 text-[#906892]'>Preview</p>
                        </div>
                      </td>
                      {obj?.isUsed === false && (
                        <td>
                          <div onClick={() => {
                            setLeadForm(true)
                            setAlbumId(obj?._id)
                            setTemplateName(obj?.templateName)
                            setAlbumName(obj?.albumName)
                            setAlbumTemplateId(obj?.albumTemplateId)
                          }}
                            className="cursor-pointer">
                            <p className=' w-fit px-[16px] py-[8px] rounded-[6px] bg-[#0064FF] text-[#FFFFFF]'>Assign to party</p>
                          </div>
                        </td>
                      )}
                      {obj?.isUsed === true && (
                        <td>
                          <div onClick={() =>
                            navigate(
                              `/leads/${obj?.projectId}/albumTaskId/${obj?.albumTaskId}`,
                            )
                          }
                            className="cursor-pointer">
                            <p className=' w-fit px-[16px] py-[8px] rounded-[6px] bg-[#0064FF] text-[#FFFFFF]'> Upload Images</p>
                          </div>
                        </td>
                      )}
                      {obj?.isUsed === true && (
                        <td>
                          <div onClick={() => {
                            setAlbumTaskId(obj?.albumTaskId)
                            setAddUserFormPopup(true)
                          }}
                            className="cursor-pointer">
                            <p className=' w-fit px-[16px] py-[8px] rounded-[6px] bg-[#0064FF] text-[#FFFFFF]'>Add Users</p>
                          </div>
                        </td>
                      )}
                      {/* <td className="flex justify-end">
                          <AlbumDropDown
                            update={update}
                            setUpdate={setUpdate}
                            albumObj={obj}
                          />
                        </td> */}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default Album
