import React, { useContext, useEffect, useState } from 'react'
import './AlbumUploadGoogleDrive.css'
//import _ from 'lodash'
import deleteIcon from '../../Assets/deleteIcon.svg'
import { userAuthContext } from '../../Context/UserAuthContext'
import { dataContext } from '../../Context/DataContext'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { PopupWrapper } from '../../UI/PopupWrapper/PopupWrapper';
import ErrorPopup from '../../Components/PopUps/ErrorPopup/ErrorPopup'
import Lottie from '../../Assets/Animation/Loading'
function AlbumUploadGoogleDrive() {
  const [uploadedMedias, setUploadedMedias] = useState([])
  const [loading, setLoading] = useState(false)
  const [gdriveUrl, setGdriveUrl] = useState("")
  const [error, setError] = useState({ "errorStatus": false, errorMessage: "", header: "" })
  //const [folderData, setFolderData] = useState([])
  const [uploadedStateUpdate, setUploadedStateUpdate] = useState(false)
  const [projectName, setProjectName] = useState()
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext)
  const tokenData = userIdToken || currentUser?.accessToken
  const { studioId } = useContext(dataContext)
  const test = useParams()
  const shootmatesAlbumId = test?.shootmatesAlbumId
  const folderId = test?.folderId
  const [uploadStateStatus, setUploadStateStatus] = useState('inQue')
  useEffect(() => {
    if (studioId) {
      verifyUser()
      getUploadedMedias()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId])
  const getUploadedMedias = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_GET_ALL_MEDIAS_IN_FOLDER}/${studioId}/${shootmatesAlbumId}/${folderId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
      }
      const response = await axios(config)
      if (response.status === 200) {
        setUploadedMedias(response?.data?.data[0]?.paginatedMedia)
        setProjectName(response?.data?.data[0]?.albumName)
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }

  const publishToFaceDetectionTraining = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_PUBLISH_TO_TRAINING}/${shootmatesAlbumId}/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
      }
      const response = await axios(config)
      if (response.status === 200) {
        //setUploadedMedias(response?.data?.data?.sections)
        setLoading(false)
        alert('Trainning datas published')
      }
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }
  const publishGoogleDrive = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'post',
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/albumoprtn/publishgdrivefolder/${studioId}/${shootmatesAlbumId}/${folderId}`,
        data: {
          "gdriveUrl": gdriveUrl
        },
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
      }
      const response = await axios(config)
      if (response.status === 200) {
        setLoading(false)
        if (response?.data?.error) {
          setError({ errorMessage: response?.data?.msg, errorStatus: true, header: "Error" })
        }
        setError({ errorMessage: response?.data?.msg, errorStatus: true, header: "Message" })
      }
    } catch (error) {
      console.log('error', error)
      setError({ errorMessage: "Invalid Input", errorStatus: true, header: "Error" })
      setLoading(false)
    }
  }
  const handleGoogleDriveLink = (e) => {
    setGdriveUrl(e.target.value);
  };
  const handleDeleteUploadedMedias = async (mediaId, mediaUrl) => {
    try {
      let config = {
        method: 'patch',
        url: `${process.env.REACT_APP_PULL_MEDIA_IN_FOLDER}/${studioId}/${shootmatesAlbumId}/${folderId}/${mediaId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
        data: {
          mediaUrl: mediaUrl,
        },
      }

      const response = await axios(config)
      if (response.status === 200) {
        setUploadedStateUpdate(!uploadedStateUpdate)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  return (
    <div>
      {loading &&
        <>
          <Lottie />
        </>
      }
      {error.errorStatus === true &&
        <PopupWrapper visible={error.errorStatus}>
          <ErrorPopup error={error} setError={setError} />
        </PopupWrapper>
      }
      <div className="pl-[60px] pr-[30px] mt-[30px] mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[30px]">
          <div className="w-[20px] h-[20px] rounded-[6px] bg-[#EB35BF]"></div>
          <p className="font_medium_22">{projectName}</p>
        </div>

      </div>
      <div className="albumUpload-container pl-[75px] mb-[15px]">
        <div
          className="px-[16px] py-[8px] bg-[#EEF0F6] rounded-[6px]"
          onClick={() => {
            publishToFaceDetectionTraining()
          }}
        >
          <p className="font_medium_15 text-[#0064FF] pr-[15px]">
            Publish to face detection training
          </p>
        </div>
      </div>
      <div className="seperatorHorizontal" />
      <div className="pl-[60px] pr-[30px] mt-[30px] mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[30px]">
          <h2 className="font_medium_18">Paste the google drive url which is public</h2>
          <input type='text' onChange={handleGoogleDriveLink} className="w-[50%] h-[52px] px-[12px] py-[15px] bg-[#EEEEEE] rounded-[8px] text-[#0e7802]" />
          <div
            className="px-[16px] py-[8px] bg-[#EEF0F6] rounded-[6px]"
            onClick={() => {
              publishGoogleDrive()
            }}
          >
            <p className="font_medium_15 text-[#0064FF]">
              Publish & Upload
            </p>
          </div>
        </div>

      </div>
      <div className="seperatorHorizontal" />
      <div className="pl-[60px] pr-[30px] mt-[30px]">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-[16px] items-center ">
            <p className="font_medium_18 text-[#89909C]">Media Status</p>
            <button
              className={`px-[16px] py-[8px] rounded-[30px] font_medium_15 ${uploadStateStatus === 'uploaded'
                ? 'bg-[#0064FF] text-[#FFFFFF]'
                : 'bg-[#E6E8EB] text-[#828D97]'
                }`}
              onClick={() => {
                setUploadStateStatus('uploaded')
              }}
            >
              Uploaded
            </button>
          </div>

        </div>
        {uploadedMedias.length > 0 && uploadStateStatus === 'uploaded' ? (
          <>
            <div className="mx-1 my-5 bg-neutral-400 p-3 rounded-lg  ">
              <ul className="grid grid-cols-5 gap-3 mt-4 ">
                {uploadedMedias?.map((obj, index) => {
                  return (
                    <li key={obj?._id} className="relative">
                      <img className="" src={obj?.url} alt={obj?._id} />
                      <div
                        className="absolute w-[100%] h-[100%] bg-black top-0 left-0 opacity-0 hover:opacity-50 transition-all duration-[800ms] flex justify-center items-center"
                        onClick={() =>
                          handleDeleteUploadedMedias(obj?._id, obj?.url)
                        }
                      >
                        <img
                          src={deleteIcon}
                          alt=""
                          className="w-[50px] h-[50px] cursor-pointer "
                        />
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>{' '}
          </>
        ) : null}
      </div>
    </div>
  )
}

export default AlbumUploadGoogleDrive
