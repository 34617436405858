import React from 'react';
import './Pagination.css';

const Pagination = ({ limit, currentPage, setCurrentPage, dataLength }) => {
  const hasNextPage = dataLength > limit;
  const hasPrevPage = currentPage > 1;

  return (
    <div className="pagination-container">
      {hasPrevPage && (
        <button onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
      )}

      <ul className="page-numbers">
        <li
          className={currentPage === 1 ? 'active' : ''}
          onClick={() => setCurrentPage(1)}
        >
          1
        </li>
        {hasNextPage && (
          <li
            className={currentPage === 2 ? 'active' : ''}
            onClick={() => setCurrentPage(2)}
          >
            2
          </li>
        )}
        {/* You can add more page numbers as needed */}
      </ul>

      {hasNextPage && (
        <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
      )}
    </div>
  );
};

export default Pagination;
