import React from "react";

function ChoosePackageComponent({
  index,
  packages,
  selectedPlan,
  setSelectedPlan,
  selectedType,
  centerIndex,
}) {
  const handleClick = (type) => {
    if (type === "FLEXI") return;
    if (selectedPlan === packages?._id) {
      setSelectedPlan("");
      localStorage.removeItem("selectedPlan");
    } else {
      setSelectedPlan(packages?._id);
      localStorage.setItem("selectedPlan", packages?._id);
    }
  };
  const formatKey = (key) => {
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/Whatsapp/g, "Whats App")
      .replace(/Msg$/g, "Message")
      .replace(/^\w/, (c) => c.toUpperCase());
  };
  return (
    <div
      onClick={() => handleClick(packages?.packageType)}
      style={{
        background:
          selectedPlan == packages?._id
            ? packages?.packageType === "YEARLY"
              ? "linear-gradient(155deg, #11B981 2.08%, #2BA76B 97.58%)"
              : "linear-gradient(155deg, #8A56FF 2.08%, #3C77F6 97.58%)"
            : "#fff",
        color: selectedPlan == packages?._id ? "#fff" : "#111",
        border:
          selectedPlan == packages?._id
            ? "none"
            : "1px solid rgba(255, 255, 255, 0.20)",
      }}
      className={` 

        ${
          selectedType === "FLEXI"
            ? index >= centerIndex - 0 && index <= centerIndex + 2
              ? "opacity-100"
              : "opacity-20"
            : "opacity-100"
        }
        
       w-[100%] max-w-[280px]  cursor-pointer p-[20px] justify-start items-start flex flex-col rounded-[16px]  flex-shrink-0`}
    >
      <div className="w-[36px] h-[36px] flex-shrink-0 justify-center items-center flex rounded-full bg-[#F3F3F3]">
        {index === 0 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11 15H6L13 1V9H18L11 23V15Z"
              fill={packages?.packageType == "YEARLY" ? "#15BB82" : "#8A56FF"}
            />
          </svg>
        ) : index === 1 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.8939 13.553C21.0123 13.7901 21.0318 14.0646 20.948 14.316C20.8641 14.5675 20.6839 14.7754 20.4469 14.894L12.4469 18.894C12.3081 18.9634 12.1551 18.9995 11.9999 18.9995C11.8447 18.9995 11.6917 18.9634 11.5529 18.894L3.55291 14.894C3.32418 14.7707 3.15245 14.5631 3.07409 14.3154C2.99574 14.0676 3.01691 13.799 3.13312 13.5666C3.24934 13.3342 3.45147 13.1561 3.6967 13.0701C3.94193 12.9842 4.211 12.997 4.44691 13.106L11.9999 16.88L19.5539 13.105C19.791 12.9866 20.0655 12.9671 20.3169 13.051C20.5684 13.1348 20.7763 13.315 20.8949 13.552M12.0079 5C12.0452 5 12.0822 5.00233 12.1189 5.007L12.2299 5.027L12.3159 5.051L12.3279 5.057L12.3399 5.059L12.3689 5.073L12.4189 5.092L12.4349 5.101L12.4469 5.106L20.4469 9.106C20.6128 9.18914 20.7523 9.31679 20.8497 9.47468C20.9472 9.63256 20.9988 9.81445 20.9988 10C20.9988 10.1856 20.9472 10.3674 20.8497 10.5253C20.7523 10.6832 20.6128 10.8109 20.4469 10.894L12.4469 14.894C12.3081 14.9634 12.1551 14.9995 11.9999 14.9995C11.8447 14.9995 11.6917 14.9634 11.5529 14.894L3.55291 10.894C3.38703 10.8109 3.24755 10.6832 3.15007 10.5253C3.0526 10.3674 3.00098 10.1856 3.00098 10C3.00098 9.81445 3.0526 9.63256 3.15007 9.47468C3.24755 9.31679 3.38703 9.18914 3.55291 9.106L11.5529 5.106L11.5639 5.101L11.5819 5.091L11.6599 5.059L11.6709 5.057L11.6839 5.051L11.7699 5.027L11.8799 5.007L11.9359 5.002L12.0079 5Z"
              fill={packages?.packageType == "YEARLY" ? "#15BB82" : "#8A56FF"}
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.0831 10.5L21.2861 11.221C21.3603 11.2654 21.4216 11.3282 21.4643 11.4034C21.5069 11.4786 21.5293 11.5636 21.5293 11.65C21.5293 11.7364 21.5069 11.8214 21.4643 11.8965C21.4216 11.9717 21.3603 12.0346 21.2861 12.079L12.0001 17.649L2.7151 12.079C2.64095 12.0346 2.57956 11.9717 2.53695 11.8965C2.49433 11.8214 2.47192 11.7364 2.47192 11.65C2.47192 11.5636 2.49433 11.4786 2.53695 11.4034C2.57956 11.3282 2.64095 11.2654 2.7151 11.221L3.9171 10.5L12.0001 15.35L20.0831 10.5ZM20.0831 15.2L21.2861 15.921C21.3603 15.9654 21.4216 16.0282 21.4643 16.1034C21.5069 16.1786 21.5293 16.2636 21.5293 16.35C21.5293 16.4364 21.5069 16.5214 21.4643 16.5965C21.4216 16.6717 21.3603 16.7346 21.2861 16.779L12.5141 22.041C12.3588 22.134 12.1811 22.1832 12.0001 22.1832C11.8191 22.1832 11.6414 22.134 11.4861 22.041L2.7151 16.78C2.64095 16.7356 2.57956 16.6727 2.53695 16.5975C2.49433 16.5224 2.47192 16.4374 2.47192 16.351C2.47192 16.2646 2.49433 16.1796 2.53695 16.1044C2.57956 16.0292 2.64095 15.9664 2.7151 15.922L3.9171 15.201L12.0001 20.05L20.0831 15.2ZM12.5151 1.30899L21.2851 6.57099C21.3593 6.61538 21.4206 6.67823 21.4633 6.75342C21.5059 6.82861 21.5283 6.91356 21.5283 6.99999C21.5283 7.08641 21.5059 7.17136 21.4633 7.24655C21.4206 7.32174 21.3593 7.38459 21.2851 7.42899L12.0001 12.999L2.7151 7.42999C2.64095 7.38559 2.57956 7.32274 2.53695 7.24755C2.49433 7.17236 2.47192 7.08741 2.47192 7.00099C2.47192 6.91456 2.49433 6.82961 2.53695 6.75442C2.57956 6.67923 2.64095 6.61638 2.7151 6.57199L11.4851 1.30999C11.6406 1.21654 11.8187 1.16718 12.0001 1.16718C12.1815 1.16718 12.3596 1.21554 12.5151 1.30899Z"
              fill={packages?.packageType == "YEARLY" ? "#15BB82" : "#8A56FF"}
            />
          </svg>
        )}
      </div>
      <div>
        <p className="mt-[10px] planName">{packages?.packageName}</p>
        <p className="mt-[16px] planPrice">
          ₹ {packages?.price}{" "}
          <span className="text-[13px] font-[600]">
            For{" "}
            {`${
              packages?.storageTenureInMonth
                ? `${packages?.storageTenureInMonth} Months`
                : `${packages?.storageTenureInDays} Days`
            } `}
          </span>
        </p>
        <button
          className={`storageShowButton 
           
          mt-[13px]  ${
            selectedPlan == packages?._id
              ? "bg-[#fff] text-[#111]"
              : packages?.packageType == "YEARLY"
              ? "bg-[#14BB82] text-[#fff]"
              : "bg-[#8A56FF] text-[#fff]"
          } `}
        >
          {selectedType === "YEARLY"
            ? `OR Pay ₹${Math.round(packages?.price / 4)} / Quaterly`
            : typeof packages?.storageInGb == "number"
            ? `${packages?.storageInGb} GB Storage`
            : `${packages?.storageInGb}`}{" "}
        </button>
      </div>

      <div className="w-full border-t-[#F3F3F3] border-t-[1px] mt-[13px]">
        {/* <p className="packageExtracharge">Extra Charges</p> */}
        {selectedType === "YEARLY" && (
          <div className="flex gap-[10px] items-center justify-start my-[14px]">
            {selectedPlan == packages?._id ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  fill="#F6F6F6"
                />
                <path
                  d="M8.8167 12.9833C8.65003 12.9833 8.4917 12.9167 8.37503 12.8L6.0167 10.4417C5.77503 10.2 5.77503 9.8 6.0167 9.55833C6.25837 9.31666 6.65837 9.31666 6.90003 9.55833L8.8167 11.475L13.1 7.19166C13.3417 6.95 13.7417 6.95 13.9834 7.19166C14.225 7.43333 14.225 7.83333 13.9834 8.075L9.25837 12.8C9.1417 12.9167 8.98337 12.9833 8.8167 12.9833Z"
                  fill="#13BB82"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z"
                  fill="#13BB82"
                />
                <path
                  d="M8.8167 12.9833C8.65003 12.9833 8.4917 12.9166 8.37503 12.8L6.0167 10.4416C5.77503 10.2 5.77503 9.79996 6.0167 9.55829C6.25837 9.31663 6.65837 9.31663 6.90003 9.55829L8.8167 11.475L13.1 7.19163C13.3417 6.94996 13.7417 6.94996 13.9834 7.19163C14.225 7.43329 14.225 7.83329 13.9834 8.07496L9.25837 12.8C9.1417 12.9166 8.98337 12.9833 8.8167 12.9833Z"
                  fill="white"
                />
              </svg>
            )}
            <p>
              {typeof packages?.storageInGb == "number"
                ? `${packages?.storageInGb} GB`
                : packages?.storageInGb}{" "}
              storage for a year
            </p>
          </div>
        )}
        {packages?.services?.length > 0 &&
          packages?.services?.map((item, i) => (
            <div
              key={i}
              className="flex gap-[10px] items-center justify-start my-[14px]"
            >
              {item?.pricing === "free" ? (
                selectedPlan == packages?._id ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39762 14.6025 1.66666 10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                      fill="#F6F6F6"
                    />
                    <path
                      d="M8.8167 12.9833C8.65003 12.9833 8.4917 12.9167 8.37503 12.8L6.0167 10.4417C5.77503 10.2 5.77503 9.8 6.0167 9.55833C6.25837 9.31666 6.65837 9.31666 6.90003 9.55833L8.8167 11.475L13.1 7.19166C13.3417 6.95 13.7417 6.95 13.9834 7.19166C14.225 7.43333 14.225 7.83333 13.9834 8.075L9.25837 12.8C9.1417 12.9167 8.98337 12.9833 8.8167 12.9833Z"
                      fill="#13BB82"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z"
                      fill="#13BB82"
                    />
                    <path
                      d="M8.8167 12.9833C8.65003 12.9833 8.4917 12.9166 8.37503 12.8L6.0167 10.4416C5.77503 10.2 5.77503 9.79996 6.0167 9.55829C6.25837 9.31663 6.65837 9.31663 6.90003 9.55829L8.8167 11.475L13.1 7.19163C13.3417 6.94996 13.7417 6.94996 13.9834 7.19163C14.225 7.43329 14.225 7.83329 13.9834 8.07496L9.25837 12.8C9.1417 12.9166 8.98337 12.9833 8.8167 12.9833Z"
                      fill="white"
                    />
                  </svg>
                )
              ) : selectedPlan == packages?._id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332Z"
                    fill="#F6F6F6"
                  />
                  <path
                    d="M13.4286 10.5714H10.5714V13.4286C10.5714 13.7429 10.3143 14 10 14C9.68571 14 9.42857 13.7429 9.42857 13.4286V10.5714H6.57143C6.25714 10.5714 6 10.3143 6 10C6 9.68571 6.25714 9.42857 6.57143 9.42857H9.42857V6.57143C9.42857 6.25714 9.68571 6 10 6C10.3143 6 10.5714 6.25714 10.5714 6.57143V9.42857H13.4286C13.7429 9.42857 14 9.68571 14 10C14 10.3143 13.7429 10.5714 13.4286 10.5714Z"
                    fill="#161616"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332Z"
                    fill="#161616"
                  />
                  <path
                    d="M13.4286 10.5714H10.5714V13.4286C10.5714 13.7429 10.3143 14 10 14C9.68571 14 9.42857 13.7429 9.42857 13.4286V10.5714H6.57143C6.25714 10.5714 6 10.3143 6 10C6 9.68571 6.25714 9.42857 6.57143 9.42857H9.42857V6.57143C9.42857 6.25714 9.68571 6 10 6C10.3143 6 10.5714 6.25714 10.5714 6.57143V9.42857H13.4286C13.7429 9.42857 14 9.68571 14 10C14 10.3143 13.7429 10.5714 13.4286 10.5714Z"
                    fill="#F6F6F6"
                  />
                </svg>
              )}
              <p>
                {item?.name}
                <span
                  className={`${
                    selectedPlan == packages?._id
                      ? "text-[#fff]"
                      : item?.pricing === "free"
                      ? "text-[#13BB82]"
                      : "text-[#8A56FF]"
                  } ml-[8px]`}
                >
                  {" "}
                  {item?.pricing === "free"
                    ? "Free"
                    : `₹ ${item?.pricing}`}{" "}
                </span>{" "}
              </p>
            </div>
          ))}{" "}
        {packages?.features &&
          Object.entries(packages?.features).map(([key, value]) => (
            <div
              key={key}
              className="flex  gap-[10px] items-center justify-start my-[14px]"
            >
              <div className="flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6023 18.3334 9.99996C18.3334 5.39759 14.6025 1.66663 10.0001 1.66663C5.39771 1.66663 1.66675 5.39759 1.66675 9.99996C1.66675 14.6023 5.39771 18.3333 10.0001 18.3333Z"
                    fill="#9747FF"
                  />
                  <path
                    d="M8.8167 12.9833C8.65003 12.9833 8.4917 12.9166 8.37503 12.8L6.0167 10.4416C5.77503 10.2 5.77503 9.79996 6.0167 9.55829C6.25837 9.31663 6.65837 9.31663 6.90003 9.55829L8.8167 11.475L13.1 7.19163C13.3417 6.94996 13.7417 6.94996 13.9834 7.19163C14.225 7.43329 14.225 7.83329 13.9834 8.07496L9.25837 12.8C9.1417 12.9166 8.98337 12.9833 8.8167 12.9833Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p className="whitespace-nowrap text-[15px]">
                {formatKey(key)}
                <span className={`${"text-[#8A56FF]"} ml-[8px]`}>
                  {value}
                </span>{" "}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ChoosePackageComponent;
