import React from 'react'

function Loading({ loadingText }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-75 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg flex items-center space-x-2">
        <div className={`w-6 h-6 bg-[#177da6] rounded-full animate-bounce`}></div>
        <div className="text-gray-800">{loadingText}</div>
      </div>
    </div>
  );
};

export default Loading
