import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Lottie from '../../Assets/Animation/LoadingWhite'
import { dataContext } from '../../Context/DataContext'
import { userAuthContext } from '../../Context/UserAuthContext'
import { useParams } from "react-router-dom";
import './PaymentsDetails.css'

const PaymentsDetails = () => {
  const { projectId } = useParams();
 // const [loadingText,setLoadingText]=useState(null)
  const [selectedCategory, setSelectedCategory] = useState('PENDING');
  const [loading, setLoading] = useState(false)
  const { studioId } = useContext(dataContext)
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext)
  const tokenData = userIdToken || currentUser?.accessToken;
  const [update, setUpdate] = useState(false)
  const [paymentData, setPaymentData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    verifyUser()
    getPaymentData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, selectedCategory])

  const getPaymentData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesPayments/api/shootmates/payments/getpaymentlistingproject/${studioId}/${projectId}?paymentRequestQuery=${selectedCategory}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
      }
      const response = await axios(config)
      if (response.status === 201) {
        setPaymentData(response.data.projectData)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false)
    }
  }
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  return (
    <div className='flex flex-col'>
      <div className='payments-container'>
        <h2 className='text-2xl italic font-bold'>Payments Details Lists</h2>
      </div>
      <div className='mb-[10]'>
        <button
          className="ml-[10px] select-none rounded-lg bg-green-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          onClick={() => {
            setUpdate(!update)
          }}
        >
          Refresh
        </button>
        <button
          className="ml-[10px] select-none rounded-lg bg-red-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          onClick={() => {
            navigate(`/payments`)
          }}
        >
          Go back to payments
        </button>
      </div>
      <div className="relative overflow-x-auto ml-[10px]">
        <div className="flex items-center justify-center mt-4 mb-4">
          <p className="mr-2 text-sm">Sort By:</p>
          <select onChange={handleCategoryChange} className="block px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option value="PENDING">Payment Pending</option>
            <option value="COMPLETED">Payment Completed</option>
          </select>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 bg-[]">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Total Cost
              </th>
              <th scope="col" className="px-6 py-3">
                Transaction Id
              </th>
              <th scope="col" className="px-6 py-3">
                Payment Status
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                <Lottie loadingText={"Fetching data.."} />
              </> :
              paymentData && paymentData.length > 0 ? (
                paymentData.map((el) => (
                  <tr key={el.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {el?.purchasedItems?.albumName}
                    </th>
                    <td className="px-6 py-4">
                      {el?.purchasedItems?.totalPrice}
                    </td>
                    <td className="px-6 py-4">
                      {el?.purchasedItems?.merchantTransactionId}
                    </td>
                    <td className="px-6 py-4">
                      <span className=' bg-green-300'>{el?.purchasedItems?.paymentStatus}</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="px-6 py-4 text-center text-red-500 dark:text-red-400">
                    No data found
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PaymentsDetails
