import React from 'react'
import { PopupWrapper } from '../../../UI/PopupWrapper/PopupWrapper'
import './GalleryEditForm.css'
import ErrorPopup from '../../PopUps/ErrorPopup/ErrorPopup'

export default function GalleryEditForm({
    initialFormData,
    setFormData,
    galleryPopupEdit,
    visible,
    setpopUp,
    formData,
    formErrors,
    handleInputChange,
    handleAlbumGalleryImageChange,
    inputRefAlbumCoverImage,
    albumCoverImageLoading,
    handleSubmit,
    removeAlbumCoverImage,
    error,
    setError }) {
    return (
        <PopupWrapper visible={visible} setpopUp={setpopUp}>
            {error.errorStatus === true &&
                <ErrorPopup error={error} setError={setError} />
            }
            <div className='w-[60%] h-[80%] overflow-y-scroll  bg-[#ffff]'>
                <div className='heading p-[24px] flex flex-row justify-between'>
                    <h3>Edit Details of Album Gallery</h3>
                    <button onClick={() => {
                        setpopUp(false)
                        setFormData(initialFormData)
                        setpopUp({ "visible": false, "galleryId": "" })
                    }}>Close</button>
                </div>
                <div className='ml-4 mt-4 md:ml-4 lg:ml-10'>
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="coverImage">
                        Gallery Name
                    </label>
                    <input
                        type="text"
                        name="title"
                        placeholder="Enter Gallery Name"
                        className={`border rounded p-4 w-10/12 ${formErrors?.title ? 'border-red-500' : ''}`}
                        value={formData?.title}
                        onChange={handleInputChange}
                    />
                    {formErrors?.title && <p className="text-red-500">{formErrors?.title}</p>}
                    <br />
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="coverImage">
                        Gallery Description
                    </label>
                    <input
                        type="text"
                        name="description"
                        placeholder="Enter Gallery Description"
                        className={`border rounded p-4 w-10/12 ${formErrors.description ? 'border-red-500' : ''}`}
                        value={formData.description}
                        onChange={handleInputChange}
                    />
                    {formErrors?.description && <p className="text-red-500">{formErrors?.description}</p>}
                    <br />
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="coverImage">
                            Gallery Cover Image:
                        </label>
                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleAlbumGalleryImageChange}
                                className="hidden"
                                id="coverImage"
                                name="coverImage"
                                ref={inputRefAlbumCoverImage}
                            />
                            <button
                                onClick={() => inputRefAlbumCoverImage.current.click()}
                                className="py-2 px-3 leading-tight focus:outline-none focus:shadow-outline cursor-pointer bg-blue-500 text-white font-semibold rounded hover:bg-blue-600 transition duration-300 ease-in-out"
                            >
                                {formData?.coverImage ? "Change Album Cover" : "Choose Album Cover"}
                            </button>

                            {albumCoverImageLoading && (
                                <p className="text-sm text-gray-500 mt-2">Uploading...</p>
                            )}
                        </div>
                        {formData?.coverImage && (
                            <div className="mt-2">
                                <div
                                    key="albumCover"
                                    className="flex items-center bg-gray-100 p-2 rounded-lg"
                                >
                                    <img
                                        src={formData?.coverImage}
                                        alt="coverImage"
                                        className="w-20 h-20 object-cover rounded-lg mr-2"
                                    />
                                    <span className="text-green-500">&#10003; Uploaded</span>
                                    <div>
                                        <button
                                            onClick={removeAlbumCoverImage}
                                            className="text-red-500 hover:text-red-700 transition duration-300 ease-in-out"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {formErrors.coverImage && (
                            <p className="text-red-500 mt-2">{formErrors?.coverImage}</p>
                        )}
                    </div>

                    <div class="text-center ">
                        <button class="mx-auto bg-blue-500 mb-4 text-white font-bold py-2 px-2 rounded" onClick={() => { handleSubmit(galleryPopupEdit?.folderId) }}>Edit Gallery</button>
                    </div>

                </div>
            </div >
        </ PopupWrapper >
    )
}
