import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Slider from "../Slider/Slider";
import { userAuthContext } from "../../Context/UserAuthContext";
import Loading from "../../Assets/Animation/Loading";

function PaidPhotosHistory({
  studioId,
  memshotAlbumId,
  projectId,
  setPaidPhotosSection,
  slugId,
}) {
  const [refreshing, setRefreshing] = useState(true);
  const [paymentHistories, setPaymentHistories] = useState([]);
  const [filterOption, setFilterOption] = useState("ALL");
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [pricePopupOpen, setPricePopupOpen] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [expired, setExpired] = useState(null);
  const [newPrice, setNewPrice] = useState("");
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    const fetchRegisteredUsers = async () => {
      try {
        await verifyUser();
        const config = {
          method: "get",
          url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/paidPhotos/paidpaymenthistory/${projectId}/${studioId}/${memshotAlbumId}`,
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios(config);
        setPaymentHistories(response?.data?.data[0]);
        console.log(
          "response?.data?.data[0]",
          response?.data?.data[0]?.paidPhotoFee
        );

        setCurrentPrice(response?.data?.data[0]?.paidPhotoFee);
        setExpired(response?.data?.data[0]?.isExpired);
        setRefreshing(false);
      } catch (error) {
        console.error("Error fetching registered users:", error);
        setRefreshing(false);
      }
    };
    fetchRegisteredUsers();
  }, [refreshing, filterOption]);

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(`https://app.memshots.com/faces/${link}`);
  };

  const handleViewFaces = (user) => {
    setSelectedUser(user);
    setCurrentImageIndex(0);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedUser.faceUrl.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < selectedUser.faceUrl.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handleCloseSlider = () => {
    setSelectedUser(null);
  };

  const getPaymentStatus = (status) => {
    if (status === "PAYMENT_SUCCESS")
      return { text: "Payment Completed", color: "text-green-500" };
    if (status === "PAYMENT_ERROR")
      return { text: "Payment Failed", color: "text-red-500" };
    return { text: "Payment Pending", color: "text-yellow-500" };
  };

  const openOrderLink = (order) => {
    const link = `https://app.memshots.com/${slugId}/purchasedphotos/${order?.orderId}`;
    window.open(link, "_blank");
  };

  const handleSetPrice = async () => {
    try {
      setRefreshing(true);
      const config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/paidPhotos/setPrice/${projectId}/${studioId}/${memshotAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          amount: newPrice,
        },
      };
      const response = await axios(config);
      setPricePopupOpen(false);
      setRefreshing(false);
    } catch (error) {
      console.log("error", error);
      setRefreshing(false);
      setPricePopupOpen(false);
    }
  };

  const handleChangeExpiry = async () => {
    try {
      setRefreshing(true);
      const config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/paidPhotos/changeexpiryStatus/${projectId}/${studioId}/${memshotAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setRefreshing(false);
    } catch (error) {
      console.log("error", error);
      setRefreshing(false);
    }
  };

  return (
    <div className="bg-white shadow-lg max-w-6xl w-full h-[600px] overflow-y-auto rounded-lg mt-10">
      {selectedUser && (
        <Slider
          memshotAlbumId={memshotAlbumId}
          images={selectedUser?.faceUrl}
          currentIndex={currentImageIndex}
          onPrev={handlePreviousImage}
          onNext={handleNextImage}
          onClose={handleCloseSlider}
        />
      )}
      <div className="flex justify-between items-center p-6 bg-gradient-to-r from-blue-500 to-purple-500 rounded-t-lg shadow-md sticky top-0">
        <h1 className="text-2xl font-semibold text-white">Payment History</h1>
        <div className="flex items-center space-x-4">
          <span className="text-white text-sm font-medium">
            {`Current Price Per Photo - ${
              refreshing
                ? "Loading..."
                : currentPrice || "Price not set ! Please set the price"
            }`}
          </span>
          <button
            onClick={() => setPricePopupOpen(true)}
            className="px-4 py-2 text-sm font-medium rounded-full bg-green-500 hover:bg-green-600 text-white transition"
          >
            Set Price
          </button>
          <span className="text-white text-sm font-medium">
            {`Order Expiry Status- ${
              refreshing
                ? "Loading..."
                : expired === true
                ? "Expired"
                : "Active"
            }`}
          </span>
          <button
            onClick={handleChangeExpiry}
            className="px-4 py-2 text-sm font-medium rounded-full bg-yellow-500 hover:bg-yellow-600 text-white transition"
          >
            Change Expiry
          </button>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-white text-sm font-medium">
            {`Total orders: ${
              refreshing
                ? "Loading..."
                : paymentHistories?.paidPhotosOrders?.length || 0
            }`}
          </span>
          <button
            onClick={() => setRefreshing(!refreshing)}
            className={`px-4 py-2 text-sm font-medium rounded-full text-white transition ${
              refreshing ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
            }`}
            disabled={refreshing}
          >
            {refreshing ? "Refreshing..." : "Refresh"}
          </button>
          <button
            onClick={() => setPaidPhotosSection(false)}
            className="px-4 py-2 text-sm font-medium rounded-full bg-red-500 hover:bg-red-600 text-white transition"
          >
            Close
          </button>
        </div>
      </div>
      {pricePopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Set Price</h2>
            <input
              type="number"
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
              min="1"
              placeholder="Enter new price"
              className="w-full p-2 border rounded-md mb-4"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setPricePopupOpen(false)}
                className="px-4 py-2 text-sm font-medium rounded-full bg-gray-500 text-white transition hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleSetPrice}
                className="px-4 py-2 text-sm font-medium rounded-full bg-blue-500 text-white transition hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {refreshing ? (
        <Loading />
      ) : (
        <div className="p-6">
          <table className="w-full bg-gray-50 shadow-md rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gradient-to-r from-purple-600 to-blue-500 text-white">
                {[
                  "No",
                  "Name",
                  "Email/Number",
                  "WhatsApp",
                  "Photos",
                  "Amount",
                  "Status",
                  "Order Link",
                ].map((header) => (
                  <th
                    key={header}
                    className="py-3 px-5 font-semibold text-center"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paymentHistories?.paidPhotosOrders?.length > 0 ? (
                paymentHistories.paidPhotosOrders.map((order, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-100 transition-colors"
                  >
                    <td className="py-3 px-5 text-center">{index + 1}</td>
                    <td className="py-3 px-5 text-center">{order?.name}</td>
                    <td className="py-3 px-5 text-center">{order?.email}</td>
                    <td className="py-3 px-5 text-center">
                      {order.whatsAppNo}
                    </td>
                    <td className="py-3 px-5 text-center">
                      {order?.photosRequested?.length}
                    </td>
                    <td className="py-3 px-5 text-center">
                      {order?.totalAmount}
                    </td>
                    <td
                      className={`py-3 px-5 text-center font-medium ${
                        getPaymentStatus(order?.paymentPaidStatus).color
                      }`}
                    >
                      {getPaymentStatus(order?.paymentPaidStatus).text}
                    </td>
                    <td className="py-3 px-5 text-center">
                      <button
                        onClick={() => openOrderLink(order)}
                        className="text-blue-600 hover:underline"
                      >
                        Open
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center text-gray-500 py-4">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default PaidPhotosHistory;
