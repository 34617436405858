import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { userAuthContext } from "./UserAuthContext";
import Lottie from "../Assets/Animation/Loading";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { ref } from "firebase/storage";
//import { useNavigate } from 'react-router-dom'

export const dataContext = createContext();

export function DataContextProvider({ children }) {
  // const navigate = useNavigate()

  const { userIdToken, currentUser, loginRefresh, handleGetStudioRoleCheck } =
    useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [studioRole, setStudioRole] = useState([]);
  const [studioRoleLoading, setStudioRoleLoading] = useState(false);
  const [roleSelected, setRoleSelected] = useState({});
  const [studioDetails, setStudioDetails] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [studioId, setStudioId] = useState(
    () => parseInt(localStorage.getItem("studioId")) || null
  ); // Retrieve studioId from local storage
  const [invCoverImage, setInvCoverImage] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [thanksImage, setThanksImage] = useState({});
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paySelResponse, setPaySelResponse] = useState(null);
  const [activePackages, setActivePackages] = useState({});
  const [storageInfo, setStorageInfo] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projectData, setProjectDatas] = useState([]);
  const [errorCatch, setErrorCatch] = useState("");
  const [flexiPackageDetails, setFlexiPackageDetails] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(() => {
    return localStorage.getItem("selectedPlan") || "";
  });
  const [selectedPackage, setSelectedPackage] = useState(null);
  const navigate = useNavigate();
  const [paymentPlan, setPaymentPlan] = useState("ONETIME");
  const [payPlanError, setPayPlanError] = useState("");

  const handlepaymentPlanChange = (event) => {
    setPaymentPlan(event.target.value?.toUpperCase());
    setPayPlanError("");
  };

  useEffect(() => {
    const foundPackage = packages.find((pkg) => pkg._id == selectedPlan);
    setSelectedPackage(foundPackage || null);
  }, [selectedPlan, packages]);
  useEffect(() => {
    getStudioRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (!isEmpty(roleSelected)) {
      getActivePackages(roleSelected?.studioId);
      // getActiveFlexiPackages
      // getStudioStorageOfAService(roleSelected?.studioId);
    }
  }, [roleSelected, refresh]);

  useEffect(() => {
    if (!isEmpty(roleSelected) && activePackages?.activePackage === true) {
      getStudioStorage(
        roleSelected?.studioId,
        activePackages?.packageDetails?.packageType
      );
      // getStudioStorageOfAService(roleSelected?.studioId);
    }
  }, [roleSelected, refresh, activePackages]);

  // useEffect(() => {
  //   if (studioRole) {
  //     handleGetStudioRoleCheck(studioRole);
  //   }
  // }, [loginRefresh]);

  const getStudioRole = async () => {
    try {
      setStudioRoleLoading(true);
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_STUDIO_ROLES}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setStudioRoleLoading(false);
        setStudioRole(response.data.data.studioRolesAndAccess);
        if (studioId) {
          const role = response.data.data.studioRolesAndAccess?.find(
            (el) => el?.studioId === studioId
          );
          setRoleSelected(role);
          setStudioId(parseInt(role?.studioId));
          localStorage.setItem("studioId", role?.studioId);
          getStudioDetails(role?.studioId);
        } else {
          setRoleSelected(response.data.data.studioRolesAndAccess[0]);
          const selectedStudioId =
            response.data.data.studioRolesAndAccess[0]?.studioId;
          setStudioId(parseInt(selectedStudioId));
          getStudioDetails(selectedStudioId);
          localStorage.setItem("studioId", selectedStudioId);
        }
      } else {
        setStudioRoleLoading(false);
        setStudioRole([]);
        setRoleSelected({});
      }
      return response.data.data.studioRolesAndAccess;
    } catch (e) {
      setStudioRoleLoading(false);
      console.log("error", e);
    }
  };
  const getStudioDetails = async (studioId) => {
    try {
      setStudioRoleLoading(true);
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/studioprofile/${studioId}/studioMetadata.json`,
      };
      const response = await axios(config);
      setStudioDetails(response?.data);
      setStudioRoleLoading(false);
    } catch (e) {
      setStudioRoleLoading(false);
      console.log("error", e);
    }
  };

  useEffect(() => {
    setStudioId(parseInt(roleSelected?.studioId));
    parseInt(localStorage.setItem("studioId", roleSelected?.studioId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleSelected]);

  // Get packages
  const getPackages = async () => {
    setLoading(true);
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/packages`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setPackages(response?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // Active packages

  const getActivePackages = async (studioId) => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/packagedetails/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setActivePackages(response?.data);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  const getActiveFlexiPackages = async (studioId) => {
    setLoading(true);
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/flexiservices/${studioId}?packageType=FLEXI`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setFlexiPackageDetails(response?.data?.data);
      setLoading(false);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // subscriptions

  const getSubscriptions = async (studioId, paymentPlan, status) => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/subscriptions/${studioId}?paymentPlan=${paymentPlan}&status=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setSubscriptions(response?.data?.data);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // Invoices

  const getInvoices = async (studioId, subscriptionId, status) => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/invoices/${studioId}/${subscriptionId}?status=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setInvoices(response?.data?.data);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // Get Studio Storage

  const getStudioStorage = async (studioId, packageType) => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/totalstorage/${studioId}?packageType=${packageType}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setStorageInfo(response?.data);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // package Details

  const getPackageDetails = async (studioId) => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/packagedetails/${studioId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      console.log(response);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // Get  Studio Storage Of A Service

  const getStudioStorageOfAService = async (studioId, albumId) => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/totalstorage/${studioId}/${albumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      return response;
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // flexiPackagePaymentInitialise

  const flexiPackagePaymentInitialise = async (
    studioId,
    eventId,
    invoiceId,
    albumId
  ) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/initialise_paymentflexi/invoice/${studioId}/${eventId}/${invoiceId}/${albumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status == "success") {
        let paymentRedirectUrl =
          response?.data?.paymentRedirectUrl ||
          `https://www.memshots.com/thirdpartyPayment`;
        let url = response?.data?.paymentPayload?.short_url
          ? response?.data?.paymentPayload?.short_url
          : response?.data?.paymentPayload?.data?.instrumentResponse
              ?.redirectInfo?.url;
        handleRedirectToLink(url, paymentRedirectUrl);
      }
      setPaymentPlan("");
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // flexi package Payment
  const flexiPackagePayment = async (
    studioId,
    albumId,
    packageCode,
    eventId,
    paymentPlan
  ) => {
    setLoading(true);

    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/buyflexi/${studioId}/${albumId}?packageCode=${encodeURIComponent(
          packageCode
        )}&paymentPlan=${paymentPlan || "ONETIME"}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      // setPaySelResponse(response?.data);
      if (response?.data?.error === true) {
        setLoading(false);
        return { errorMsg: response?.data?.msg || "Something went wrong" };
      }

      if (paymentPlan === "PAYLATER") {
        setRefresh(!refresh);
        setPaymentPlan("");
        return;
      }

      if (response?.status === 201) {
        flexiPackagePaymentInitialise(
          studioId,
          eventId,
          response?.data?.invoiceId,
          albumId
        );
        setSelectedPlan("");
        localStorage.removeItem("selectedPlan");
      }
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // Package Payment
  const PackagePayment = async (
    studioId,
    subscriptionId,
    invoiceId,
    packageType
  ) => {
    setLoading(true);

    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/initialise_payment/invoice/${studioId}/${subscriptionId}/${invoiceId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);

      if (response?.data?.status == "success") {
        if (packageType == "TRIAL") {
          setRefresh(!refresh);
          return navigate(`/Event`);
        } else {
          let paymentRedirectUrl =
            response?.data?.paymentRedirectUrl ||
            `https://www.memshots.com/thirdpartyPayment`;
          let url = response?.data?.paymentPayload?.short_url
            ? response?.data?.paymentPayload?.short_url
            : response?.data?.paymentPayload?.data?.instrumentResponse
                ?.redirectInfo?.url;
          handleRedirectToLink(url, paymentRedirectUrl);
        }
      }
      setPaySelResponse("");
      setSelectedPlan("");
      localStorage.removeItem("selectedPlan");
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  // package selection
  const PackageSelection = async (
    packageId,
    studioId,
    paymentPlan,
    packageType
  ) => {
    setLoading(true);
    try {
      let config = {
        method: "post",
        url: `https://asia-south1-shootmates.cloudfunctions.net/packagesAndSubscriptions/api/shootmates/packagesandsubs/selectpackages/${packageId}/${studioId}?paymentPlan=${paymentPlan}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);

      if (response?.data?.error === true) {
        setLoading(false);
        return setPaySelResponse(response?.data?.msg);
      }
      if (response?.status === 201) {
        PackagePayment(
          response?.data?.studioId,
          response?.data?.subscriptionId,
          response?.data?.invoiceId,
          packageType
        );
      }
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  function handleRedirectToLink(link, paymentRedirectUrl) {
    const form = document.createElement("form");
    form.method = "get";
    form.action = paymentRedirectUrl;

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "gatewayresp";
    input.id = "gatewayresp";
    input.value = link;

    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
  }
  const bytesToHumanReadable = (bytes) => {
    if (bytes >= 1024 ** 3) {
      return `${(bytes / 1024 ** 3).toFixed(2)} GB`; // Use binary conversion (1 GB = 1024^3 bytes)
    }
    return `${(bytes / 1024 ** 2).toFixed(2)} MB`; // Use binary conversion (1 MB = 1024^2 bytes)
  };

  const bytesToGB = (bytes) => bytes / 1024 ** 3;

  const totalStorageGb =
    activePackages?.packageDetails?.storageInGb === "unlimited"
      ? null
      : parseFloat(activePackages?.packageDetails?.storageInGb || 0);

  const usedPercentage =
    totalStorageGb && totalStorageGb > 0
      ? (
          (bytesToGB(storageInfo?.totalMediaSize || 0) / totalStorageGb) *
          100
        ).toFixed(2)
      : null;

  const usedSize = bytesToHumanReadable(storageInfo?.totalMediaSize || 0);

  const formatDate = (data) => {
    const date = new Date(data);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const filteredProjects = projectData?.filter(
    (obj) =>
      searchTerm === "" ||
      obj.leadName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <dataContext.Provider
      value={{
        usedSize,
        getStudioDetails,
        searchTerm,
        filteredProjects,
        setSearchTerm,
        studioId,
        studioRole,
        setRoleSelected,
        studioRoleLoading,
        roleSelected,
        getStudioRole,
        refresh,
        setRefresh,
        invCoverImage,
        setInvCoverImage,
        uploadedImages,
        setUploadedImages,
        thanksImage,
        setThanksImage,
        studioDetails,
        studioRole,
        getStudioRole,
        getPackages,
        packages,
        loading,
        selectedPlan,
        setSelectedPlan,
        selectedPackage,
        PackageSelection,
        paySelResponse,
        PackagePayment,
        getActivePackages,
        activePackages,
        getSubscriptions,
        storageInfo,
        usedPercentage,
        getStudioStorageOfAService,
        getPackageDetails,
        subscriptions,
        formatDate,
        bytesToGB,
        getInvoices,
        invoices,
        flexiPackagePayment,
        paySelResponse,
        PackagePayment,
        setPaySelResponse,
        setLoading,
        projectData,
        setProjectDatas,
        totalStorageGb,
        errorCatch,
        setErrorCatch,
        paymentPlan,
        setPaymentPlan,
        handlepaymentPlanChange,
        payPlanError,
        setPayPlanError,
        getActiveFlexiPackages,
        flexiPackageDetails,
        flexiPackagePaymentInitialise,
      }}
    >
      {studioRoleLoading ? <Lottie /> : children}
    </dataContext.Provider>
  );
}
