import React, { useState, useEffect } from "react";

const StudioLogo = ({ studioId, fallbackUrl }) => {
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    const extensions = ["png", "jpeg", "jpg"];
    let found = false;

    const checkLogo = async () => {
      for (let ext of extensions) {
        // const url = `https://storage.googleapis.com/studioprofile/${studioId}/vcd.${ext}`;
        const url = `https://storage.googleapis.com/studioprofile/${studioId}/logo.${ext}`;
        try {
          const response = await fetch(url);
          if (response.ok) {
            setLogoUrl(url);
            found = true;
            break;
          }
        } catch (error) {
          console.error(`Error fetching logo with .${ext} extension`, error);
        }
      }
      if (!found) {
        setLogoUrl(fallbackUrl);
      }
    };

    checkLogo();
  }, [studioId, fallbackUrl]);

  return (
    <img
      src={logoUrl || fallbackUrl}
      alt="Studio Logo"
      className="w-[24px] h-[24px] object-cover rounded-full"
    />
  );
};

export default StudioLogo;
