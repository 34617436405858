import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { userAuthContext } from "../../Context/UserAuthContext";
import { useParams } from "react-router-dom";
import Loading from "../../Assets/Animation/Loading";
import styles from "./PortfolioPaymentPage.module.css";
import successTick from "../../Assets/successTick.svg";
import failedIcon from "../../Assets/paymentFailed.svg";

export default function PortfolioPaymentPage() {
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [dataLoading, setDataLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (currentUser) {
      checkPaymentStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const { requestId } = useParams();

  const checkPaymentStatus = async () => {
    setDataLoading(true);
    try {
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_NOSTUDIO_PAYMENT_STATUS}/${requestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios(config);
      setPaymentData(data);
      setDataLoading(false);
    } catch (e) {
      setDataLoading(false);
      console.log(e);
    }
  };
  const handleNoStudioPayment = async () => {
    try {
      const config = {
        method: "POST",
        url: `${process.env.REACT_APP_NOSTUDIO_PORTFOLIO_PAYMENT}/${requestId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const responseFromPaymentStatus = await axios(config);
      const url =
        responseFromPaymentStatus?.data?.paymentPayload?.data
          ?.instrumentResponse?.redirectInfo?.url;
      if (url) {
        const form = document.createElement("form");
        form.method = "get";
        form.action = "https://www.shootmates.com/thirdpartyPayment";
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "gatewayresp";
        input.id = "gatewayresp";
        input.value = url;
        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={styles.portfolioPayment}>
      {dataLoading ? (
        <Loading />
      ) : (
        <div className="w-full min-h-[60vh] gap-[10px] flex flex-col justify-center items-center ">
          <img
            src={
              paymentData?.paymentStatus === "PAYMENT_SUCCESS"
                ? successTick
                : failedIcon
            }
            alt=""
            className="w-[30px] h-[30px]"
          />
          <h1>
            {paymentData?.paymentStatus === "PAYMENT_SUCCESS"
              ? "Your request and payment have been successfully processed. We'll get back to you soon."
              : "Your request has been successfully submitted, but the payment is pending. Please try the payment again."}
          </h1>
          {paymentData?.paymentStatus !== "PAYMENT_SUCCESS" && (
            <button className={styles.button} onClick={handleNoStudioPayment}>
              Retry Payment
            </button>
          )}
        </div>
      )}
    </div>
  );
}
