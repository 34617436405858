import React from 'react';

const MessagePopupAlbum = ({ messagePopup, setMessagePopup, publishCrmAlbum }) => {
  const isVisible = messagePopup?.visible
  const type = messagePopup?.type
  const message = messagePopup?.message
  const getIconClass = () => {
    return type === 'success' ? 'text-green-500' : 'text-red-500';
  };
  const onClose = () => {
    setMessagePopup({
      "visible": false,
      "type": "",
      "message": ""
    })
  }
  return (
    <div className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-75 z-50 ${isVisible ? 'block' : 'hidden'}`}>
      <div className="bg-white p-4 rounded-lg shadow-lg flex items-center space-x-2">
        <div className="w-6 h-6 bg-blue-500 rounded-full ">
          <div className={getIconClass()}>
            {type === 'success' ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-25 w-25 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-25 w-25 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            )}
          </div>
        </div>
        <div className="text-gray-800">{message}</div>
        {
          messagePopup?.republish &&
          <button className="text-sm text-white-500 hover:underline bg-teal-600 rounded-full py-2 px-4" onClick={() => {
            publishCrmAlbum("PUBLISHED")
            onClose()
          }}>Republish</button>
        }
        <button className="text-sm text-white-500 hover:underline bg-[#2c899e] rounded-full py-2 px-4" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};



export default MessagePopupAlbum;
