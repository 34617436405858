import React, { useContext, useEffect, useState } from "react";
import { PopupWrapperAlbum } from "../../UI/PopupWrapperAlbum/PopupWrapperAlbum";
import "./AlbumCreateForm.css";
import { dataContext } from "../../Context/DataContext";
import Lottie from "../../Assets/Animation/LoadingWhite";
import { userAuthContext } from "../../Context/UserAuthContext";
import _ from "lodash";
import ErrorPopup from "../PopUps/ErrorPopup/ErrorPopup";
import MessagePopup from "../PopUps/MessagePopup/MessagePopup";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
import axios from "axios";
import DynamicAlbumTemplate from "./DynamicTemplateForm/DynamicTemplateForm";
function AlbumEditForm({ visible, setAlbum, projectId, albumId }) {
  const [messagePopup, setMessagePopup] = useState({
    visible: false,
    type: "",
    message: "",
  });
  const initialValues = {
    albumName: null,
    templateName: null,
    templateType: null,
    albumTemplateId: null,
  };
  const { studioId } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [wishesOptions, setWishesOptions] = useState([]);
  const [loadingText, setLoadingText] = useState("Loading Album Templates");
  const [pickedTemplate, setPickedTemplate] = useState({});
  const [albumData, setAlbumData] = useState([]);
  //const [curretAlbumTemplateData, setCurretAlbumTemplateData] = useState([])
  const [error, setError] = useState({
    errorStatus: false,
    errorMessage: "",
    header: "",
  });
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    getWishesTemplates();
    getAlbums();
    getCurrentAlbumsTemplatesData();
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getWishesTemplates = async () => {
    try {
      let config = {
        method: "get",
        url: `https://storage.googleapis.com/memshotalbum_templates/WISHES_CARD/wishesCardTemplate.json`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setWishesOptions(response?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getAlbums = async () => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/crmalbums/templates?templateType=ALBUM&platformType=SHOOTMATES&eventType=WEDDING`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumData(response?.data?.templates);
        //setPickedTemplate()
        setLoading1(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading1(false);
    }
  };
  const getCurrentAlbumsTemplatesData = async () => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/getAlbumTemplatesData/${projectId}/${studioId}/${albumId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/getAlbumTemplatesData/${projectId}/${studioId}/${albumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        //setCurretAlbumTemplateData(response?.data?.data)
        setPickedTemplate(response?.data?.data);
        setFormValues(response?.data?.data);
        setLoading2(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading2(false);
    }
  };
  const publishCrmAlbumTemplate = async () => {
    try {
      setLoading1(true);
      verifyUser();
      let config = {
        method: "patch",
        url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${albumId}`,
        // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${albumId}`,
        data: formValues,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoadingText("Album Template is updating...");
        setLoading1(false);
        setMessagePopup({
          visible: true,
          type: "success",
          message: response?.data?.msg,
        });
      } else {
        setLoading1(false);
        setMessagePopup({
          visible: true,
          type: "error",
          message: "Please try again..",
        });
      }
    } catch (error) {
      setLoading1(false);
      console.log("error", error);
    }
  };
  return (
    <PopupWrapperAlbum visible={visible}>
      {loading1 || loading2 ? (
        <Lottie loadingText={loadingText} />
      ) : (
        <>
          {error.errorStatus === true && (
            <ErrorPopup error={error} setError={setError} />
          )}
          {messagePopup?.visible && (
            <MessagePopup
              messagePopup={messagePopup}
              setMessagePopup={setMessagePopup}
            />
          )}
          <div className="AlbumCreateFormContainer  mt-[78px] min-w-[768px]">
            <div className="flex justify-between p-[24px] items-center">
              <p className="font_semibold_20 text-[#161616]">
                Edit Album Template
              </p>
              <img
                src={popupsCloseIcon}
                alt=""
                onClick={() => {
                  setAlbum(false);
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="workflowLeadAddAlbum-grid">
              <div className="row3"></div>
              <div className="row3">
                <p>
                  Switch Template:<span className="text-red-600">*</span>
                </p>
                <select
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      templateName: albumData[e.target.value].templateName,
                      albumTemplateId: albumData[e.target.value]._id,
                      templateType: albumData[e.target.value].templateType,
                    });
                    setPickedTemplate(albumData[e.target.value]);
                  }}
                >
                  <option value="">--Please choose a template--</option>
                  {albumData &&
                    albumData.length > 0 &&
                    albumData.map((obj, index) => {
                      return (
                        <option
                          key={index}
                          value={index}
                          selected={
                            pickedTemplate?.albumTemplateId === obj?._id
                              ? true
                              : false
                          }
                        >
                          {obj?.templateName}
                        </option>
                      );
                    })}
                </select>
              </div>
              {!_.isEmpty(pickedTemplate) && (
                <DynamicAlbumTemplate
                  pickedTemplate={pickedTemplate}
                  setFormValues={setFormValues}
                  wishesOptions={wishesOptions}
                />
              )}

              <div className="row5 flex flex-row mt-[12px] gap-x-[12px]">
                <button
                  className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
                  onClick={() => {
                    setAlbum(false);
                  }}
                >
                  Cancel
                </button>
                {loading1 || loading2 ? (
                  <button className="px-6 py-2 text-white rounded-full bg-[#1b2835] focus:outline-none">
                    Loading
                  </button>
                ) : (
                  <button
                    className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
                    onClick={publishCrmAlbumTemplate}
                  >
                    Edit Album Template
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </PopupWrapperAlbum>
  );
}

export default AlbumEditForm;
