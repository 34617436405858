import React, { useEffect, useRef, useState } from "react";
import Delete from "../Delete/Delete";
import "./ClientDropDown.css";
//import dropdownimg from '../../Assets/dropdown.svg'
import ClientForm from "../Forms/ClientForm/ClientForm";
import ClientViewPopup from "../ClientViewPopup/ClientViewPopup";
import eventDetailDotsIcon from "../../Assets/eventDetailDotsIcon.svg";
import dropDownEditIcon from '../../Assets/dropDownEditIcon.svg'
import dropDownDeleteIcon from '../../Assets/dropDownDeleteIcon.svg'
import dropDownViewIcon from '../../Assets/dropDownViewIcon.svg'


function ClientDropDown({ update, setUpdate, clientObj }) {
  const [dropdown, setDropdown] = useState(false);
  const [remove, setRemove] = useState(false);
  const [clientForm, setClientForm] = useState(false);
  const [view, setView] = useState(false);
  const modalRef = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);
  return (
    <>
      {" "}
      {clientForm && (
        <ClientForm
          update={update}
          setUpdate={setUpdate}
          visible={clientForm}
          setpopUp={setClientForm}
          clientObj={clientObj}
          idkey="update"
        />
      )}
      {remove && (
        <Delete
          update={update}
          setUpdate={setUpdate}
          visible={remove}
          setRemove={setRemove}
          Objid={clientObj}
          url={process.env.REACT_APP_DELETE_CLIENT}
        />
      )}
      {view && (
        <ClientViewPopup
          visible={view}
          setView={setView}
          clientObj={clientObj}
        />
      )}
      <div className="clientDropdown cursor-pointer" ref={modalRef}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setDropdown((prevState) => {
              return !prevState;
            });
          }}
          className="px-[8px]">
          <img
            src={eventDetailDotsIcon}
            alt=""
          />
        </div>
        {dropdown && (
          <div className="clientDropDownButton">
            <div
              onClick={() => setView(true)}
              className="flex gap-[8px] items-center py-[4px] pl-[4px] pr-[12px] rounded-[8px]">
              <img src={dropDownViewIcon} alt="" />
              <p className="font_normal_12 text-[#161616]">View Client</p>
            </div>
            <div
              onClick={() => setClientForm(true)}
              className="flex gap-[8px] items-center py-[4px] pl-[4px] pr-[12px] rounded-[8px]" >
              <img src={dropDownEditIcon} alt="" />
              <p className="font_normal_12 text-[#161616]">Edit Client</p></div>
            <div
              onClick={() => setRemove(true)}
              className="flex gap-[8px] items-center py-[4px] pl-[4px] pr-[12px] rounded-[8px]">
              <img src={dropDownDeleteIcon} alt="" />
              <p className="font_normal_12 text-[#161616]">Delete Client</p></div>
          </div>
        )}
      </div>
    </>
  );
}

export default ClientDropDown;
