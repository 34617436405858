import { useState, useRef, useEffect, useContext } from "react";
import axios from "axios";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import { ref, uploadBytes, updateMetadata } from "firebase/storage";
import { userAuthContext } from "../../Context/UserAuthContext";
import { shootmatesalbumdata } from "../../store/firebase-config";
import "./Watermarks.css";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";

const Watermark = ({
  visible,
  setAddWatermarkPopup,
  studioId,
  projectId,
  albumId,
}) => {
  const [sampleImage, setSampleImage] = useState(null);
  const [watermarkImage, setWatermarkImage] = useState(null);
  const [watermarkPosition, setWatermarkPosition] = useState("top-left");
  const [width, setWidth] = useState(100); // Default width
  const [height, setHeight] = useState(100); // Default height
  const [aspectRatio, setAspectRatio] = useState(1); // Aspect ratio of the watermark
  const [synchronizeDimensions, setSynchronizeDimensions] = useState(false);
  const [positionMap, setPositionMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [message, setMessage] = useState(""); // For displaying success/error messages
  const canvasRef = useRef(null);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  useEffect(() => {
    verifyUser();
    if (sampleImage) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const sampleImg = new Image();
      const watermarkImg = new Image();
      sampleImg.src = URL.createObjectURL(sampleImage);

      sampleImg.onload = () => {
        canvas.width = sampleImg.width;
        canvas.height = sampleImg.height;
        ctx.drawImage(sampleImg, 0, 0, sampleImg.width, sampleImg.height);
        if (watermarkImage) {
          watermarkImg.src = URL.createObjectURL(watermarkImage);
          watermarkImg.onload = () => {
            drawWatermark(ctx, sampleImg, watermarkImg);
          };
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleImage, watermarkImage, watermarkPosition, width, height]);

  const handleSampleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSampleImage(file);
    }
  };

  const handleWatermarkImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWatermarkImage(file);
      const img = new Image();
      const reader = new FileReader();
      reader.onload = (event) => {
        img.src = event.target.result;
      };
      img.onload = () => {
        setWidth(img.width);
        setHeight(img.height);
        setAspectRatio(img.width / img.height); // Calculate aspect ratio
      };
      reader.readAsDataURL(file);
    }
  };

  const resizeWatermark = async (watermarkImg) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(watermarkImg, 0, 0, width, height);
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, watermarkImg.type);
    });
  };

  const uploadWatermark = async (file) => {
    setLoading(true);
    try {
      const fileName = file.name;
      const filePath = `/studio/${studioId}/project/${projectId}/album/${albumId}/${fileName}`;
      const storageRef = ref(shootmatesalbumdata, filePath);
      await uploadBytes(storageRef, file);

      const newMetadata = {
        cacheControl: "no-cache, no-store, must-revalidate",
        contentType: file.type,
      };
      await updateMetadata(storageRef, newMetadata);

      const url = `https://storage.googleapis.com/shootmatesalbumdata${filePath}`;
      return url;
    } catch (error) {
      console.error("Error uploading watermark:", error);
      setMessage("Error uploading watermark.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const drawWatermark = (ctx, sampleImg, watermarkImg) => {
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.drawImage(sampleImg, 0, 0, sampleImg.width, sampleImg.height);

    if (!watermarkImg) return;

    const positionMap = {
      "top-left": { x: 0, y: 0 },
      "top-right": { x: sampleImg.width - width, y: 0 },
      "bottom-left": { x: 0, y: sampleImg.height - height },
      "bottom-right": {
        x: sampleImg.width - width,
        y: sampleImg.height - height,
      },
    };
    setPositionMap(positionMap);
    const { x, y } = positionMap[watermarkPosition];
    ctx.drawImage(watermarkImg, x, y, width, height);
  };

  const changePosition = (e) => {
    setWatermarkPosition(e.target.value);
  };

  const handleSetWaterMarkUpload = async () => {
    setLoadingApi(true);
    try {
      if (!watermarkImage) {
        setMessage("No watermark image selected.");
        return;
      }

      const watermarkImg = new Image();
      watermarkImg.src = URL.createObjectURL(watermarkImage);
      watermarkImg.onload = async () => {
        const resizedBlob = await resizeWatermark(watermarkImg);
        const resizedFile = new File([resizedBlob], watermarkImage.name, {
          type: watermarkImage.type,
        });
        const url = await uploadWatermark(resizedFile);

        if (!url) return;

        const watermarkdetails = {
          position: watermarkPosition,
          dimensions: positionMap[watermarkPosition],
          watermarkUrl: url,
        };

        let config = {
          method: "post",
          url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesDesktopTool/api/shootmates/mediaoperations/setWatermark/${projectId}/${studioId}/${albumId}`,
          headers: {
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json",
          },
          data: {
            watermarkdetails: watermarkdetails,
            action: "SET",
          },
        };
        const response = await axios(config);
        if (response.status === 200) {
          setMessage("Watermark uploaded and API call successful!");
          return;
        } else {
          setMessage("Something went wrong please try after sometime!");
          return;
        }
      };
    } catch (error) {
      console.error("Error setting watermark:", error);
      setMessage("Error setting watermark.");
    } finally {
      setLoadingApi(false);
    }
  };

  const handleWidthChange = (e) => {
    const value = e.target.value;
    const newWidth = value === "" ? "" : Number(value);
    setWidth(newWidth);
    if (synchronizeDimensions) {
      if (newWidth !== "") {
        setHeight(Math.round(newWidth / aspectRatio));
      } else {
        setHeight("");
      }
    }
  };

  const handleHeightChange = (e) => {
    const value = e.target.value;
    const newHeight = value === "" ? "" : Number(value);
    setHeight(newHeight);
    if (synchronizeDimensions) {
      if (newHeight !== "") {
        setWidth(Math.round(newHeight * aspectRatio));
      } else {
        setWidth("");
      }
    }
  };

  return (
    <PopupWrapper visible={visible}>
      <div className="WaterMarkContainer w-[800px]  my-[30px] bg-white rounded-lg shadow-lg">
        <div className="px-4 py-4 sticky top-0 bg-white flex items-center justify-between rounded-t-lg">
          <p className="font-semibold text-2xl text-gray-900">Add Watermark</p>
          <button
            type="button"
            onClick={handleSetWaterMarkUpload}
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            {loadingApi ? "Processing..." : "Set Watermark"}
          </button>
          <img
            onClick={() => setAddWatermarkPopup(false)}
            src={popupsCloseIcon}
            alt="Close"
            className="cursor-pointer w-6 h-6"
          />
        </div>
        <div className="flex">
          <div className="w-2/3 px-4">
            <div className="mb-4">
              <label
                htmlFor="sample_image"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sample Image for check
              </label>
              <input
                type="file"
                id="sample_image"
                accept="image/*"
                onChange={handleSampleImageChange}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="sample_image_help"
              />
              <div className="mb-4">
                <label
                  htmlFor="watermark_image"
                  className="block mb-2 text-sm mt-[14px] font-medium text-gray-900 dark:text-white"
                >
                  Watermark Image
                </label>
                <input
                  type="file"
                  id="watermark_image"
                  accept="image/*"
                  onChange={handleWatermarkImageChange}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="watermark_image_help"
                />
              </div>
              <div className="border-[1px] w-full h-[400px] border-[#111] border-opacity-50 p-[12px]">
                {sampleImage && (
                  <div className="mt-2">
                    <canvas
                      ref={canvasRef}
                      className="w-[100%] h-[360px]   object-contain max-w-xl"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-1/3 pl-4 pr-8 mt-[10px]">
            <div className="mb-4">
              <label className="font-semibold text-lg text-green-900 mb-2">
                Watermark Position
              </label>
              <div className="flex flex-col space-y-2 mt-2">
                {["top-left", "top-right", "bottom-left", "bottom-right"].map(
                  (position) => (
                    <label
                      key={position}
                      className={`px-4 py-2 text-sm border rounded cursor-pointer text-center ${
                        watermarkPosition === position
                          ? "bg-blue-500 text-white"
                          : "bg-white text-gray-900"
                      }`}
                    >
                      <input
                        type="radio"
                        name="position"
                        value={position}
                        checked={watermarkPosition === position}
                        onChange={changePosition}
                        className="hidden"
                      />
                      {position
                        .replace("-", " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    </label>
                  )
                )}
              </div>
            </div>
            <div className="mb-4">
              <label className="font-semibold text-lg text-green-900 mb-2">
                Watermark Dimensions
              </label>
              <br />
              <label className="font-semibold text-xs text-gray-900 mb-2">
                Width
              </label>
              <input
                type="number"
                placeholder="Width"
                value={width || ""}
                onChange={handleWidthChange}
                className="block w-full p-2 border border-gray-300 rounded appearance-none hide-spin"
              />
              <label className="font-semibold text-xs text-gray-900 mb-2">
                Height
              </label>
              <input
                type="number"
                placeholder="Height"
                value={height || ""}
                onChange={handleHeightChange}
                className="block w-full p-2 border border-gray-300 rounded appearance-none hide-spin"
              />

              <label className="flex items-center mt-4">
                <input
                  type="checkbox"
                  checked={synchronizeDimensions}
                  onChange={(e) => setSynchronizeDimensions(e.target.checked)}
                  className="mr-2"
                />
                <span className="text-xs">Synchronize Width & Height</span>
              </label>
            </div>
          </div>
        </div>
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
          </div>
        )}
        {message && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white border border-gray-300 p-4 rounded shadow-md">
              <p>{message}</p>
              <button
                onClick={() => setMessage("")}
                className="mt-2 px-4 py-2 bg-gray-800 text-white rounded"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </PopupWrapper>
  );
};

export default Watermark;
