import React from 'react'
import './ClientSearchDropDown.css'

function ClientSearchDropDown({ formValues, setFormValues, clientSearchData, setSearch, setClientSelect, clientDropDownLoading, clientDataLength }) {
    return (
        <div className="dropdown-clientSearch bg-white shadow-lg rounded-md p-4 border border-gray-200 max-w-xs">
            {/* Client Search Results */}
            {!clientDropDownLoading &&
                <div className={`max-h-[300px] overflow-y-auto ${clientSearchData.length > 10 ? 'scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200' : ''}`}>
                    {clientSearchData.map((obj, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                setSearch(obj.firstName);
                                setClientSelect(false);
                                setFormValues({
                                    ...formValues,
                                    client: obj._id
                                });
                            }}
                            className="w-full text-left px-4 py-2 text-gray-700 hover:bg-blue-500 hover:text-white rounded-md transition-colors duration-150"
                        >
                            {obj.firstName}
                        </button>
                    ))}
                </div>
            }
            {/* Loading State */}
            {clientDropDownLoading && (
                <button className="w-full text-left px-4 py-2 text-gray-400">Loading...</button>
            )}
            {/* No Results Found */}
            {clientDataLength === 0 && !clientDropDownLoading && (
                <button className="w-full text-left px-4 py-2 text-gray-400">No results found</button>
            )}
        </div>
    );
}

export default ClientSearchDropDown;
