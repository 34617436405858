import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { dataContext } from '../../../Context/DataContext'
import { userAuthContext } from '../../../Context/UserAuthContext'
import { PopupWrapper } from '../../../UI/PopupWrapper/PopupWrapper'
import ClientSearchDropDown from '../../ClientSearchDropDown/ClientSearchDropDown'
import ClientForm from '../ClientForm/ClientForm'
import './LeadFormAssignAlbum.css'
import { useNavigate } from 'react-router-dom'

function LeadFormAssignAlbum({
  update,
  albumTemplateId,
  setUpdate,
  visible,
  closePopup,
  albumId,
  templateName,
  albumName,
}) {
  const [loading, setLoading] = useState(false)
  const { studioId } = useContext(dataContext)
  const { userIdToken, currentUser } = useContext(userAuthContext)
  const tokenData = userIdToken || currentUser?.accessToken
  const [search, setSearch] = useState('')
  const [clientSearchData, setClientSearchData] = useState([])
  const [clientSelect, setClientSelect] = useState(false)
  const [clientPopUp, setClientPopUp] = useState(false)
  const navigate = useNavigate()
  const initialValues = {
    isDateConfirmed: 'FALSE',
    isAllDay: 'FALSE',
  }
  const [formValues, setFormValues] = useState(initialValues)
  useEffect(() => {
    let CancelToken = axios.CancelToken
    let source = CancelToken.source()
    if (search !== '') {
      clientSearch(source)
    }
    return () => {
      source.cancel('Cancelled due to stale request')
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [search])

  const handleChange = (e) => {
    e.preventDefault()
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    })
  }

  const clientSearch = async (source) => {
    try {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_GET_ALL_CLIENTS}/${studioId}?search=${search}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
        cancelToken: source.token,
      }
      const response = await axios(config)

      if (response.status === 201) {
        setClientSearchData(response.data.data)
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('cancelled')
      } else {
        throw error
      }
    }
  }

  const values = {
    _id: albumId,
    templateName: templateName,
    albumName: albumName,
    albumTemplateId: albumTemplateId,
  }
  const createLeadForAlbum = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_CREATE_LEAD_FOR_ALBUM}/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
        data: { projectData: formValues, albumData: values },
      }
      const response = await axios(config)
      closePopup(false)
      if (response.status === 200) {
        navigate(`/leads/${response.data.data._id}`)
        setLoading(false)
        setUpdate(!update)
      }
    } catch (error) {
      console.log('error', error)
      closePopup(true)
      setLoading(false)
    }
  }
  return (
    <PopupWrapper visible={visible}>
      <div className="w-[60%] h-[80%] overflow-y-scroll">
        {clientPopUp && (
          <ClientForm
            update={update}
            setUpdate={setUpdate}
            visible={clientPopUp}
            setpopUp={setClientPopUp}
            idkey="create"
          />
        )}
        <div className="leadformAlbum-heading p-[24px] flex flex-row justify-between">
          <h3>Add a New Lead</h3>
          <button
            onClick={() => {
              closePopup(false)
            }}
          >
            Close
          </button>
        </div>
        <div className="leadformAlbum-container">
          <div className="leadformAlbumgrid-container">
            <div className="item1">
              <p>
                Client Contact<span className="text-red-600">*</span>
              </p>
              <input
                type="text"
                placeholder="Start entering..."
                onFocus={() => {
                  setClientSelect(true)
                }}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              {clientSearchData.length !== 0 &&
                clientSelect &&
                search !== '' && (
                  <ClientSearchDropDown
                    formValues={formValues}
                    setFormValues={setFormValues}
                    clientSearchData={clientSearchData}
                    setSearch={setSearch}
                    setClientSelect={setClientSelect}
                  />
                )}
            </div>
            <div className="item2">
              <p>OR</p>
            </div>
            <div className="item3">
              <button
                className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
                onClick={() => setClientPopUp(true)}
              >
                Add New Client
              </button>
            </div>
            <div className="item4">
              <p>Lead Deatils</p>
            </div>
            <div className="item5">
              <p>
                Project Name:<span className="text-red-600">*</span>
              </p>
              <input
                type="text"
                id="leadName"
                onChange={handleChange}
                value={formValues.projectName}
              />
            </div>
            <div className="item17">
              <p>
                Location:<span className="text-red-600">*</span>
              </p>
              <input
                type="text"
                id="location"
                onChange={handleChange}
                value={formValues.location}
              />
            </div>
            <div className="item6">
              <p>Schedule</p>
            </div>
            <div className="item7">
              <input
                type="checkbox"
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    isDateConfirmed:
                      formValues.isDateConfirmed === 'FALSE' ? 'TRUE' : 'FALSE',
                  })
                }}
                value={formValues.isDateConfirmed}
                checked={formValues.isDateConfirmed === 'FALSE' ? false : true}
              />
              <label>Date not yet confirmed</label>
            </div>
            {formValues.isDateConfirmed === 'FALSE' && (
              <>
                <div className="item8">
                  <p>
                    Date<span className="text-red-600">*</span>
                  </p>
                  <input
                    type="date"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        startDate: e.target.value,
                      })
                    }}
                    value={formValues.startDate}
                  />
                </div>
                <div className="item9">
                  <p>To</p>
                </div>
                <div className="item10">
                  <input
                    type="date"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        endDate: e.target.value,
                      })
                    }}
                    value={formValues.endDate}
                  />
                </div>
                <div className="item11">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        isAllDay:
                          formValues.isAllDay === 'FALSE' ? 'TRUE' : 'FALSE',
                      })
                    }}
                    value={formValues.isAllDay}
                    checked={formValues.isAllDay === 'FALSE' ? false : true}
                  />
                  <label>All Day</label>
                </div>
                {formValues.isAllDay === 'FALSE' && (
                  <>
                    {' '}
                    <div className="item12">
                      <p>
                        Time<span className="text-red-600">*</span>
                      </p>
                      <input
                        type="time"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            startTime: e.target.value,
                          })
                        }}
                        value={formValues.startTime}
                      />
                    </div>
                    <div className="item13">
                      <p>To</p>
                    </div>
                    <div className="item14">
                      <input
                        type="time"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            endTime: e.target.value,
                          })
                        }}
                        value={formValues.endTime}
                      />
                    </div>
                    <div className="item15"></div>{' '}
                  </>
                )}
              </>
            )}
            <div className="item18">
              <p>
                Notes:<span className="text-red-600">*</span>
              </p>
              <input
                type="text"
                id="projectNote"
                onChange={handleChange}
                value={formValues.projectNote}
              />
            </div>
            <div className="item16 flex flex-row mt-[12px] gap-x-[12px]">
              <button
                className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
                onClick={() => {
                  closePopup(false)
                }}
              >
                Cancel
              </button>
              {loading ? (
                <button className="px-6 py-2 text-white rounded-full bg-[#1b2835] focus:outline-none">
                  Loading
                </button>
              ) : (
                <button
                  className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
                  onClick={() => createLeadForAlbum()}
                >
                  Save Lead
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupWrapper>
  )
}

export default LeadFormAssignAlbum
