import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from '../../Assets/Animation/LoadingWhite';
import { dataContext } from '../../Context/DataContext';
import { userAuthContext } from '../../Context/UserAuthContext';
import './Payments.css';

const Payments = () => {
  const [loadingText, setLoadingText] = useState(null)
  const [loading, setLoading] = useState(false);
  const { studioId } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [update, setUpdate] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    verifyUser();
    getPaymentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const getPaymentData = async () => {
    setLoading(true);
    try {
      let config = {
        method: 'get',
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesPayments/api/shootmates/payments/paymentlisting/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setPaymentData(response.data.projectData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const paymentHandler = async (eventId) => {
    try {
      verifyUser()
      setLoading(true);
      setLoadingText("Initialising payment..")
      let config = {
        method: "POST",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesPayments/api/shootmates/payments/phonepe/${eventId}/${studioId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      }
      const responseFromPaymentStatus = await axios(config)
      const url = responseFromPaymentStatus?.data?.url
      if (url) {
        const form = document.createElement("form");
        form.method = "get";
        form.action = "https://www.shootmates.com/thirdpartyPayment";
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "gatewayresp";
        input.id = "gatewayresp";
        input.value = url;
        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
        setLoading(false);
        setLoadingText(null)
      }
      setLoading(false);
      setLoadingText(null)
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setLoadingText(null)
    }
  }
  return (
    <div className='flex flex-col'>
      <div className='payments-container'>
        <h2 className='text-2xl italic font-bold'>Payments Overview</h2>
      </div>
      <div className='payments-container'>
        <button
          className="select-none rounded-lg bg-green-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          onClick={() => {
            setUpdate(!update);
          }}
        >
          Refresh
        </button>
      </div>
      <div class="relative overflow-x-auto ml-[10px]">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3 bg-[]">
                Project name
              </th>
              <th scope="col" class="px-6 py-3">
                Total Pending
              </th>
              <th scope="col" class="px-6 py-3">
                Total Cost
              </th>
              <th scope="col" class="px-6 py-3">
                Explore
              </th>
              <th scope="col" class="px-6 py-3" >
                Payment Action
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <Lottie loadingText={loadingText !== null ? loadingText : 'Fetching data..'} />
            ) : paymentData && paymentData?.length > 0 ? (
              paymentData?.map((el) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {el?.leadName}
                  </th>
                  <td className="px-6 py-4">
                    {el?.paymentDue}
                  </td>
                  <td className="px-6 py-4">
                    {el?.totalPayment}
                  </td>
                  <td className="px-6 py-4"
                    onClick={() => {
                      navigate(`/payments/${el?.projectId}`);
                    }}>
                    <button className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white hover:border-transparent rounded'>View Items</button>
                  </td>
                  <td className="px-6 py-4"
                  >
                    {
                      el?.paymentDue !== 0 ?
                        <button className='bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white hover:border-transparent rounded' onClick={() => {
                          paymentHandler(el?.projectId)
                        }}>Pay Now</button> :
                        <button className='bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white hover:border-transparent rounded'
                        >Paid</button>
                    }

                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colspan="4" className="px-6 py-4 text-center text-red-700 dark:text-red-400">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payments;
