import React, { useContext, useEffect, useState } from "react";
import Lottie from "../../../Assets/Animation/LoadingWhite";
import MessagePopup from "../../PopUps/MessagePopup/MessagePopup";
import axios from "axios";
import { dataContext } from "../../../Context/DataContext";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./AddCrewForm.css";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";

const AddCrewForm = ({ visible, setpopUp, update, setUpdate }) => {
    const { studioId } = useContext(dataContext);
    const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
    const tokenData = userIdToken || currentUser?.accessToken;
    const initialFormData = {
        email: "",
        password: "",
        name: "",
    };
    const [formData, setFormData] = useState(initialFormData);
    const [formErrors, setFormErrors] = useState({});
    const [messagePopup, setMessagePopup] = useState({
        visible: false,
        type: "",
        message: "",
    });
    const [loadingText, setLoadingText] = useState("Adding Crew in progress..");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        verifyUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setFormErrors({
            ...formErrors,
            [name]: "",
        });
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const handleSubmit = async () => {
        try {
            const { email, password, name } = formData;
            const errors = {};

            if (!isValidEmail(email)) {
                errors.email = "Invalid email address";
            }

            if (name.trim() === "") {
                errors.name = "Name cannot be empty";
            }

            if (password.trim().length < 8) {
                errors.password = "Password must be at least 8 characters long";
            }

            if (Object.keys(errors).length > 0) {
                setFormErrors(errors);
                return;
            }
            setLoading(true);
            verifyUser();

            let config = {
                method: "post",
                url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/addCrewUser/${studioId}`,
                data: formData,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios(config);
            if (response.status === 201) {
                setLoadingText("Adding Crew in progress..");
                setLoading(false);
                setFormData(initialFormData)
                setMessagePopup({
                    visible: true,
                    type: "success",
                    message: response?.data?.msg,
                });
                setUpdate(!update)
            } else {
                setLoading(false);
                setFormData(initialFormData)
                setMessagePopup({
                    visible: true,
                    type: "error",
                    message: response?.data?.msg,
                });
            }
        } catch (error) {
            setLoading(false);
            setMessagePopup({
                visible: true,
                type: "success",
                message: "Request failed please try again..",
            });
            console.log("error", error);
        }
    };


    return (
        <PopupWrapper visible={visible} setpopUp={setpopUp}>
            {loading ? (
                <Lottie loadingText={loadingText} />
            ) : (
                <>
                    {messagePopup?.visible && (
                        <MessagePopup
                            messagePopup={messagePopup}
                            setMessagePopup={setMessagePopup}
                        />
                    )}
                    <div className="AddUserFormContainer mt-[78px]">
                        <div className="px-[16px] pt-[24px] pb-[12px] sticky top-0 bg-white flex items-center justify-between rounded-t-[12px]">
                            <p className="font_semibold_20 text-[#161616]">Add Crew</p>
                            <img
                                onClick={() => {
                                    setpopUp(false);
                                    setFormData(initialFormData);
                                    setFormErrors({});
                                }}
                                src={popupsCloseIcon}
                                alt=""
                                className="cursor-pointer"
                            />
                        </div>
                        <div className="seperatorHorizontalNew"></div>
                        <div className="px-[16px] pt-[12px]">
                            <p className="font_semibold_16 text-[#161616] mb-[20px]">User Details</p>
                            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                                User Name <span className="text-red-500">*</span>
                            </p>
                            <input
                                type="text"
                                name="name"
                                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${formErrors.name ? "border-red-500" : "border-[#8C8C8C4D]"
                                    }`}
                                placeholder="Enter name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                            {formErrors.name && (
                                <p className="text-red-500 font_normal_12">{formErrors.name}</p>
                            )}
                        </div>

                        <div className="px-[16px]">
                            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                                Email <span className="text-red-500">*</span>
                            </p>
                            <input
                                type="email"
                                name="email"
                                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${formErrors.email ? "border-red-500" : "border-[#8C8C8C4D]"
                                    }`}
                                placeholder="Enter email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {formErrors.email && (
                                <p className="text-red-500 font_normal_12">{formErrors.email}</p>
                            )}
                        </div>

                        <div className="px-[16px]">
                            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                                Password <span className="text-red-500">*</span>
                            </p>
                            <input
                                type="password"
                                name="password"
                                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${formErrors.password ? "border-red-500" : "border-[#8C8C8C4D]"
                                    }`}
                                placeholder="Enter password"
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                            {formErrors.password && (
                                <p className="text-red-500 font_normal_12">{formErrors.password}</p>
                            )}
                        </div>

                        <div className="seperatorHorizontalNew mt-[16px]"></div>
                        <div className="px-[16px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
                            <button
                                className="cancelButton font_semibold_12"
                                onClick={() => {
                                    setpopUp(false);
                                    setFormData(initialFormData);
                                    setFormErrors({});
                                }}
                            >
                                Cancel
                            </button>
                            <button className="eventButton" onClick={handleSubmit}>
                                <p className="text-[#F6F6F6] font_semibold_12">Submit</p>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </PopupWrapper>
    );
};

export default AddCrewForm;
