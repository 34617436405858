import React, { useContext, useState } from "react";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";
import { isEmpty } from "lodash";

function RequestPortfolio({ visible, setPopup, submitFunction }) {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(userAuthContext);
  const [formValues, setFormValues] = useState({
    email: currentUser?.email || "",
  });
  const [formErrors, setFormErrors] = useState({});
  const validatePhone = (number) => {
    let regexmobile =
      // eslint-disable-next-line no-useless-escape
      /^\+?(\d{1,4}|\(\d{1,4}\))[-.\s]?((\d{1,3})?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/;
    if (number?.match(regexmobile)) {
      return true;
    } else {
      return false;
    }
  };
  const validate = (values) => {
    let errors = {};
    if (!values?.name) {
      errors.name = "Please enter your Name";
    }
    if (!values?.studioName) {
      errors.studioName = "Please enter Organisation Name";
    }
    if (!validatePhone(values?.phone_number)) {
      errors.phone_number = "Please enter valid mobile number";
    }

    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const errors = validate(formValues);
    if (isEmpty(errors)) {
      await submitFunction(formValues);
    }
    setLoading(false);
  };
  const handleChangeInputs = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  return (
    <PopupWrapper visible={visible}>
      <div className="min-w-[50%] bg-white rounded-lg border border-gray-300 border-opacity-30 shadow-lg h-fit mt-[78px]">
        <div className="p-[24px] sticky top-0 bg-white flex items-center justify-between rounded-t-[12px]">
          <p className="font_semibold_20 text-[#161616]">Request Portfolio</p>
          <img
            src={popupsCloseIcon}
            alt=""
            onClick={() => setPopup(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="seperatorHorizontalNew"></div>
        <div className="pt-[12px]">
          <div className="px-[24px] mb-[16px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Name <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Enter your name"
              className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
              value={formValues?.name}
              style={{ borderColor: formErrors?.name && "#f25555" }}
              id="name"
              onChange={handleChangeInputs}
            />
            {formErrors?.name && (
              <p className="font_normal_12 text-[#f25555]">
                {formErrors?.name}
              </p>
            )}
          </div>
          <div className="px-[24px] mb-[16px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Organisation Name <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Enter your organisation name"
              className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
              value={formValues?.studioName}
              id="studioName"
              style={{ borderColor: formErrors?.studioName && "#f25555" }}
              onChange={handleChangeInputs}
            />
            {formErrors?.studioName && (
              <p className="font_normal_12 text-[#f25555]">
                {formErrors?.studioName}
              </p>
            )}
          </div>
          <div className="px-[24px] mb-[16px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Phone Number <span className="text-red-500">*</span>
            </p>
            <input
              type="text"
              placeholder="Enter your contact number"
              className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] border-[#8C8C8C4D]`}
              value={formValues?.phone_number}
              id="phone_number"
              style={{ borderColor: formErrors?.phone_number && "#f25555" }}
              onChange={handleChangeInputs}
            />
            {formErrors?.phone_number && (
              <p className="font_normal_12 text-[#f25555]">
                {formErrors?.phone_number}
              </p>
            )}
          </div>
          <div className="seperatorHorizontalNew"></div>
          <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[16px] pb-[24px]">
            <button
              className="cancelButton font_semibold_12"
              onClick={() => setPopup(false)}
            >
              Cancel
            </button>
            <button
              disabled={loading}
              onClick={handleSubmit}
              className="eventButton font_semibold_12"
            >
              {loading ? "Loading" : "Sent Request"}
            </button>
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default RequestPortfolio;
