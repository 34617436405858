import React, { useEffect } from "react";
import { useState } from "react";
import facebook from "../../Assets/share/fbLogo.svg";
import whatsapp from "../../Assets/share/whatsApp.svg";
import twitter from "../../Assets/share/twitter.svg";
import LinkedIn from "../../Assets/share/LinkedIn.svg";
import copyLinkImage from "../../Assets/share/copyLinkBlack.svg";
import copySuccess from "../../Assets/share/copySuccessBlack.svg";
import closebutton from "../../Assets/share/closebutton.svg";
import styles from "../SharePopup/SharePopup.module.css";

const SharePopup = ({ heading, showShare, url }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  let copyText = url;
  function copyLink() {
    setCopied(true);
    setTimeout(() => {
      showShare();
    }, [1000]);
    navigator.clipboard.writeText(copyText);
    window.document.execCommand("copy");
    // alert(copyText + "\n copied to clipboard")
  }
  const whatsapplink = () => {
    const encodedContent = encodeURIComponent(copyText);
    const isMobileDevice = /Android|iPhone|iPad|iPod/i.test(
      navigator.userAgent
    );
    if (isMobileDevice) {
      const url = `https://wa.me/?text=${encodedContent}`;
      window.open(url, "_blank");
    } else {
      const url = `https://web.whatsapp.com/send?text=${encodedContent}`;
      window.open(url, "_blank");
    }
  };
  const facebooklink = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${copyText}`);
    return false;
  };
  const shareToTwitter = () => {
    window.open(`http://twitter.com/share?url=${copyText}`);
  };
  const shareToLinkedin = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${copyText}`
    );
  };
  // const shareToEmail = () => {
  //   window.location.href = `mailto:?body=${copyText}`;
  // };

  return (
    <div
      className={`fixed top-0 left-0 flex justify-center items-center h-[100vh] w-[100%] z-[999] px-[20px] ${styles.popupBackground}`}
    >
      <div className={`${styles.SharePopupParent}`}>
        <div className="relative w-[100%]">
          <div className="flex justify-between items-center w-[100%] p-[20px]">
            <p className="font_semibold_16 text-[#161616]">{heading} </p>
            <img
              src={closebutton}
              onClick={showShare}
              alt="Copy"
              tooltip="Copy to clipboard"
              className=" hover:cursor-pointer"
            />
          </div>
        </div>
        <div className="seperator" />
        <div className="p-[20px] w-[100%]">
          <div className="flex justify-between w-[100%] text-[#161616] font_normal_13">
            <div
              className="flex flex-col items-center gap-[6px] cursor-pointer"
              onClick={facebooklink}
            >
              <img src={facebook} alt="" className="w-[56px] h-[56px]" />
              <p>Facebook</p>
            </div>
            <div
              className="flex flex-col items-center gap-[6px] cursor-pointer"
              onClick={whatsapplink}
            >
              <img src={whatsapp} alt="" className="w-[56px] h-[56px]" />
              <p>Whatsapp</p>
            </div>
            <div
              className="flex flex-col items-center gap-[6px] cursor-pointer"
              onClick={shareToTwitter}
            >
              <img src={twitter} alt="" className="w-[56px] h-[56px]" />
              <p>Twitter</p>
            </div>
            <div
              className="flex flex-col items-center gap-[6px] cursor-pointer"
              onClick={shareToLinkedin}
            >
              <img src={LinkedIn} alt="" className="w-[56px] h-[56px]" />
              <p>Linkedin</p>
            </div>
          </div>
          <div className="seperator my-[24px]" />

          <div className="">
            <div
              className="flex flex-row items-center justify-between w-[100%] "
              onClick={copyLink}
              tooltip="Copy to clipboard"
            >
              <div className="w-[calc(100%-50px)]">
                <p className="font_medium_16 text-[#161616]">Copy the link </p>
                <p className="font_normal_13 break-words text_1Line text-[#161616]">
                  {copyText}
                </p>
              </div>
              <div className="w-[24px] h-[24px]">
                {copied ? (
                  <img src={copySuccess} className="" alt="Copy" />
                ) : (
                  <img src={copyLinkImage} className="" alt="Copy" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePopup;
