import axios from "axios";
import React, { useContext, useState } from "react";
import { dataContext } from "../../../Context/DataContext";
import { userAuthContext } from "../../../Context/UserAuthContext";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./ClientForm.css";
import popupsCloseIcon from '../../../Assets/popupsCloseIcon.svg'

export default function ClientForm({
    update,
    setUpdate,
    visible,
    setpopUp,
    clientObj,
    idkey,
}) {
    const [loading, setLoading] = useState(false);
    const { userIdToken, currentUser } = useContext(userAuthContext);
    const tokenData = userIdToken || currentUser?.accessToken;
    const { studioId } = useContext(dataContext);
    const initialValues = {
        firstName: clientObj?.firstName ? clientObj.firstName : "",
        lastName: clientObj?.lastName ? clientObj.lastName : "",
        email: clientObj?.email ? clientObj.email : "",
        phone: clientObj?.phone ? clientObj.phone : "",
        streetAddress: clientObj?.streetAddress ? clientObj.streetAddress : "",
        town: clientObj?.town ? clientObj.town : "",
        pincode: clientObj?.pincode ? clientObj.pincode : "",
        state: clientObj?.state ? clientObj.state : "",
        country: clientObj?.country ? clientObj.country : "",
        isCompany: clientObj?.isCompany ? clientObj.isCompany : "FALSE",
        company: clientObj?.company ? clientObj.company : "",
        businessNumber: clientObj?.businessNumber ? clientObj.businessNumber : "",
    };
    const [formValues, setFormValues] = useState(initialValues);

    const createClient = async () => {
        setLoading(true);
        try {
            let config = {
                method: "post",
                url: `${process.env.REACT_APP_CREATE_CLIENT}/${studioId}`,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    "Content-Type": "application/json",
                },
                data: formValues,
            };
            const response = await axios(config);
            setpopUp(false);
            if (response.status === 201) {
                setLoading(false);
                setUpdate(!update);
            }
        } catch (error) {
            console.log("error", error);
            setpopUp(true);
            setLoading(false);
        }
    };
    const updateClient = async () => {
        try {
            let config = {
                method: "patch",
                url: `${process.env.REACT_APP_UPDATE_CLIENT}/${clientObj._id}/${studioId}`,
                headers: {
                    Authorization: `Bearer ${tokenData}`,
                    "Content-Type": "application/json",
                },
                data: formValues,
            };
            const response = await axios(config);
            setpopUp(false);
            if (response.status === 200) {
                setLoading(false);
                setUpdate(!update);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    return (
        <PopupWrapper visible={visible}>
            <div className="clientFormContainer mt-[78px]">
                <div className="flex justify-between p-[24px] items-center">
                    <p className="font_semibold_20 text-[#161616]">
                        {idkey === "update" ? "Edit client" : "Add a new client"}
                    </p>
                    <img
                        onClick={() => setpopUp(false)}
                        src={popupsCloseIcon}
                        alt=""
                        className="cursor-pointer" />
                </div>
                <div className="seperatorHorizontalNew"></div>
                <div className='className="w-full h-full px-[24px] py-[20px]'>
                    <p className="font_semibold_16 text-[#161616] mb-[24px]">
                        Client details
                    </p>
                    <div className="flex gap-[8px] items-center mb-[24px]">
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                setFormValues({
                                    ...formValues,
                                    isCompany:
                                        formValues.isCompany === "FALSE" ? "TRUE" : "FALSE",
                                });
                            }}
                            value={formValues.isCompany}
                            checked={formValues.isCompany === "FALSE" ? false : true}
                        />
                        <p className="font_normal_12 text-[#4D58E3]">
                            This client is a company and invoices will be addressed to the
                            company.
                        </p>
                    </div>
                    <div
                        className={
                            formValues.isCompany === "TRUE"
                                ? "clientGridCompany"
                                : "clientGrid"
                        }
                    >
                        {formValues.isCompany === "TRUE" && (
                            <>
                                <div className="clientitem11">
                                    <p className="font_normal_12 text-[#8C8C8C]">
                                        Company<span className="text-red-600">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setFormValues({
                                                ...formValues,
                                                company: e.target.value,
                                            })
                                        }
                                        value={formValues.company}
                                    />
                                </div>
                                <div className="clientitem12">
                                    <p className="font_normal_12 text-[#8C8C8C]">
                                        Business Number<span className="text-red-600">*</span>
                                    </p>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setFormValues({
                                                ...formValues,
                                                businessNumber: e.target.value,
                                            })
                                        }
                                        value={formValues.businessNumber}
                                    />
                                </div>
                                <div className="clientitem15"></div>
                            </>
                        )}
                        <div className="clientitem1">
                            <p className="font_normal_12 text-[#8C8C8C]">
                                First Name<span className="text-red-600">*</span>
                            </p>
                            <input
                                type="text"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        firstName: e.target.value,
                                    })
                                }
                                value={formValues.firstName}
                            />
                        </div>
                        <div className="clientitem2">
                            <p className="font_normal_12 text-[#8C8C8C]">Last Name</p>
                            <input
                                type="text"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        lastName: e.target.value,
                                    })
                                }
                                value={formValues.lastName}
                            />
                        </div>
                        <div className="clientitem15"></div>
                        <div className="clientitem3">
                            <p className="font_normal_12 text-[#8C8C8C]">
                                Phone Number<span className="text-red-600">*</span>
                            </p>
                            <input
                                type="number"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        phone: e.target.value,
                                    })
                                }
                                value={formValues.phone}
                            />
                        </div>
                        <div className="clientitem4">
                            <p className="font_normal_12 text-[#8C8C8C]">
                                Email<span className="text-red-600">*</span>
                            </p>
                            <input
                                type="email"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        email: e.target.value,
                                    })
                                }
                                value={formValues.email}
                            />
                        </div>
                        <div className="clientitem5">
                            <p className="font_normal_12 text-[#8C8C8C]">Street Address</p>
                            <input
                                type="text"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        streetAddress: e.target.value,
                                    })
                                }
                                value={formValues.streetAddress}
                            />
                        </div>
                        <div className="clientitem6">
                            <p className="font_normal_12 text-[#8C8C8C]">Suburb/Town</p>
                            <input
                                type="text"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        town: e.target.value,
                                    })
                                }
                                value={formValues.town}
                            />
                        </div>
                        <div className="clientitem7">
                            <p className="font_normal_12 text-[#8C8C8C]">PostCode/Zip</p>
                            <input
                                type="number"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        pincode: e.target.value,
                                    })
                                }
                                value={formValues.pincode}
                            />
                        </div>
                        <div className="clientitem8">
                            <p className="font_normal_12 text-[#8C8C8C]">State</p>
                            <input
                                type="text"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        state: e.target.value,
                                    })
                                }
                                value={formValues.state}
                            />
                        </div>
                        <div className="clientitem9">
                            <p className="font_normal_12 text-[#8C8C8C]">Country</p>
                            <input
                                type="text"
                                onChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        country: e.target.value,
                                    })
                                }
                                value={formValues.country}
                            />
                        </div>
                        <div className="clientitem15"></div>
                    </div>
                </div>
                <div className="seperatorHorizontalNew"></div>
                <div className="px-[24px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
                    <button
                        className="cancelButton font_semibold_12"
                        onClick={() => setpopUp(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="eventButton font_semibold_12"
                        onClick={idkey === "update" ? updateClient : createClient}
                    >
                        {loading ? "Loading..." : "Create Client Profile"}
                    </button>

                </div>
            </div>
        </PopupWrapper>
    );
}
