import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import qrCodeIcon from "../../Assets/qrCodeIcon.svg";

const QrCodeGenerator = ({ onClose, slugId }) => {
  const [copiedLive, setCopiedLive] = useState(false);
  const [copiedRegister, setCopiedRegister] = useState(false);
  const qrRefLive = useRef();
  const qrRefRegister = useRef();
  const livelink = `https://app.memshots.com/${slugId}/face/live`;
  const registerlink = `https://app.memshots.com/${slugId}/face/register`;

  const handleDownloadRegister = () => {
    const svg = qrRefRegister.current.querySelector("svg");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {
      const scaleFactor = 4;
      canvas.width = img.width * scaleFactor;
      canvas.height = img.height * scaleFactor;
      ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngFile;
      downloadLink.download = "register_qrcode.png";
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const handleDownloadLive = () => {
    const svg = qrRefLive.current.querySelector("svg");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {
      const scaleFactor = 4;
      canvas.width = img.width * scaleFactor;
      canvas.height = img.height * scaleFactor;
      ctx.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngFile;
      downloadLink.download = "live_qrcode.png";
      downloadLink.click();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const copyToClipboard = (mode) => {
    const linkToCopy = `https://app.memshots.com/${slugId}/face/${mode}`;
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    mode === "live" ? setCopiedLive(true) : setCopiedRegister(true);
    setTimeout(() => {
      mode === "live" ? setCopiedLive(false) : setCopiedRegister(false);
    }, 1000);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl space-y-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-red-500 hover:text-red-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-2xl font-bold mb-6 text-gray-800">QR Codes</h2>

        <div className="flex justify-between space-x-6">
          <div className="w-1/2 text-center">
            {registerlink && (
              <div ref={qrRefRegister} className="bg-gray-100 p-4 rounded-lg">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={registerlink}
                  viewBox={`0 0 256 256`}
                />
                <button
                  onClick={handleDownloadRegister}
                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg w-full"
                >
                  Download Register QR Code
                </button>
              </div>
            )}
            <div
              onClick={() => copyToClipboard("register")}
              className="mt-4 cursor-pointer inline-flex items-center justify-center space-x-2 bg-gray-800 text-white py-2 px-4 rounded-lg hover:bg-gray-700"
            >
              <img src={qrCodeIcon} alt="QR Icon" className="w-5 h-5" />
              <p className="text-sm">
                {copiedRegister ? "Copied!" : "Copy Register Link"}
              </p>
            </div>
          </div>

          <div className="w-1/2 text-center">
            {livelink && (
              <div ref={qrRefLive} className="bg-gray-100 p-4 rounded-lg">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={livelink}
                  viewBox={`0 0 256 256`}
                />
                <button
                  onClick={handleDownloadLive}
                  className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg w-full"
                >
                  Download Live QR Code
                </button>
              </div>
            )}
            <div
              onClick={() => copyToClipboard("live")}
              className="mt-4 cursor-pointer inline-flex items-center justify-center space-x-2 bg-gray-800 text-white py-2 px-4 rounded-lg hover:bg-gray-700"
            >
              <img src={qrCodeIcon} alt="QR Icon" className="w-5 h-5" />
              <p className="text-sm">{copiedLive ? "Copied!" : "Copy Live Link"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeGenerator;
