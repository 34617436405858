import React, { useContext, useEffect, useState } from "react";
import { dataContext } from "../../Context/DataContext";
import { isEmpty } from "lodash";
import axios from "axios";
import Loading from "../../Assets/Animation/Loading";
import styles from "./PortfolioLeadsPage.module.css";
import { Link, useSearchParams } from "react-router-dom";
import { userAuthContext } from "../../Context/UserAuthContext";
import LeadsMoreOptionDropDown from "./LeadsMoreOptionDropDown";
import MessagePopup from "../../Components/PopUps/MessagePopup/MessagePopup";
import CommentPopup from "./CommentPopup";

export default function PortfolioLeadsPage() {
  const { roleSelected } = useContext(dataContext);
  const { studioId, studioUniqueText } = roleSelected || {};
  const [dataLoading, setDataLoading] = useState(false);
  const [created, setCreated] = useState(false);
  const [enquiries, setEnquires] = useState([]);
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [commentsObj, setCommentsObj] = useState([]);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [updateData, setUpdateData] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams({ filter: "NEW" });
  const [successPopup, setSuccessPopup] = useState({
    visible: false,
    type: "success",
    message: "",
  });
  const status = searchParams?.get("filter");

  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(roleSelected)) {
      getDraftJson();
    }
    // eslint-disable-next-line
  }, [roleSelected, updateData, status]);

  const getDraftJson = async () => {
    setDataLoading(true);
    try {
      const config = {
        method: "GET",
        url: `https://storage.googleapis.com/studio_portfolio/${studioUniqueText}/draft.json`,
      };
      await axios(config);
      setCreated(true);
      await getEnquiries();
      setDataLoading(false);
    } catch (e) {
      setDataLoading(false);
      setCreated(false);
      console.log(e);
    }
  };

  const getEnquiries = async () => {
    setDataLoading(true);
    try {
      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_GET_ENQUIRES}/${studioId}?status=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios(config);
      setEnquires(data?.doc?.enquiries);
      setDataLoading(false);
    } catch (e) {
      setDataLoading(false);
      setCreated(false);
      console.log(e);
    }
  };
  const dateShortFormat = (dateString) => {
    if (!dateString) {
      return "-";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];
    const year = date.getFullYear();
    const hour = date?.getHours();
    const minutes = date?.getMinutes();

    return `${day}  ${monthName}  ${year}, ${hour}:${minutes}`;
  };
  const handleStatusMarker = async (enquiryId, status) => {
    try {
      let config = {
        method: "patch",
        url: `${process.env.REACT_APP_GET_CHANGE_LEADS_STATUS}/${studioId}/${enquiryId}?status=${status}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        setUpdateData((prevState) => prevState + 1);
        setSuccessPopup({
          visible: true,
          type: "success",
          message: "Status Updated Successfully.",
        });
      } else {
        setSuccessPopup({
          visible: true,
          type: "failed",
          message: "Something went wrong please try again",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleAddComment = async (comment, enquiryId) => {
    try {
      let config = {
        method: "POST",
        url: `${process.env.REACT_APP_LEADS_ADD_COMMENT}/${studioId}/${enquiryId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
        data: {
          comment,
        },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        setShowCommentPopup(false);
        setUpdateData((prevState) => prevState + 1);
        setSuccessPopup({
          visible: true,
          type: "success",
          message: "Comment added Successfully.",
        });
      } else {
        setSuccessPopup({
          visible: true,
          type: "failed",
          message: "Something went wrong please try again",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const deleteComment = async (enquiryId, commentId) => {
    try {
      let config = {
        method: "DELETE",
        url: `${process.env.REACT_APP_LEADS_DELETE_COMMENT}/${studioId}/${enquiryId}/${commentId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response?.data?.status === "success") {
        setShowCommentPopup(false);
        setUpdateData((prevState) => prevState + 1);
        setSuccessPopup({
          visible: true,
          type: "success",
          message: "Comment removed Successfully.",
        });
      } else {
        setSuccessPopup({
          visible: true,
          type: "failed",
          message: "Something went wrong please try again",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSetParam = (e) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("filter", e.target.value);

    setSearchParams(newParams);
  };
  return (
    <>
      {dataLoading ? (
        <Loading />
      ) : (
        <div className={styles.portfolio}>
          {successPopup && (
            <MessagePopup
              messagePopup={successPopup}
              setMessagePopup={setSuccessPopup}
            />
          )}
          {showCommentPopup && (
            <CommentPopup
              visible={showCommentPopup}
              setpopUp={setShowCommentPopup}
              handleAddComment={handleAddComment}
              commentsObj={commentsObj}
              deleteComment={deleteComment}
            />
          )}
          {created ? (
            <div className="w-full">
              <div className="w-full flex justify-between">
                <h1> Portfolio Leads</h1>
                <div className="flex items-center gap-[8px]">
                  <Link to="/portfolio/create">
                    <button className="ml-auto">Edit Portfolio</button>
                  </Link>
                </div>
              </div>
              <div className={styles.selectContainer}>
                <select onChange={handleSetParam} value={status}>
                  <option key="NEW" value="NEW">
                    New
                  </option>
                  <option key="CLOSED" value="CLOSED">
                    Contacted
                  </option>
                </select>
              </div>
              <div>
                {isEmpty(enquiries) ? (
                  <div className="w-full min-h-[60vh] gap-[30px] flex flex-col justify-center items-center ">
                    <p className="font_medium_13">No Enquiries</p>
                  </div>
                ) : (
                  <div className="eventDetailsTable ">
                    <table>
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Date & Time</th>
                          <th>Comments</th>
                          <th>Requirement</th>
                        </tr>
                        {enquiries?.map((obj, i) => {
                          return (
                            <>
                              <tr className="cursor-pointer">
                                <td>
                                  <p className="font_medium_16 text-[#161616] whitespace-nowrap">
                                    {obj?.name}
                                  </p>
                                </td>
                                <td>
                                  <p className="font_medium_16 text-[#161616] whitespace-nowrap">
                                    {obj?.email}
                                  </p>
                                </td>
                                <td>
                                  <p className="font_medium_16 text-[#161616] whitespace-nowrap">
                                    {obj?.phone}
                                  </p>
                                </td>
                                <td>
                                  <p className="font_medium_16 text-[#161616] whitespace-nowrap">
                                    {dateShortFormat(obj?.enquiryTime)}
                                  </p>
                                </td>
                                <td>
                                  {obj?.comments.length > 0 ? (
                                    obj.comments.map((el) => {
                                      return (
                                        <p className="font_medium_16 text-[#161616]">
                                          {el?.comment}
                                        </p>
                                      );
                                    })
                                  ) : (
                                    <p>-</p>
                                  )}
                                </td>
                                <td className="relative">
                                  <p className="font_medium_16 text-[#161616] flex gap-[2px]">
                                    {obj?.requirment}
                                    <LeadsMoreOptionDropDown
                                      setShowCommentPopup={setShowCommentPopup}
                                      setCommentsObj={setCommentsObj}
                                      obj={obj}
                                      handleStatusMarker={handleStatusMarker}
                                    />
                                  </p>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="w-full min-h-[60vh] gap-[30px] flex flex-col justify-center items-center ">
              <h1>Create Your Portfolio to view leads</h1>
              <Link
                to="/portfolio/create"
                state={{
                  buttonAction: "createPortfolio",
                }}
              >
                <button>Create</button>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
}
