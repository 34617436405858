import React, { useContext, useState } from 'react';
import { PopupWrapper } from '../../UI/PopupWrapper/PopupWrapper';
import './UserAddForm.css';
import { dataContext } from '../../Context/DataContext';
import { userAuthContext } from '../../Context/UserAuthContext';
import ErrorPopup from '../PopUps/ErrorPopup/ErrorPopup';
import axios from 'axios';

function UserAddForm({
  update,
  setUpdate,
  visible,
  setAddUserFormPopup,
  albumTaskId,
}) {
  const initialValues = {
    name: null,
    phone: null,
    userType: 'USER', // Default dropdown value
  };

  const { studioId } = useContext(dataContext);
  const { userIdToken, currentUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState({ errorStatus: false, errorMessage: '', header: '' });

  const handleClickAdd = async () => {
    try {
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_ADD_USER_AND_PUBLISH_ALBUM}/${studioId}/${albumTaskId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          'Content-Type': 'application/json',
        },
        data: {
          userData: formValues,
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        if (response?.data?.error) {
          setError({ errorMessage: response?.data?.msg, errorStatus: true, header: 'Message' });
          setLoading(false);
        } else {
          setError({ errorMessage: response?.data?.msg, errorStatus: false, header: 'Message' });
          setLoading(false);
          setUpdate(!update);
          setAddUserFormPopup(false);
        }
      }
    } catch (error) {
      console.log('error', error);
      setAddUserFormPopup(false);
      setLoading(false);
    }
  };

  return (
    <PopupWrapper visible={visible}>
      {error.errorStatus === true && <ErrorPopup error={error} setError={setError} />}
      <div className="w-[55%] h-[60%]">
        <div className="workflowLeadAddAlbum-heading p-[24px] flex flex-row justify-between">
          <h3>Add Users To Album</h3>
          <button onClick={() => setAddUserFormPopup(false)}>Close</button>
        </div>
        <div className="workflowLeadAddAlbum-grid">
          <div className="row3">
            <p>
              Name:<span className="text-red-600">*</span>
            </p>
            <input
              type="text"
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className="row3">
            <p>
              Mobile Number:<span className="text-red-600">*</span>
            </p>
            <input
              type="text"
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  phone: e.target.value,
                });
              }}
            />
          </div>
          <div className="row3">
            <p>
              User Type:<span className="text-red-600">*</span>
            </p>
            <select
              value={formValues.userType}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  userType: e.target.value,
                });
              }}
            >
              <option value="USER">User</option>
              <option value="CREW">Crew</option>
            </select>
          </div>
          <div className="row5 flex flex-row mt-[12px] gap-x-[12px]">
            <button
              className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover:bg-cyan-900 focus:outline-none"
              onClick={() => {
                setAddUserFormPopup(false);
              }}
            >
              Cancel
            </button>
            {loading ? (
              <button className="px-6 py-2 text-white rounded-full bg-[#1b2835] focus:outline-none">
                Loading
              </button>
            ) : (
              <button
                className="px-6 py-2 text-white rounded-full bg-[#1b2835] hover-bg-cyan-900 focus:outline-none"
                onClick={handleClickAdd}
              >
                Add User
              </button>
            )}
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
}

export default UserAddForm;
