import React, { useContext, useEffect, useState } from "react";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import Lottie from "../../Assets/Animation/LoadingWhite";
import _ from "lodash";
import axios from "axios";
import DynamicAlbumTemplate from "./DynamicTemplateForm/DynamicTemplateForm";
import popupsCloseIcon from "../../Assets/popupsCloseIcon.svg";
import "./AlbumCreateForm.css";
import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
function AlbumEditFormNew({ visible, setAlbum, projectId, albumId }) {
  const initialValues = {
    albumName: null,
    templateName: null,
    templateType: null,
    albumTemplateId: null,
  };
  const { studioId } = useContext(dataContext);
  const [loading, setLoading] = useState(false);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [pickedTemplate, setPickedTemplate] = useState({});
  const [albumData, setAlbumData] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    getCurrentAlbumsTemplatesData();
    getAlbums();
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAlbums = async () => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesAlbums/api/shootmates/crmalbums/templates?templateType=ALBUM&platformType=SHOOTMATES&eventType=WEDDING`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setAlbumData(response?.data?.templates);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const getCurrentAlbumsTemplatesData = async () => {
    try {
      let config = {
        method: "get",
        // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/getAlbumTemplatesData/${projectId}/${studioId}/${albumId}`,
        url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/getAlbumTemplatesData/${projectId}/${studioId}/${albumId}`,

        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setPickedTemplate(response?.data?.data);
        setFormValues(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const publishCrmAlbumTemplate = async () => {
    try {
      setLoading(true);
      verifyUser();
      let config = {
        method: "patch",
        // url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${albumId}`,
        url: `https://asia-south1-shootmates.cloudfunctions.net/test_shootmatesMemshotsServices/api/shootmates/memshotsservices/publishShootmatesAlbumTemplate/${projectId}/${studioId}/${albumId}`,
        data: formValues,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setLoading(false);
        setAlbum({
          mode: false,
          albumId: null,
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  return (
    <PopupWrapper visible={visible}>
      {loading ? (
        <Lottie loadingText={"Loading.."} />
      ) : (
        <>
          <div className="AlbumCreateFormContainer  mt-[78px] min-w-[768px]">
            <div className="flex justify-between p-[24px] items-center">
              <p className="font_semibold_20 text-[#161616]">Edit Template</p>
              <img
                src={popupsCloseIcon}
                alt=""
                onClick={() => {
                  setAlbum(false);
                }}
                className="cursor-pointer"
              />
            </div>
            <div className="seperatorHorizontalNew"></div>
            <div className="w-full h-full px-[24px] py-[20px]">
              <p className="font_semibold_16 text-[#161616] mb-[24px]">
                Select Album Template
              </p>
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Choose a template <span className="text-red-500">*</span>
              </p>
              <div className="w-full flex flex-col">
                {albumData &&
                  albumData.length > 0 &&
                  albumData?.map((obj, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setPickedTemplate(albumData[index]);
                          setFormValues({
                            ...formValues,
                            templateName: albumData[index]?.templateName,
                            albumTemplateId: albumData[index]?._id,
                            templateType: albumData[index]?.templateType,
                            sections: albumData[index]?.sections,
                          });
                        }}
                        className="hover:opacity-[0.8]"
                      >
                        <img
                          src={obj?.templateCover}
                          alt=""
                          className="w-[153px] h-[217px] object-cover rounded-[12px] cursor-pointer"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="seperatorHorizontalNew"></div>
            <div className="w-full h-full  pt-[20px]">
              {!_.isEmpty(pickedTemplate) ? (
                <DynamicAlbumTemplate
                  key={pickedTemplate._id}
                  pickedTemplate={pickedTemplate}
                  setFormValues={setFormValues}
                />
              ) : null}
            </div>
            <div className="px-[24px] w-full flex gap-[16px] items-center justify-end  pb-[24px]">
              <button
                onClick={() => {
                  setAlbum(false);
                }}
                className="cancelButton font_semibold_12"
              >
                Cancel
              </button>
              {loading ? (
                <button className="eventButton font_semibold_12">
                  Loading..
                </button>
              ) : (
                <button
                  className="eventButton font_semibold_12"
                  onClick={publishCrmAlbumTemplate}
                >
                  Save Changes
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </PopupWrapper>
  );
}

export default AlbumEditFormNew;
