import React, { useContext, useEffect } from "react";
import { dataContext } from "../../Context/DataContext";
import { useNavigate } from "react-router-dom";

function PaymentPage() {
  const {
    selectedPlan,
    roleSelected,
    setRoleSelected,
    studioRole,
    studioRoleLoading,
    setStudioId,
    packages,
    getPackages,
    paySelResponse,
  } = useContext(dataContext);
  const navigate = useNavigate();

  console.log(paySelResponse?.subscriptionId, "");

  return (
    <div className="justify-center items-center flex">
      <div className=" w-[60%] p-[22px] justify-start items-start flex flex-col">
        <p>PaymentPage</p>
      </div>
    </div>
  );
}

export default PaymentPage;
