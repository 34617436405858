import React from "react";

const Slider = ({
  memshotAlbumId,
  images,
  currentIndex,
  onPrev,
  onNext,
  onClose,
}) => {
  const copyImageUrl = (index) => {
    const imageUrl = `https://storage.googleapis.com/facedetectionregistrationsselfies/${memshotAlbumId}/${images[index]}`;
    navigator.clipboard
      .writeText(imageUrl)
      .then(() => {
        alert("Image URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy image URL: ", err);
      });
  };

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-xl mx-auto bg-black bg-opacity-75 z-50 flex items-center justify-center p-[16px]">
      <div className="relative w-full h-full">
        <button
          onClick={onClose}
          className="absolute top-2 left-2 text-white text-xl hover:text-gray-300 focus:outline-none z-10"
        >
          &#x2715;
        </button>
        <div className="relative flex flex-col justify-center items-center">
          <img
            src={`https://storage.googleapis.com/facedetectionregistrationsselfies/${memshotAlbumId}/${encodeURIComponent(
              images[currentIndex]
            )}`}
            alt={`face ${currentIndex + 1}`}
            className="max-w-full h-[350px]"
          />
          <button
            onClick={() => copyImageUrl(currentIndex)}
            className="text-white text-lg p-1 hover:bg-gray-700 focus:outline-none"
          >
            Copy Image URL
          </button>
        </div>
        <div className="w-full h-full flex items-center justify-between">
          <button
            onClick={onPrev}
            className="absolute w-fit h-full top-0 left-0 text-white text-lg p-1 hover:bg-gray-700 focus:outline-none"
          >
            &lt;
          </button>
          <button
            onClick={onNext}
            className="absolute w-fit h-full top-0 right-0 text-white text-lg p-1 hover:bg-gray-700 focus:outline-none"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;
