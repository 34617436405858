import React, { useEffect, useRef, useState } from "react";
import "./StudioDropDown.css";
//import dropdownimg from "../../Assets/dropdown.svg";
//import StudioForm from "../Forms/StudioForm/StudioFormPopup";
import StudioEditFormPopup from "../Forms/StudioEditForm/StudioEditFormPopup";
import Delete from "../Delete/Delete";
import eventDetailDotsIcon from "../../Assets/eventDetailDotsIcon.svg";
import dropDownEditIcon from "../../Assets/dropDownEditIcon.svg";
import dropDownDeleteIcon from "../../Assets/dropDownDeleteIcon.svg";
import { useNavigate } from "react-router-dom";

function StudioDropDown({
  update,
  setUpdate,
  studioObj,
  deleteStudio,
  data,
  studioId,
  studioEdit,
  setStudioEdit,
  handleEdit,
}) {
  const [dropdown, setDropdown] = useState(false);
  // const [crewView, setStudioView] = useState(false)
  // const [studioEdit, setStudioEdit] = useState(false);
  const [studioDelete, setStudioDelete] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        dropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  return (
    <>
      {/* {studioEdit && (
        <StudioEditFormPopup
          update={update}
          setUpdate={setUpdate}
          visible={studioEdit}
          setStudioForm={setStudioEdit}
          studioObj={data}
          idkey="update"
          studioId={studioId}
        />
      )} */}
      {studioDelete && (
        <Delete
          update={update}
          setUpdate={setUpdate}
          visible={studioDelete}
          setRemove={setStudioDelete}
          Objid={studioObj}
          url={process.env.REACT_APP_DELETE_CREW}
        />
      )}
      {/* {!studioEdit && ( */}
      <div className="studioDropdown cursor-pointer" ref={modalRef}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setDropdown((prevState) => {
              return !prevState;
            });
          }}
          className="px-[8px]"
        >
          <img src={eventDetailDotsIcon} alt="" />
        </div>
        {dropdown && (
          <div className="studioDropDownButton">
            {/* <button onClick={() => setStudioView(true)} >View Studio</button> */}
            <div
              // onClick={() => navigate(`/organizations/edit`)}
              onClick={() => handleEdit(data)}
              className="flex gap-[8px] items-center py-[4px] pl-[4px] pr-[12px] rounded-[8px]"
            >
              <img src={dropDownEditIcon} alt="" />
              <p className="font_normal_12 text-[#161616]">Edit Organisation</p>
            </div>
            <div
              onClick={() => deleteStudio(data?._id)}
              className="flex gap-[8px] items-center py-[4px] pl-[4px] pr-[12px] rounded-[8px]"
            >
              <img src={dropDownDeleteIcon} alt="" />
              <p className="font_normal_12 text-[#161616]">
                {" "}
                Delete Organisation
              </p>
            </div>
          </div>
        )}
      </div>
      {/* )} */}
    </>
  );
}

export default StudioDropDown;
