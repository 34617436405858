import React, { useContext, useState } from "react";
import styles from "./InvitationPurchase.module.css";
import { useRef } from "react";
import { dataContext } from "../../Context/DataContext";

function ThankingSection({
  section,
  setTemplateJson,
  index,
  setImagesToUpload,
  preview,
}) {
  const { thanksImage, setThanksImage } = useContext(dataContext);
  const [imageUrl, setImageUrl] = useState(
    section?.sectionData?.coverImage || ""
  );
  const [loading, setLoading] = useState(false);
  const thanksImageRef = useRef();

  const handleThanksImageButton = () => {
    thanksImageRef.current.click();
  };

  const handleCoverImageChange = (e) => {
    let imageFile = e.target.files[0];
    setLoading(true);
    setImageUrl(imageFile && URL.createObjectURL(imageFile));
    setThanksImage(imageFile);
    setImagesToUpload((prev) => ({
      ...prev,
      [index]: {
        index: index,
        type: section?.sectionProps?.sectionType,
        images: [imageFile],
      },
    }));
  };

  const handleInputChange = (e) => {
    setTemplateJson((prev) => {
      const newArray = prev.sections;
      const newObject = newArray[index];
      newObject.sectionData[e.target.id] = e.target.value;
      newArray[index] = newObject;
      return {
        ...prev,
        sections: newArray,
      };
    });
  };

  return (
    <div className=" bg-[#000] px-[20px]">
      {preview ? (
        section.sectionData.thanksNote && (
          <div
            style={{
              paddingTop: section?.sectionData?.thanksNote
                ? window.innerWidth >= 640
                  ? "40px"
                  : "30px"
                : "0",
              paddingBottom: section?.sectionData?.thanksNote
                ? window.innerWidth >= 640
                  ? "60px"
                  : "30px"
                : "0",
            }}
            className="flex flex-col items-center text-center"
          >
            {section.sectionData.thanksNote && (
              <p className="font_normal_21 text-[#fff] dmSerifRegularItalic">
                A thanks note
              </p>
            )}
            <p className="font_normal_16 text-[#fff] mt-[16px] max-w-[600px]">
              {section.sectionData.thanksNote}
            </p>
            {imageUrl && (
              <img
                src={imageUrl}
                alt=""
                className="mt-[24px] object-cover w-[174px] h-[214px] lg:w-[212px] lg:h-[260px] rounded-[12px]"
              />
            )}
            <p className="mt-[24px] dmSerifRegularItalic text-[#FFD8BA] font_normal_34">
              {section.sectionData.thanksFrom}
            </p>
          </div>
        )
      ) : (
        <div className="py-[35px]">
          <div className={styles.dottedSectionContainerWhite}>
            <p className="font_normal_21 text-[#fff] dmSerifRegularItalic">
              A thanks note
            </p>
            <textarea
              onChange={handleInputChange}
              className={`mt-[16px]`}
              placeholder="Write Something you want share with."
              id="thanksNote"
              value={section.sectionData.thanksNote}
            />
            <div className={`mt-[40px] ${styles.thanksImageSection} `}>
              {imageUrl ? (
                <img
                  style={{
                    display: loading ? "none" : "block",
                  }}
                  src={imageUrl}
                  alt=""
                  className="w-full h-full object-cover opacity-1"
                  onLoad={() => setLoading(false)}
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g opacity="0.6">
                    <path
                      opacity="0.5"
                      d="M19 2H5C4.20508 2.00237 3.4434 2.31921 2.8813 2.8813C2.31921 3.4434 2.00237 4.20508 2 5V13.86L5.88 9.98C6.4517 9.43586 7.21074 9.13238 8 9.13238C8.78926 9.13238 9.5483 9.43586 10.12 9.98L12.991 12.867L13.879 11.979C14.4426 11.4185 15.2052 11.1039 16 11.1039C16.7948 11.1039 17.5574 11.4185 18.121 11.979L22 15.86V5C21.9976 4.20508 21.6808 3.4434 21.1187 2.8813C20.5566 2.31921 19.7949 2.00237 19 2Z"
                      fill="white"
                    />
                    <path
                      d="M10.12 9.97994C9.5483 9.4358 8.78926 9.13232 8 9.13232C7.21074 9.13232 6.4517 9.4358 5.88 9.97994L2 13.8599V18.9999C2.00237 19.7949 2.31921 20.5565 2.8813 21.1186C3.4434 21.6807 4.20508 21.9976 5 21.9999H19C19.4034 21.9997 19.8026 21.9181 20.1737 21.76C20.5449 21.6019 20.8803 21.3706 21.16 21.0799L10.12 9.97994Z"
                      fill="white"
                    />
                    <path
                      opacity="0.25"
                      d="M22 15.8579L18.121 11.9789C17.5574 11.4185 16.7949 11.1039 16 11.1039C15.2052 11.1039 14.4426 11.4185 13.879 11.9789L12.991 12.8669L21.156 21.0759C21.698 20.5209 22.001 19.7759 22 18.9999V15.8579Z"
                      fill="white"
                    />
                  </g>
                </svg>
              )}
              {loading && (
                <div className="w-full h-full flex items-center justify-center text-[#fff] text-[12px] opacity-[0.6]">
                  <p>Loading...</p>
                </div>
              )}
            </div>
            <button onClick={handleThanksImageButton} className={`mt-[20px]`}>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                ref={thanksImageRef}
                onChange={handleCoverImageChange}
              />
              {imageUrl ? "Replace Image" : "Browse Image"}
            </button>
            <div className={`mt-[40px] ${styles.thanksNoteFrom} `}>
              <span>Thank Note From : </span>
              <input
                onChange={handleInputChange}
                type="text"
                placeholder='"Eg. Arun & Family"'
                id="thanksFrom"
                value={section.sectionData.thanksFrom}
              />
            </div>

            <div className={styles.dottedSectionTitleWhiteAbsolute}>
              <p>
                Thanking Note<span>Optional</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ThankingSection;
