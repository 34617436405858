import React, { useContext, useEffect, useState } from "react";
import "./Studio.css";
import StudioFormPopup from "../../Components/Forms/StudioForm/StudioFormPopup";
import StudioEditFormPopup from "../../Components/Forms/StudioEditForm/StudioEditFormPopup";
import { dataContext } from "../../Context/DataContext";
import { userAuthContext } from "../../Context/UserAuthContext";
import axios from "axios";
import Lottie from "../../Assets/Animation/Loading";
import whiteAddButton from "../../Assets/whiteAddButton.svg";
import StudioDropDown from "../../Components/StudioDropDown/StudioDropDown";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

//import StudioDropDown from '../../Components/StudioDropDown/StudioDropDown'

function Studio() {
  const { studioId, refresh, setRefresh, studioRole } = useContext(dataContext);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;
  const [studioData, setstudioData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [StudioForm, setStudioForm] = useState(false);
  const [StudioEditForm, setStudioEditForm] = useState(false);
  const [studioObj, setStudioObj] = useState({});
  const [studioIdObj, setStudioIdObj] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const pageNumber = 1;
  const limit = 200;

  useEffect(() => {
    if (location?.state?.buttonAction === "createOrganisation") {
      setStudioForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    verifyUser();
    if (studioId) {
      getStudios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, studioId]);

  const getStudios = async () => {
    setLoading(true);
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/getStudios?page=${pageNumber}&limit=${limit}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setstudioData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const deleteStudio = async (id) => {
    setLoading(true);
    try {
      let config = {
        method: "delete",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootMatesAuth/api/shootmates/auth/deleteStudio/${id}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 201) {
        setLoading(false);
        setUpdate(!update);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleEdit = (obj) => {
    setStudioObj(obj);
    setStudioEditForm(true);
  };

  return (
    <div className="studioContainer ">
      {StudioForm && (
        <StudioFormPopup
          refresh={refresh}
          setRefresh={setRefresh}
          update={update}
          setUpdate={setUpdate}
          visible={StudioForm}
          setStudioForm={setStudioForm}
          idkey="create"
        />
      )}

      {StudioEditForm && Object.keys(studioObj?.length > 0) && (
        <StudioEditFormPopup
          update={update}
          setUpdate={setUpdate}
          visible={StudioEditForm}
          setStudioForm={setStudioEditForm}
          studioObj={studioObj}
          idkey="update"
          // studioId={studioIdObj}
        />
      )}

      {!StudioForm && !StudioEditForm && (
        <>
          {!isEmpty(studioData) && (
            <div className="flex justify-between p-[24px] items-center">
              <p className="font_semibold_20 text-[#161616]">
                Organizations Overview
              </p>
              <button
                onClick={() => setStudioForm(true)}
                className="eventButton flex items-center gap-[6px] "
              >
                <img
                  src={whiteAddButton}
                  alt=""
                  className="w-[20px] h-[20px]"
                />
                <p className="font_semibold_12 ">Create</p>
              </button>
            </div>
          )}
          <div className="seperatorHorizontalNew"></div>
          {loading ? (
            <>
              <Lottie />
            </>
          ) : (
            <>
              {isEmpty(studioData) ? (
                <div className="w-full h-[50vh] flex flex-col gap-[20px] items-center justify-center">
                  <p className="font_medium_22">
                    Please create your studio profile to get started.
                  </p>
                  <button
                    onClick={() => setStudioForm(true)}
                    className="eventButton flex items-center gap-[6px] "
                  >
                    <img
                      src={whiteAddButton}
                      alt=""
                      className="w-[20px] h-[20px]"
                    />
                    <p className="font_semibold_12 ">Create Organisation</p>
                  </button>
                </div>
              ) : (
                <div className="studioTable">
                  <table>
                    <tbody>
                      <tr>
                        <th>Organisation Name</th>
                        <th>Location</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Whatsapp Number</th>
                        <th className="w-[44px]"></th>
                      </tr>

                      {studioData?.map((obj, index) => {
                        return (
                          <tr key={index}>
                            <td>{obj?.studioName}</td>
                            <td>{obj?.location || obj?.stateCountry}</td>
                            <td>{obj?.phone_number}</td>
                            <td>{obj?.email}</td>
                            <td>{obj?.whatsAppNumber}</td>
                            <td className="flex justify-end">
                              <StudioDropDown
                                update={update}
                                setUpdate={setUpdate}
                                studioEdit={StudioEditForm}
                                setStudioEdit={setStudioEditForm}
                                deleteStudio={deleteStudio}
                                handleEdit={handleEdit}
                                data={obj}
                                studioId={obj?._id}
                              />
                            </td>
                            {/* <td
                      className="text-red-500 cursor-pointer"
                      onClick={() => {
                        deleteStudio(obj?._id);
                      }}
                    >
                      Delete
                    </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Studio;
