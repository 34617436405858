import React from "react";
import { Link } from "react-router-dom";

function FlexiLastCard() {
  return (
    <div className="bg-[#fff] w-[100%] max-w-[280px]  cursor-pointer p-[20px] justify-start items-start flex flex-col rounded-[16px]  flex-shrink-0">
      <div className="w-[36px] h-[36px] flex-shrink-0 justify-center items-center flex rounded-full bg-[#F3F3F3]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.0831 10.5L21.2861 11.221C21.3603 11.2654 21.4216 11.3282 21.4643 11.4034C21.5069 11.4786 21.5293 11.5636 21.5293 11.65C21.5293 11.7364 21.5069 11.8214 21.4643 11.8965C21.4216 11.9717 21.3603 12.0346 21.2861 12.079L12.0001 17.649L2.7151 12.079C2.64095 12.0346 2.57956 11.9717 2.53695 11.8965C2.49433 11.8214 2.47192 11.7364 2.47192 11.65C2.47192 11.5636 2.49433 11.4786 2.53695 11.4034C2.57956 11.3282 2.64095 11.2654 2.7151 11.221L3.9171 10.5L12.0001 15.35L20.0831 10.5ZM20.0831 15.2L21.2861 15.921C21.3603 15.9654 21.4216 16.0282 21.4643 16.1034C21.5069 16.1786 21.5293 16.2636 21.5293 16.35C21.5293 16.4364 21.5069 16.5214 21.4643 16.5965C21.4216 16.6717 21.3603 16.7346 21.2861 16.779L12.5141 22.041C12.3588 22.134 12.1811 22.1832 12.0001 22.1832C11.8191 22.1832 11.6414 22.134 11.4861 22.041L2.7151 16.78C2.64095 16.7356 2.57956 16.6727 2.53695 16.5975C2.49433 16.5224 2.47192 16.4374 2.47192 16.351C2.47192 16.2646 2.49433 16.1796 2.53695 16.1044C2.57956 16.0292 2.64095 15.9664 2.7151 15.922L3.9171 15.201L12.0001 20.05L20.0831 15.2ZM12.5151 1.30899L21.2851 6.57099C21.3593 6.61538 21.4206 6.67823 21.4633 6.75342C21.5059 6.82861 21.5283 6.91356 21.5283 6.99999C21.5283 7.08641 21.5059 7.17136 21.4633 7.24655C21.4206 7.32174 21.3593 7.38459 21.2851 7.42899L12.0001 12.999L2.7151 7.42999C2.64095 7.38559 2.57956 7.32274 2.53695 7.24755C2.49433 7.17236 2.47192 7.08741 2.47192 7.00099C2.47192 6.91456 2.49433 6.82961 2.53695 6.75442C2.57956 6.67923 2.64095 6.61638 2.7151 6.57199L11.4851 1.30999C11.6406 1.21654 11.8187 1.16718 12.0001 1.16718C12.1815 1.16718 12.3596 1.21554 12.5151 1.30899Z"
            fill={"#8A56FF"}
          />
        </svg>
      </div>
      <p className="planName mt-[10px]">
        {" "}
        Get limitless storage for all your memories with our unlimited photo
        storage plan.
      </p>
      <p className="mt-[10px]">
        Contact Our Team: Mail us at:{" "}
        <Link
          href="mailto:hello@memshots.com"
          className="text-blue-500 underline"
        >
          hello@memshots.com
        </Link>{" "}
        or call us at:{" "}
        <Link href="tel:+919986619796" className="text-blue-500 underline">
          +91 9986619796
        </Link>
      </p>
    </div>
  );
}

export default FlexiLastCard;
