import React from "react";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";

const MessagePopup = ({
  messagePopup,
  setMessagePopup,
  closeProperty,
  closeOuterPopup,
}) => {
  const isVisible = messagePopup?.visible;
  const type = messagePopup?.type;
  const message = messagePopup?.message;

  const onClose = () => {
    if (closeProperty === true) {
      setMessagePopup({
        visible: false,
        type: "",
        message: "",
      });
      closeOuterPopup(false);
    }
    setMessagePopup({
      visible: false,
      type: "",
      message: "",
    });
  };
  return (
    // <div
    //   className={`fixed top-0 left-0 w-full h-[200px] flex justify-center items-center bg-gray-900 bg-opacity-75 z-1000 ${
    //     isVisible ? "block" : "hidden"
    //   }`}
    // >
    <PopupWrapper visible={messagePopup}>
      <div className="bg-white mt-[10%] w-[30%] h-[200px]  py-4 px-8 rounded-lg shadow-lg flex flex-col items-center gap-[5px] justify-center">
        <div className="w-6 h-6 bg-black rounded-full ">
          <div className="text-white">
            {type === "success" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-25 w-25 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-25 w-25 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            )}
          </div>
        </div>
        <div className="text-gray-800 text-center">{message}</div>
        <button
          className="text-sm bg-black rounded-[8px] text-white py-[8px] px-[16px] mx-auto mt-[10px]"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </PopupWrapper>
    // </div>
  );
};

export default MessagePopup;
