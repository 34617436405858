import React, { useEffect } from "react";
import styles from "./PortfoloUploadPopup.module.css";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../Assets/Animation/upload-animation.json";
import ProgressBandBlack from "./ProgressBandBlack";

function PortfoloUploadPopup({ progress }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  function handleTitle(obj) {
    if (obj?.type === "COVERIMAGE") {
      return "Cover Section Image";
    } else if (obj?.type === "THANKING") {
      return "Thanks Section Image";
    } else if (obj?.type === "GALLERY") {
      return "Gallery Images";
    } else {
      return "";
    }
  }

  return (
    <div className={styles.popUpContainer}>
      <div className=" w-full max-w-[500px] flex  justify-center items-center flex-col">
        <p className="font_medium_16 text-center text-black ">
          Please Wait. Your details are getting saved.
        </p>

        {progress?.map((obj, i) => {
          return (
            <div
              key={i}
              className="w-full flex flex-col gap-[12px] items-center mt-[20px]"
            >
              <p className="font_medium_10 text-[#000] whitespace-nowrap">
                {handleTitle(obj)}
              </p>
              <ProgressBandBlack value={obj?.progress} />
            </div>
          );
        })}

        <Lottie
          loop
          animationData={loadingAnimation}
          play
          style={{ width: 100, height: 100 }}
        />
      </div>
    </div>
  );
}

export default PortfoloUploadPopup;
