import React from 'react'
import uploadanimation from './upload-animation.json'
import Lottie from 'react-lottie-player'
function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Lottie
            loop
            animationData={uploadanimation}
            play
            style={{ width: 200, height: 200 }}
          />
   </div>
  )
}

export default Loading
