import React from "react";
import StudioFormPopup from "../../Components/Forms/StudioForm/StudioFormPopup";

function CreateProfile() {
  return (
    <div className="flex ">
      <div
        style={{
          background: `linear-gradient(26deg, #3035AD 0%, #444BE3 100%)`,
        }}
        className="w-[234px]  flex-shrink-0 min-h-[100vh] opacity-90"
      ></div>
      <StudioFormPopup />
    </div>
  );
}

export default CreateProfile;
