import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dataContext } from "../../Context/DataContext";
import { isEmpty } from "lodash";
import Loading from "../../Assets/Animation/Loading";

function Invoice() {
  const { subId } = useParams();
  const navigate = useNavigate();
  const {
    getInvoices,
    roleSelected,
    formatDate,
    invoices,
    PackagePayment,
    loading,
  } = useContext(dataContext);
  const [status, setStatus] = useState("ACTIVE");

  useEffect(() => {
    if (!isEmpty(roleSelected) && subId) {
      getInvoices(roleSelected?.studioId, subId, status);
    }
  }, [roleSelected, subId, status]);

  const handleStatusChange = (e) => {
    let data = e.target.value;
    let value = data.toUpperCase();
    setStatus(value);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="px-[20px]">
          <button
            onClick={() => navigate(`/subscriptions`)}
            className="bg-[#e8e6e6] rounded-[50px] p-[5px] my-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M11.3999 16C11.3999 16.2304 11.4819 16.4332 11.6551 16.6083L18.8825 23.7512C19.0375 23.9171 19.238 24 19.475 24C19.9489 24 20.3134 23.6406 20.3134 23.1613C20.3134 22.9217 20.2132 22.7189 20.0674 22.5622L13.4323 16L20.0674 9.43779C20.2132 9.28111 20.3134 9.06912 20.3134 8.83871C20.3134 8.35945 19.9489 8 19.475 8C19.238 8 19.0375 8.08295 18.8825 8.23963L11.6551 15.3917C11.4819 15.5576 11.3999 15.7696 11.3999 16Z"
                fill="#111"
              />
            </svg>
          </button>
          <div className="w-[400px]  gap-[10px]    flex">
            <button className="text-[32px] font-[600]">Invoices</button>
          </div>
          <div className="mt-4">
            {" "}
            <label htmlFor="status" className="mr-2 text-md">
              Status
            </label>
            <select
              id="status"
              onChange={handleStatusChange}
              className="p-2 border border-[#787878] rounded"
            >
              <option value="" disabled>
                Choose Status
              </option>
              <option value="Active">Active</option>
              <option value="Created">Created</option>
            </select>
          </div>
          <div className="p-4 mt-4 overflow-x-auto">
            <table className="min-w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    ID
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Studio ID
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Package Name
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Package Type
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Package Price
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Payment Plan
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Status
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    No. of Payments
                  </th>

                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Created At
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Payment status
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoices?.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="border border-gray-300 px-4 py-2">
                      {item._id}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item?.studioId}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item?.packageName}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item?.packageType}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item?.packagePrice}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item?.paymentPlan}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item?.status}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item?.noOfPayments}
                    </td>

                    <td className="border border-gray-300 px-4 py-2">
                      {formatDate(item?.updatedAt)}
                    </td>

                    <td
                      onClick={() =>
                        item?.status == "PAID"
                          ? ""
                          : PackagePayment(
                              item?.studioId,
                              item?.subscriptionId,
                              item?._id
                            )
                      }
                      className="border border-gray-300 px-4 py-2"
                    >
                      <button className="py-[5px] px-[8px] text-[#fff] whitespace-nowrap rounded-[10px] border-[1px] bg-[#8787dc]">
                        {item?.status == "PAID" ? "Paid" : "Pay Now"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Invoice;
