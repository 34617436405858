import React from 'react';

const UploadQueue = ({
  compressing,
  isExpanded,
  uploadedFiles,
  selectedFiles,
  toggleExpand,
  totalFiles,
  completedFiles,
  currentFile,
  currentFileProgress,
}) => {
  return (
    <div className="fixed bottom-4 right-4 flex flex-col z-50">
      <div className="bg-gray-900 text-white p-4 rounded-t-lg shadow-lg w-80 flex justify-between items-center cursor-pointer backdrop-blur-lg bg-opacity-80">
        <div className="flex-grow">
          <h3 className="font-semibold text-lg">Upload Progress</h3>
          <p className="text-sm text-gray-400">
            {completedFiles}/{totalFiles} files uploaded
          </p>
        </div>
        <button
          onClick={toggleExpand}
          className="text-white bg-blue-600 hover:bg-blue-700 font-medium py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {isExpanded ? 'Minimize' : 'Expand'}
        </button>
      </div>
      {isExpanded && (
        <div className="bg-gray-800 text-white p-4 rounded-b-lg shadow-lg w-80 max-h-[400px] overflow-y-auto backdrop-blur-lg bg-opacity-90 scrollbar-thin scrollbar-thumb-blue-600 scrollbar-track-gray-700 scrollbar-thumb-rounded-lg">
          {currentFile && (
            <div className="mb-4">
              <h4 className="text-sm font-medium text-gray-300">Uploading: <span className="text-white">{currentFile.name}</span></h4>
              <div className="w-full bg-gray-700 rounded-full h-2.5">
                <div
                  className="bg-blue-500 h-2.5 rounded-full"
                  style={{ width: `${currentFileProgress}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-400 mt-1">Progress: {currentFileProgress}%</p>
            </div>
          )}
          {compressing ? (
            <div className="text-center text-yellow-500">Processing images...</div>
          ) : (
            <ul className="space-y-2">
              {selectedFiles.map((item, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center bg-gray-700 p-2 rounded-lg"
                >
                  <span>{item.name}</span>
                  {uploadedFiles[item.name] && (
                    <span className="text-green-500">✔</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadQueue;
