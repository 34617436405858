import React from 'react';

const ImageModel = ({ media, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 p-6">
            <div className="relative bg-white rounded-lg shadow-2xl overflow-hidden transition-all transform scale-100 hover:scale-105 duration-300 ease-in-out max-w-3xl max-h-[90vh]">
                <button
                    className="absolute top-3 right-3 text-white bg-red-500 p-2 rounded-full hover:bg-red-600 transition-colors duration-200 ease-in-out focus:outline-none"
                    onClick={onClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <img
                    src={media}
                    alt="Viewed media"
                    className="w-full h-auto rounded-b-lg max-h-[80vh] object-contain"
                />
            </div>
        </div>
    );
};

export default ImageModel;
