import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router";
import Navbar from "../Components/Navbar/Navbar";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Layout.css";
import Footer from "../Components/Footer/Footer";
import EmailAccCreatedPopup from "../Components/EmailAccCreatedPopup/EmailAccCreatedPopup";
import { userAuthContext } from "../Context/UserAuthContext";
import { dataContext } from "../Context/DataContext";

function Layout() {
  const { emailAccountLogin, setEmailAccountLogin, fetchUserData } =
    useContext(userAuthContext);
  const { activePackages } = useContext(dataContext);
  const [verificationPopup, setVerificationPopup] = useState(false);

  useEffect(() => {
    const checkUserVerification = async () => {
      if (emailAccountLogin) {
        const userData = await fetchUserData();
        if (userData?.emailVerified) {
          setVerificationPopup(false);
        } else {
          setVerificationPopup(true);
        }
        setEmailAccountLogin(false);
      }
    };
    checkUserVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAccountLogin]);

 
  return (
    <>
      {verificationPopup && (
        <EmailAccCreatedPopup setPopup={setVerificationPopup} />
      )}
      <div className="w-full h-full">
        <Navbar activePackages={activePackages} />
        <div className="flex">
          <div className=" [&::-webkit-scrollbar]:hidden w-[260px]  h-[calc(100vh-64px)] overflow-y-auto sticky top-[64px]">
            <Sidebar />
          </div>

          <main className=" flex-1 overflow-x-hidden overflow-y-auto h-[calc(100vh-64px)]">
            <Outlet />
          </main>
        </div>
      </div>

      {/* <div className="w-full h-full">
        <Navbar />
        <div className="flex  ">
          <div className=" w-[308px] pb-4 overflow-y-auto ">
            <Sidebar />
          </div>

          <main className=" mb-[50px] flex-1 overflow-x-hidden overflow-y-auto ">
            <Outlet />
          </main>
        </div>
      </div> */}
      {/* <div className="w-full h-full">
        {verificationPopup && (
          <EmailAccCreatedPopup setPopup={setVerificationPopup} />
        )}
        <Navbar />
        <div className="flex w-full h-full">
          <div className="w-[308px] flex-shrink-0 h-[calc(100vh-68px)] sticky top-[68px] overflow-auto custom-scrollbar">
            <Sidebar />
          </div>
          <div className="bg-white w-full h-full min-h-[80vh]">
            <Outlet />
          </div>
          <div className="bg-[#F8F8F9] w-[86px] h-[100vh]"></div> 
        </div>
        <Footer /> 
      </div> */}
    </>
  );
}

export default Layout;
