import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items, update, setUpdate }) => {
  const handleRefresh = () => {
    setUpdate(!update);
  };
  return (
    <>
      <nav
        className="flex mt-[10px] ml-[10px] rounded-[8px]"
        aria-label="Breadcrumb "
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse rounded-[8px]">
          {items.map((item, index) => (
            <li key={index} className="border-none p-0">
              <div className="flex items-center">
                {index !== 0 && (
                  <svg
                    className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1 rounded-[8px]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                )}
                {item.url ? (
                  <Link
                    to={item.url}
                    className="ms-1 text-sm font-medium  text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white rounded-[8px]"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <span className="ms-1 text-sm font-medium  text-gray-500 md:ms-2 dark:text-gray-400 rounded-[8px]">
                    {item.label}
                  </span>
                )}
              </div>
            </li>
          ))}
        </ol>
      </nav>
      <div className="mt-[10px] ml-[10px]">
        <button
          onClick={handleRefresh}
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-8 py-1 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Refresh
        </button>
        <Link
          to={items[items?.length - 1]?.url}
          type="button"
          className="ml-[5px] text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-8 py-1 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        >
          Back
        </Link>
      </div>
    </>
  );
};

export default Breadcrumb;
