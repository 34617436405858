// MetaTags.js
import React from 'react';
import { Helmet } from 'react-helmet';

function MetaTags() {
    const image = "https://shootmatesassets.s3.ap-south-1.amazonaws.com/slogo.png"
    return (
        <Helmet>
            <link rel="icon" href={image} />
            <meta property="og:image" content={image} />
            <meta name="description" content="Empower The Photography Community With Technology." />
        </Helmet>
    );
}

export default MetaTags;
