import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PageLoading from "../Components/PageLoading/PageLoading";
import Footer from "../Components/Footer/Footer";

function TandC() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    let count = 0;
    useEffect(() => {
        getData();
        window.scrollTo(0, 0)
    }, []);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                "https://storage.googleapis.com/shootmatesweb_datas/tandc%26policies/tandc.json"
            );
            setData(response?.data);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
    };
    return (
        <div className="w-full h-full">
            <div className="min-h-[80vh]">
                <div className=" h-[79px] navbar flex items-center">
                    <p
                        onClick={() => {
                            navigate("/");
                        }}
                        className="underline cursor-pointer"
                    >
                        Back
                    </p>
                </div>
                <div className="p-[40px]">
                    {loading ? <PageLoading /> :
                        <div className="w-full h-full text-[#000]">
                            <span className="font-semibold text-[24px] lg:text-[40px]">
                                {data?.heading}
                            </span>
                            <div className="flex flex-col pt-[30px]">
                                <span className="pb-[10px] font-bold text-[18px] lg:text-[24px]">{data?.question}</span>
                                <div className="flex flex-col text-[16px] lg:text-[21px]">
                                    {data &&
                                        data.termsAndConditions &&
                                        data.termsAndConditions.map((obj) => {
                                            count++;
                                            return (
                                                <div className="flex">
                                                    <span>{count}.</span>
                                                    <span className="pb-[10px] pl-2">{obj.tandc}</span>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TandC;
