import { PopupWrapper } from "../../UI/PopupWrapper/PopupWrapper";
import React, { useState, useEffect, useContext } from "react";
import "./ImageGalleryPopup.css";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { userAuthContext } from "../../Context/UserAuthContext";
import axios from "axios";

function ImageGalleryPopup({
  visible,
  setIsOpenGallery,
  projectId,
  shootmatesAlbumId,
  studioId,
  albumId,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const { userIdToken, currentUser, verifyUser } = useContext(userAuthContext);
  const tokenData = userIdToken || currentUser?.accessToken;

  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await verifyUser();
        if (studioId) {
          await getSelectedImages();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioId]);

  const getSelectedImages = async () => {
    try {
      let config = {
        method: "get",
        url: `https://asia-south1-shootmates.cloudfunctions.net/shootmatesDesktopTool/api/shootmates/selectiontool/getselectedmediadashboard/${projectId}/${studioId}/${shootmatesAlbumId}`,
        headers: {
          Authorization: `Bearer ${tokenData}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      if (response.status === 200) {
        setSelectedFiles(response?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const downloadZip = async () => {
    const zip = new JSZip();
    for (const file of selectedFiles) {
      const response = await fetch(file.url);
      const blob = await response.blob();
      const fileName = file.name;
      zip.file(fileName, blob);
    }
    zip
      .generateAsync({ type: "blob" }, (metadata) => {
        setProgress(Math.round(metadata.percent));
      })
      .then((content) => {
        saveAs(content, "selectedImages.zip");
      });
  };
  const getThumbnailUrl = (url, folderName) => {
    const parts = url.split("/");
    const lastPart = parts.pop();
    const finalThumbnail = `https://storage.googleapis.com/albumthumbnails/${albumId}/${folderName}/d_${lastPart}`;
    console.log("finalThumbnail", finalThumbnail);
    return finalThumbnail;
  };
console.log("progress",progress);
  return (
    <PopupWrapper visible={visible}>
      <div className="metadataFormContainer mt-[78px] min-w-[768px] ml-[20px] mr-[20px] mb-[20px]">
        <div className="settingsBar fixed top-0 left-0 w-full z-50 bg-white shadow-md p-6">
          <p className="font-semibold text-2xl text-[#161616]">
            Selected Images
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 text-sm rounded ml-4"
              onClick={() => {
                setIsOpenGallery(false);
              }}
            >
              Close
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 text-sm rounded ml-4"
              onClick={downloadZip}
            >
              Download as ZIP
            </button>
          </p>
          {progress > 0 && (
            <div className="progressBar mt-2">
              <div className="h-4 bg-gray-200 rounded">
                <div
                  className="h-4 bg-blue-500 rounded"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <p className="text-gray-600 text-sm mt-1">{progress}%</p>
            </div>
          )}
        </div>
        <div className="seperatorHorizontalNew mt-[78px]"></div>
        <div className="imageGalleryContainer mt-8 ml-[20px] mr-[20px] overflow-y-auto h-[calc(100vh-154px)]">
          {loading ? (
            <div className="loadingContainer flex items-center justify-center h-full">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-blue-500 border-t-transparent"></div>
            </div>
          ) : (
            <div className="grid grid-cols-4 md:grid-cols-5 gap-4">
              {selectedFiles.map((image, index) => (
                <div key={index}>
                  <img
                    className="h-auto max-w-full rounded-lg cursor-pointer"
                    src={getThumbnailUrl(image?.url, image?.folderName)}
                    alt=""
                    onClick={() => openModal(image?.url)}
                  />
                </div>
              ))}
            </div>
          )}
          {isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="bg-white p-4 rounded-lg relative">
                <span
                  className="text-black cursor-pointer absolute top-2 right-2"
                  onClick={closeModal}
                >
                  &times;
                </span>
                <img
                  className="w-auto h-[500px] rounded-lg"
                  src={selectedImage}
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </PopupWrapper>
  );
}

export default ImageGalleryPopup;
