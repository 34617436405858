import React, { useContext, useEffect, useState } from "react";
import styles from "./EmailAccCreatedPopup.module.css";
import LoadingFromCenter from "../LoadingFromCenter/LoadingFromCenter";
import singinCloseButton from "../../Assets/singinCloseButton.svg";
import success from "../../Assets/share/copySuccessRound.svg";
import { userAuthContext } from "../../Context/UserAuthContext";

const EmailAccCreatedPopup = ({ setPopup }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const [verified, setVerified] = useState(false);
  const {
    emailVerification,
    currentUser,
    loading,
    setLoading,
    emailError,
    setEmailError,
    emailVerified,
    fetchUserData,
  } = useContext(userAuthContext);
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const userData = await fetchUserData();
      if (userData?.emailVerified == true) {
        setVerified(true);
        setLoading(false);
      }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  
  useEffect(() => {
    handleEmailVerification();
  }, []);

  const handleEmailVerification = () => {
    emailVerification(currentUser);
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.container}>
        <div className="w-[100%] flex justify-end p-[10px] cursor-pointer">
          <img
            src={singinCloseButton}
            onClick={() => {
              setEmailError(false);
              setLoading(false);
              setPopup(false);
            }}
          />
        </div>
        <h3 className="font_medium_16 mb-[14px]">Please verify your email</h3>
        <p
          className="font_normal_13 text-opacity-80 mb-[25px]"
          style={{ textAlign: "center" }}
        >
          Almost there! An email containing verification instructions was sent
          to {currentUser?.email}.
        </p>

        {verified ? (
          <div className="font_medium_16 flex items-center w-full justify-center gap-[5px]">
            Verified <img src={success} alt="" className="w-[36px] h-[36px]" />
          </div>
        ) : (
          <p
            className="font_normal_13 cursor-pointer"
            onClick={handleEmailVerification}
            disabled={loading}
          >
            Didn't receive the email?{" "}
            <span className="text-[#3075FC]">Resend email</span>
          </p>
        )}

        <div className="w-full p-[10px] flex flex-col items-center">
          {emailError && (
            <div className="font_normal_13 text-center w-[100%] mb-[12px]">
              <span className="text-red-600">*</span>
              {emailError}
            </div>
          )}
          {loading && !emailVerified && <LoadingFromCenter />}
        </div>
      </div>
    </div>
  );
};

export default EmailAccCreatedPopup;
