import React, { useContext, useEffect, useState } from "react";

import styles from "./InvitationPurchase.module.css";
import CoverImage from "./CoverImageAndTitle";
import DateTimeVenue from "./DateTimeVenue";
import LiveSection from "./LiveSection";
import VideoSection from "./VideoSection";
import Gallery from "./Gallery";
import ThankingSection from "./ThankingSection";
import PortfoloUploadPopup from "../PortfoloUploadPopup/PortfoloUploadPopup";
import InvitaionStudio from "./InvitaionStudio";
import AISection from "./AISection";

function TemplatePurchase({
  templateJson,
  setTemplateJson,
  preview,
  setImagesToUpload,
  uploadLoading,
  progress,
  packageType,
}) {
  const components = (section, i) => {
    let type = section?.sectionProps?.sectionType;
    switch (type) {
      case "COVERIMAGE": {
        return (
          <CoverImage
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
          />
        );
      }
      case "VENUE": {
        return (
          <DateTimeVenue
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            preview={preview}
          />
        );
      }
      case "LIVE": {
        return (
          <LiveSection
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            preview={preview}
          />
        );
      }
      case "AI": {
        return (
          <AISection
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
          />
        );
      }
      case "TEASER": {
        return (
          <VideoSection
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            preview={preview}
          />
        );
      }
      case "GALLERY": {
        return (
          <Gallery
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
          />
        );
      }
      case "THANKING": {
        return (
          <ThankingSection
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
          />
        );
      }
      case "STUDIO": {
        return (
          <InvitaionStudio
            section={section}
            setTemplateJson={setTemplateJson}
            index={i}
            setImagesToUpload={setImagesToUpload}
            preview={preview}
            packageType={packageType}
          />
        );
      }
      default:
        return null;
    }
  };
  return (
    <div className="containFull">
   
      {uploadLoading && <PortfoloUploadPopup progress={progress} />}

      <div className={styles.parentContainer}>
        <div className={styles.templateContainer}>
          {templateJson?.sections?.map((obj, i) => {
            return components(obj, i);
          })}
        </div>
      </div>
    </div>
  );
}

export default TemplatePurchase;
