import React from 'react'
import { PopupWrapper } from '../../UI/PopupWrapper/PopupWrapper'
import './ClientViewPopup.css'

function ClientViewPopup({ visible, setView, clientObj }) {
    return (
        <PopupWrapper visible={visible}>
            <div className=' w-[60%] h-[80%] overflow-y-scroll'>
                <div className='viewClient-heading p-[24px] flex flex-row justify-between'>
                    <h3>Client Details</h3>
                    <button onClick={() => setView(false)}>Close</button>
                </div>
                <div className='viewClinet-Container'>
                    <p>First Name : {clientObj.firstName}</p>
                    <p>Last Name : {clientObj.lastName}</p>
                    <p>Email : {clientObj.email}</p>
                    <p>Phone Number : {clientObj.phone}</p>
                    <p>Street Address: {clientObj.streetAddress}</p>
                    <p>Town : {clientObj.town}</p>
                    <p>Pincode : {clientObj.pincode}</p>
                    <p>State : {clientObj.state}</p>
                    <p>Country : {clientObj.country}</p>
                    <p>Company : {clientObj.company}</p>
                    <p>Business Number : {clientObj.businessNumber}</p>
                </div>
            </div>
        </PopupWrapper>
    )
}

export default ClientViewPopup
