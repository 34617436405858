// src/imageCompression.js

const compressImage = async (file) => {
  try {
    const img = new Image();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = Math.min(img.width / 2);
          const height = Math.min(img.height / 2);
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            async (resultBlob) => {
              let compressedFile = new File([resultBlob], file.name, {
                type: "image/jpeg",
              });
              resolve(compressedFile);
            },
            "image/jpeg",
            0.9
          );
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
      reader.readAsDataURL(file);
    });
  } catch (error) {
    console.error("Error compressing image:", error);
    throw error;
  }
};

export default compressImage;
