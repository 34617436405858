import React from "react";
import ErrorPopup from "../../PopUps/ErrorPopup/ErrorPopup";
import { PopupWrapper } from "../../../UI/PopupWrapper/PopupWrapper";
import "./TrainingFolder.css";
import popupsCloseIcon from "../../../Assets/popupsCloseIcon.svg";

const TrainingFolder = ({
  handleCameraFolderChange,
  selectedCameraFolder,
  cameras,
  initialFormData,
  setFormData,
  uploadTypes,
  selectedUploadType,
  handleUploadTypeChange,
  visible,
  setpopUp,
  formData,
  formErrors,
  handleInputChange,
  handleSubmit,
  error,
  setError,
}) => {
  return (
    <PopupWrapper visible={visible} setpopUp={setpopUp}>
      <>
        {error.errorStatus === true && (
          <ErrorPopup error={error} setError={setError} />
        )}
        <div className="AddPinFormContainer mt-[108px] w-[50%]">
          <div className="px-[16px] py-[16px]  sticky top-0 bg-white flex items-center justify-between rounded-t-[12px]">
            <p className="font_semibold_20 text-[#161616]">
              Add a Camera Folder
            </p>
            <img
              onClick={() => {
                setpopUp(false);
                setFormData(initialFormData);
              }}
              src={popupsCloseIcon}
              alt=""
              className="cursor-pointer"
            />
          </div>
          <div className="seperatorHorizontalNew"></div>
          <div className="px-[16px] pt-[16px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Camera Folder Name
            </p>
            <input
              type="text"
              name="folderName"
              placeholder="Enter Camera Folder Name"
              className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${
                formErrors?.folderName ? "border-red-500" : "border-[#8C8C8C4D]"
              }`}
              value={formData?.folderName}
              onChange={handleInputChange}
            />
            {formErrors?.folderName && (
              <p className="text-red-500 font_normal_12">
                {formErrors?.folderName}
              </p>
            )}
          </div>
          <div className="px-[16px] pt-[16px]">
            <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
              Upload Type
            </p>
            <select
              id="uploadType"
              name="uploadType"
              value={selectedUploadType}
              onChange={handleUploadTypeChange}
              placeholder="Enter Folder Name"
              className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px]  "border-[#8C8C8C4D]"
              }`}
            >
              {uploadTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          {selectedUploadType === "GOOGLE_DRIVE" && (
            <div className="px-[16px] pt-[16px]">
              <p className="font_normal_12 text-[#8C8C8C] mb-[8px]">
                Google Drive Url
              </p>
              <input
                type="text"
                name="gdriveUrl"
                placeholder="Enter Google Drive Url Which Is Public"
                className={`w-full font_normal_14 border-[1px] p-[10px] rounded-[8px] ${
                  formErrors?.gdriveUrl
                    ? "border-red-500"
                    : "border-[#8C8C8C4D]"
                }`}
                value={formData?.gdriveUrl}
                onChange={handleInputChange}
              />
              {formErrors?.gdriveUrl && (
                <p className="text-red-500 font_normal_12">
                  {formErrors?.gdriveUrl}
                </p>
              )}
            </div>
          )}
          <div className="seperatorHorizontalNew mt-[16px] "></div>
          <div className="px-[16px] w-full flex gap-[16px] items-center justify-end pt-[12px] pb-[24px]">
            <button
              className="cancelButton font_semibold_12"
              onClick={() => {
                setpopUp(false);
                setFormData(initialFormData);
              }}
            >
              {" "}
              Cancel
            </button>
            <button className="eventButton mr-[50px]" onClick={handleSubmit}>
              <p className="text-[#F6F6F6] font_semibold_12 ">
                Create Upload Section
              </p>
            </button>
          </div>
        </div>
      </>
    </PopupWrapper>
  );
};

export default TrainingFolder;
