import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";

const QRCodeWithGallery = () => {
  const { albumId, photoshareid } = useParams();
  const qrCodeRef = useRef(null);
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageUrl, setPageUrl] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://storage.googleapis.com/photosharetoolqr/${albumId}/${photoshareid}.json`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const studioUniqueText =
          data?.studioUniqueText === "photosharetool"
            ? "photosharetool"
            : data?.studioUniqueText;
        const slugId = data?.slugId;
        const name = data?.name;
        setJsonData(data);
        setPageUrl(
          `https://${studioUniqueText}.memshots.com/${slugId}/QRPhotoShare/${name}`
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [albumId, photoshareid]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(pageUrl).then(() => {
      setCopySuccess("Link copied!");
      setTimeout(() => setCopySuccess(""), 2000);
    });
  };

  // Function to download the QR code
  const handleDownloadQRCode = async () => {
    const canvas = await html2canvas(qrCodeRef.current);
    const dataUrl = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "qr-code.png";
    link.click();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="grid grid-cols-2 h-screen">
      <div className="p-4 flex flex-col justify-start">
        {pageUrl && (
          <div className="mb-6 text-center">
            <h2 className="text-xl font-semibold mb-2">Photo Share QR Code</h2>

            <div
              ref={qrCodeRef}
              className="inline-block bg-white p-4 rounded-lg shadow-lg"
            >
              <QRCode value={pageUrl} size={400} />
            </div>

            <p className="mt-2 text-red-600">
              Scan the QR code to open gallery of photos.
            </p>

            {/* Copy Link and Download QR Buttons */}
            <div className="mt-4 flex justify-center space-x-4">
              <button
                onClick={handleCopyLink}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Copy Link
              </button>
              <button
                onClick={handleDownloadQRCode}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
              >
                Download QR Code
              </button>
            </div>
            {copySuccess && (
              <p className="text-green-500 mt-2">{copySuccess}</p>
            )}
          </div>
        )}
      </div>

      <div className="p-4 overflow-y-auto">
        {jsonData && jsonData.media?.length >= 0 && (
          <div>
            <h2 className="text-xl font-semibold mb-2 text-start">
              Selected Images
            </h2>

            <div className="columns-2 sm:columns-3 md:columns-4 gap-4 space-y-4">
              {jsonData.media.map((image, index) => (
                <div key={index} className="break-inside-avoid">
                  <img
                    src={image}
                    alt={`Media ${index + 1}`}
                    className="object-cover w-[200px]  h-[200px]  rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QRCodeWithGallery;
