import React, { useEffect, useState } from "react";
import image from "../../Assets/albumDetailAssignedUsers.png";
import img from "../../Assets/GR1.png";
import "./Tools.css";
import ReactPlayer from "react-player";
import VideoPlayPopup from "./VideoPlayPopup";
import GR1 from "../../Assets/GR1.png";
import { method } from "lodash";
import axios from "axios";

const Tools = () => {
  const [openVideo, setOpenVideo] = useState(false);
  const [turtorials, setTurtorials] = useState([]);
  useEffect(() => {
    getTurtorials();
  }, []);

  const handleDownload = async (link) => {
    try {
      // const link = "";
      window.open(link, "_blank");
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const getTurtorials = async () => {
    try {
      let config = {
        method: "get",
        url: "https://storage.googleapis.com/crm_tutorialvideos/tutorials.json",
      };
      const response = await axios(config);
      setTurtorials(response?.data);
    } catch (err) {
      console.log(err, "get turtorials error");
    }
  };

  return (
    <div className="p-[24px]">
      <p className="AdvancedToolsHead"> Advanced Tools </p>
      <div className="bg-[#4D58E3] mt-[22px] rounded-[12px]">
        <div className="justify-center gap-[5px] py-[59px] px-[50px] items-center flex">
          <div className="w-[301px] h-[301px] flex-shrink-0 ">
            <img
              className="w-full h-full object-cover rounded-[12px]"
              src={GR1}
              alt=""
            />
            {/* <div className="bg-[#fff] w-full h-full rounded-[12px]"
            ></div> */}
          </div>
          <div className="px-[10%]">
            <p className="toolsText">
              Best tool ever made for photographers and studios
            </p>
            <p className="toolsPara mt-[11px]">
              Best tool ever made for photographers and studios. Glad to see you
              again. Login to your account below
            </p>
            <div className="mt-[24px] ">
              <p className="downloadAppText">Download App For Desktop </p>
              <div className="flex items-center gap-[16px]">
                <button
                  onClick={() => {
                    handleDownload(
                      `https://storage.googleapis.com/shootmatesweb_datas/memshots-1.0.11.dmg`
                    );
                  }}
                  className="mt-[12px] downloadButton flex items-center gap-[10px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M17.0502 20.28C16.0702 21.23 15.0002 21.08 13.9702 20.63C12.8802 20.17 11.8802 20.15 10.7302 20.63C9.29016 21.25 8.53016 21.07 7.67016 20.28C2.79016 15.25 3.51016 7.59 9.05016 7.31C10.4002 7.38 11.3402 8.05 12.1302 8.11C13.3102 7.87 14.4402 7.18 15.7002 7.27C17.2102 7.39 18.3502 7.99 19.1002 9.07C15.9802 10.94 16.7202 15.05 19.5802 16.2C19.0102 17.7 18.2702 19.19 17.0402 20.29L17.0502 20.28ZM12.0302 7.25C11.8802 5.02 13.6902 3.18 15.7702 3C16.0602 5.58 13.4302 7.5 12.0302 7.25Z"
                      fill="white"
                    />
                  </svg>
                  <p>Download for MacOs</p>
                </button>

                <button
                  onClick={() => {
                    handleDownload(
                      `https://storage.googleapis.com/shootmatesweb_datas/memshots-1.0.11-setup.exe`
                    );
                  }}
                  className="mt-[12px] downloadButton flex items-center gap-[10px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3.001 5.479L10.378 4.463V11.59H3L3.001 5.479ZM3.001 18.521L10.378 19.538V12.498H3L3.001 18.521ZM11.189 19.646L21.001 21V12.498H11.189V19.646ZM11.189 4.354V11.59H21.001V3L11.189 4.354Z"
                      fill="white"
                    />
                  </svg>
                  <p>Download for Windows</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[40px]">
        <p className="turtorials pb-[30px]">Turtorials</p>
        <div className="flex flex-wrap gap-4">
          {turtorials?.map((item) => (
            <div className="toolsMainCard   p-[16px]">
              <div
                onClick={() => setOpenVideo(true)}
                className="w-full h-[200px] relative cursor-pointer"
              >
                <ReactPlayer
                  style={{ objectFit: "contain" }}
                  height={"100%"}
                  width={"100%"}
                  playing={false}
                  url={`https://storage.googleapis.com/crm_tutorialvideos/crmEventCreation.mp4`}
                />
                <div className="absolute bg-opacity-[0.07] bg-[#111] w-full h-full justify-center items-center flex top-0 left-0 right-0 bottom-0">
                  <button className=" playBut ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="44"
                      viewBox="0 0 36 44"
                      fill="none"
                    >
                      <path
                        d="M35.75 22C35.7513 22.5518 35.6099 23.0945 35.3394 23.5754C35.0688 24.0562 34.6785 24.4589 34.2062 24.7443L4.94 42.6477C4.44658 42.9498 3.88145 43.1148 3.30297 43.1255C2.72449 43.1362 2.15364 42.9923 1.64938 42.7086C1.14991 42.4294 0.733842 42.0221 0.443958 41.5287C0.154073 41.0354 0.000834555 40.4737 0 39.9014V4.09863C0.000834555 3.5264 0.154073 2.96471 0.443958 2.47134C0.733842 1.97796 1.14991 1.5707 1.64938 1.29144C2.15364 1.00778 2.72449 0.863868 3.30297 0.874581C3.88145 0.885293 4.44658 1.05024 4.94 1.35238L34.2062 19.2558C34.6785 19.5412 35.0688 19.9438 35.3394 20.4247C35.6099 20.9056 35.7513 21.4483 35.75 22Z"
                        fill="#787878"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {/* <img className="rounded-[8px]" src={img} alt="" /> */}
              <div className="mt-[16px]">
                <p className="welcome">{item?.title}</p>
                <p className="welcometext">{item?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {openVideo && (
        <VideoPlayPopup onClose={setOpenVideo} visible={openVideo} />
      )}
      {/* <div className="w-48 text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white ml-[10px] mt-[20px]">
        <button
          onClick={() => {
            handleDownload("windows");
          }}
          type="button"
          className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
        >
          <svg
            className="w-10 h-10 me-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
            <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
          </svg>
          Download Mac Os Version
        </button>
      </div>
      <div className="w-48 text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white ml-[10px] mt-[20px]">
        <button
          onClick={() => {
            handleDownload("macos");
          }}
          type="button"
          className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-b-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
        >
          <svg
            className="w-10 h-10 me-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
            <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
          </svg>
          Download Windows Version
        </button>
      </div> */}
    </div>
  );
};

export default Tools;
